import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { useContext, useEffect, useState } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid from "@mui/material/Grid/Grid";
import Divider from '@mui/material/Divider';
import StyledButton from '../../styledComponents/styledButton.module';
import useFormStyles from '../../styledComponents/globalFormStying.module';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import UserContext from '../../../context/UserContext';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import { getUserId, useMediaQueries } from '../../../utils/global-utils';
import useStyles from './userDialog.module';
import { Candidate, EmployeeRatingsResponse } from '../../../utils/types.d';
import UserRatings from '../userRatings/userRatings';
import RatingsApi from '../../../services/ratingsApi';
import { Controller, useForm } from 'react-hook-form';
import { StyledInput } from '../../styledComponents/styledInput/styledInput';
import StyledProfilePicture from '../../styledComponents/profileImage/profileImage';
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Alert from '@mui/material/Alert';



interface UserDialogProps {
    open: boolean;
    onClose: () => void;
    onAddToFavorites: () => void;
    user: Candidate | null,
    addedToFavorite: boolean;
}


function UserDialogCard({ open, onClose, onAddToFavorites, user, addedToFavorite}: UserDialogProps) {

    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();

    const { userData, accessToken } = useContext(UserContext);
    
    const [currentComponent, setCurrentComponent] = useState('details');
    const [fetchEmployeeRatings, setFetchEmployeeRatings] = useState<EmployeeRatingsResponse | []>([]);
    const [loading, setLoading] = useState(true);
    const [ratingValue, setRatingValue] = useState<number | null>(3);
    const [errorModal, setErrorModal] = useState(false);

    const employeeId = user?.employeeId
    const employerId = getUserId(userData);

    const { 
        handleSubmit,
        formState,
        control,
        reset,
        trigger,
        setValue } = useForm({
            mode: 'onChange',
            defaultValues: {
                employeeId: employeeId,
                employerId: employerId,
                rating: 3,
                comment: '',
            }
    });

    setValue('employeeId', employeeId);
    setValue('employerId', employerId);

    useEffect(() => {

        const fetchDetails = async () => {
            try {
                if (accessToken && user) {

                    const employeeRatingsResponse = await RatingsApi.GetAllEmployeeRatings(accessToken, employeeId);
                    setFetchEmployeeRatings(employeeRatingsResponse.data)
                }
            } catch (error) {
                //
            }
        };

        if (accessToken && user) {
            fetchDetails();
        }
    }, [accessToken, user]);

    

    const handleComponentChange = (_event: any, newComponent: string) => {
        if (currentComponent === newComponent) {
            return;
        }
        setCurrentComponent(newComponent);
    };

    const onSubmit = async (formData: any) => {
        try {

            await RatingsApi.AddRatingForEmployee(formData, accessToken);
            reset({
                rating: 0,
                comment: '',
            })

        } catch (error) {
            setErrorModal(true)
            setValue('comment', 'Već ste ocenili korisnika');
        }
    }

    const handleButtonClick = async () => {
        try {
            await trigger();
            if (Object.keys(formState.errors).length === 0) {
                setLoading(true);
                await handleSubmit(onSubmit)();
                setLoading(false);
            }
        }
        catch (err) {
            //
        }
    };
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            keepMounted
            aria-describedby="userCard-modal"
        >
            <DialogTitle>
                Profil kandidata
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="userCard-modal">
                    <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                        <Grid item xs={12} sm={12} md={6}>
                            <ToggleButtonGroup
                                    className={formClasses.buttonsGroup}
                                    value={currentComponent}
                                    exclusive
                                    onChange={handleComponentChange}
                                    aria-label="component-switcher"
                                >
                                    <ToggleButton
                                        className={formClasses.singleToggleButton}
                                        value="details" 
                                        aria-label="details-layout"
                                        disabled={currentComponent === 'details'}
                                        >
                                            Detalji
                                    </ToggleButton>
                                    <ToggleButton
                                        className={formClasses.singleToggleButton}
                                        value="applications" 
                                        aria-label="ratings-layout"
                                        disabled={currentComponent === 'ratings'}
                                        >
                                            Ocene
                                    </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" className={`${isMobile ? `${formClasses.bodyContainerMobile} ${formClasses.toggleLayoutsContainerMobile}` : `${formClasses.bodyContainer} ${formClasses.toggleLayoutsContainer}`}`}>
                        {(currentComponent === 'details') ? (
                            <>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <div className={isMobile ? classes.personalInfoContainerMobile : classes.personalInfoContainer}>
                                            {user?.profileImagePath ? (
                                                <div className={`${formClasses.avatarRatingsContainer}`}>
                                                    <div className={formClasses.avatarsHolderContainer}>
                                                        <StyledProfilePicture
                                                            style={{ pointerEvents: 'none' }}
                                                            width={86}
                                                            height={86}
                                                            alt={`profile-image-${user?.employeeId}`}
                                                            imageData={user?.profileImagePath as string}
                                                        />
                                                    </div>
                                                    <div className={formClasses.userDetailsRRatingsImage}>
                                                        <StarRoundedIcon className={`${formClasses.star} ${formClasses.pt3}`}/>
                                                        <div className={formClasses.gradeOverlay}>4.0</div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={`${formClasses.avatarRatingsContainer}`}>
                                                    <div className={`${formClasses.avatarContainer} ${formClasses.avatarsHolderContainer}`}>
                                                        <div className={formClasses.initialsHolderMedium}>
                                                        {`${user?.firstName[0]} ${user?.lastName[0]}`}
                                                        </div>
                                                        <div className={formClasses.UserDetailsRatings}>
                                                            <StarRoundedIcon className={formClasses.star}/>
                                                            <div className={formClasses.grade}>4.0</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className={classes.nameInfoContainer}>
                                                <div className={formClasses.userRatingsName}>
                                                    <span>
                                                        {`${user?.firstName} ${user?.lastName}`}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={classes.aboutMeContainer}>
                                                {user?.aboutMe}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <div className={formClasses.addDetailsContainer}>
                                            <div className={formClasses.detailsHeadlinesContainer}>
                                                <div className={`${formClasses.detailsText} ${formClasses.pt12} ${formClasses.pb12}`}>
                                                    <span className={classes.addDescriptionText}>Email:</span>
                                                </div>
                                                <div className={`${formClasses.detailsText} ${formClasses.pt12} ${formClasses.pb12}`}>
                                                    <span className={classes.addDescriptionText}>Telefon:</span>
                                                </div>
                                                <div className={`${formClasses.detailsText} ${formClasses.pt12} ${formClasses.pb12}`}>
                                                    <span className={classes.addDescriptionText}>Godine:</span>
                                                </div>
                                                <div className={`${formClasses.detailsText} ${formClasses.pt12} ${formClasses.pb12}`}>
                                                    <span className={classes.addDescriptionText}>Industrije:</span>
                                                </div>
                                            </div>
                                            <div className={classes.details}>
                                                <div className={`${formClasses.detailsText} ${formClasses.pt12} ${formClasses.pb12}`}>
                                                    <span className={`${formClasses.addDescriptionDetails}`}>{user?.emailAddress}</span>
                                                </div>
                                                <div className={`${formClasses.detailsText} ${formClasses.pt12} ${formClasses.pb12}`}>
                                                    <span className={`${formClasses.addDescriptionDetails} ${formClasses.fcolorLInki}`}>{user?.phoneNumber}</span>
                                                </div>
                                                <div className={`${formClasses.detailsText} ${formClasses.pt12} ${formClasses.pb12}`}>
                                                    <span className={formClasses.addDescriptionDetails}>{user?.age}</span>
                                                </div>
                                                <div className={`${formClasses.detailsText} ${formClasses.pt12} ${formClasses.pb12}`}>
                                                    {user?.industries.length !== 0 ? (
                                                        <span className={formClasses.addDescriptionDetails}>{user?.industries.map((industry) => industry).join(', ')}</span>

                                                    ) : (
                                                        <span className={formClasses.addDescriptionDetails}>Industrije još uvek nisu izabrane</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} className={formClasses.toggleButtonsContainer}>
                                <UserRatings ratings={fetchEmployeeRatings} />
                                <div className={formClasses.inputContainerTop}>
                                    <Divider />
                                </div>
                                <div className={classes.leaveRatingContainer}>
                                    <div className={classes.ratingsExplanationBox}>
                                        <span className={classes.ratingsText}>
                                            Ovim putem možete oceniti kandidata ukoliko ste sarađivali ili još uvek sarađujete u cilju da olakšate izbor drugim poslodavcima. 
                                            Molimo Vas da ukoliko niste ostvarivali saradnju ili imate nameru da neosnovano klevetate korisnika, 
                                            da to ne radite.
                                        </span>
                                    </div>
                                    <div className={classes.ratingsExplanationBox}>
                                        <strong>Svaka ocena će postati vidljiva tek nakon odobrenja admina. </strong>
                                    </div>
                                    <form onSubmit={handleButtonClick}>
                                        <div className={formClasses.inputContainerTop}>
                                            <div className={formClasses.ratingsTextSmall}>
                                                Ocenite poslodavca *
                                            </div>
                                            <Controller 
                                                name="rating"
                                                control={control}
                                                render={({field, fieldState}) => {
                                                    return (
                                                        <Box sx={{
                                                            '& > legend': { mt: 2 },
                                                            }}>
                                                                <Rating
                                                                    {...field}
                                                                    value={ratingValue}
                                                                    size="large"
                                                                    onChange={(event, newValue) => {
                                                                        field.onChange(newValue);
                                                                        setRatingValue(newValue)
                                                                    }}
                                                                
                                                                />
                                                        </Box>
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className={formClasses.inputContainer}>
                                            <Controller
                                                name="comment"
                                                control={control}
                                                rules={{
                                                    required: 'Polje je obavezno',
                                                    maxLength: 200
                                                }}
                                                render={({field, fieldState}) => {
                                                    return (
                                                        <StyledInput
                                                            variant="outlined"
                                                            multiline={true}
                                                            rows={4}
                                                            required={false}
                                                            type="text"
                                                            label="Komentar" 
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            inputRef={field.ref}
                                                            {...field}
                                                        />
                                                    )
                                                }}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions >
                <Grid container>
                    <Grid item xs={12} sm={12} md={4} className={isMobile ? classes.dialogActionsContainerMobile : classes.dialogActionsContainer}>
                        <StyledButton
                            style={{ textTransform: 'none'}}
                            
                            className={formClasses.w100pi}
                            variant="contained"
                            onClick={onAddToFavorites}
                            disabled={addedToFavorite}
                        >
                            {addedToFavorite ? 'Omiljen kandidat' : 'Dodaj u omiljene'}
                        </StyledButton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className={isMobile ? classes.dialogActionsContainerMobile : classes.dialogActionsContainer}>
                        <StyledButton
                            style={{ textTransform: 'none'}}
                            
                            className={formClasses.w100pi}
                            variant="text"
                            onClick={handleButtonClick}
                        >
                            Oceni korisnika
                        </StyledButton>
                        {errorModal && (
                            <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                                <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModal(false)} severity="error">Već ste ocenili korisnika</Alert>
                            </Backdrop>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className={isMobile ? classes.dialogActionsContainerMobile : classes.dialogActionsContainer}>
                        <StyledButton
                            style={{ textTransform: 'none'}}
                            className={formClasses.w100pi}
                            variant="outlined"
                            onClick={onClose}
                        >
                            Zatvori
                        </StyledButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default UserDialogCard
