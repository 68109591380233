import Grid from "@mui/material/Grid/Grid"
import useFormStyles from '../../styledComponents/globalFormStying.module'
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import useStyles from "./detailedCard.module";
import { useMediaQueries } from "../../../utils/global-utils";
import Chip from "@mui/material/Chip/Chip";
import { useContext, useState } from "react";
import UserDialogCard from "../../wwwApp/userDialog/userDialog";
import { Candidate } from "../../../utils/types.d";
import UserContext from "../../../context/UserContext";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Alert from '@mui/material/Alert';
import FavoritesApi from "../../../services/favoritesApi";
import StyledProfilePicture from "../../styledComponents/profileImage/profileImage";

interface DetailedCardPropsProps {
    addDetails: any;
    adId?: string
}

function DetailedCard({ addDetails, adId }: DetailedCardPropsProps) {

    const formClasses = useFormStyles();
    const classes = useStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const [hoveredUser, setHoveredUser] = useState<Candidate | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isCandidateFavorite, setIsCandidateFavorite] = useState<boolean>(false);
    const [errorModal, setErrorModal] = useState(false);

    const { userData, accessToken } = useContext(UserContext);

    const employerId = userData?.nameid

    const componentDetailsMap = [];
    if (addDetails.candidates) {
        componentDetailsMap.push(...addDetails.candidates)
    } else {
        componentDetailsMap.push(...addDetails)
    }
    
    const handleDialogOpen = (user: Candidate) => {
        setIsCandidateFavorite(user.isFavorite)
        setHoveredUser(user);
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setHoveredUser(null);
    };
    
    const handleAddCandidateToFavorite = async () => {
        
        try {
            const dataObject = {
                employerId: employerId,
                AdId: adId,
                employeeId: hoveredUser?.employeeId
            }
            
            if(!isCandidateFavorite) {
                await FavoritesApi.AddCandidateToFavorite(dataObject, accessToken);
                setIsCandidateFavorite(true);
            }
            
        } catch (error) {
            setErrorModal(true)
        }
    }
    
    return (
        <>
            {componentDetailsMap?.map((details: any, index: number) => (
                <Grid  key={index} container>
                    <Grid item xs={12} sm={12} md={12} onClick={() => handleDialogOpen(details)}>
                        <div className={formClasses.detailedCardContainer}>
                            <Grid container>
                                <Grid item xs={3} sm={3} md={1}>
                                    {details?.profileImagePath ? (
                                        <div className={`${formClasses.avatarsHolderContainerMedium}`}>
                                            <StyledProfilePicture
                                                style={{ pointerEvents: 'none' }}
                                                width={60}
                                                height={60}
                                                alt={`profile-image-${index}`}
                                                imageData={details?.profileImagePath as string}
                                            />
                                        </div>
                                    ) : (
                                        <div className={formClasses.avatarContainer}>
                                            <div key={index} className={formClasses.initialsHolderMedium}>{`${details.firstName[0]} ${details.lastName[0]}`}</div>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={6} sm={6} md={7}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div key={index} className={formClasses.personalDetailsContainer}>
                                                <div  className={isMobile ? formClasses.candidateNameMobile : formClasses.canditateName}>
                                                    <span>
                                                        {`${details.firstName} ${details.lastName}`}
                                                    </span>
                                                </div>
                                                <FiberManualRecordIcon className={formClasses.dotSeparator}/>
                                                <StarRoundedIcon className={formClasses.star}/>
                                                <div className={isMobile ? formClasses.gradeMobile : formClasses.grade}>4.0</div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Grid container key={index}>
                                                <Grid item xs={12} sm={2} md={3}>
                                                    <div className={isMobile ? `${formClasses.mt12} ${formClasses.mb12}` : ''}>
                                                        <span className={classes.addDescriptionText}>telefon:</span>
                                                        <span className={`${classes.moreInfoDetails} ${formClasses.fcolorLInki}`}>{details.phoneNumber}</span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}  sm={2} md={3} className={`${isMobile ? ' ' : `${formClasses.dflexI} ${formClasses.jContenntCenterI}`}`}>
                                                    <div className={isMobile ? `${formClasses.mt12} ${formClasses.mb12}` : ''}>
                                                        <span className={classes.addDescriptionText}>godine:</span>
                                                        <span className={classes.moreInfoDetails}>{details.age}</span>
                                                    </div>
                                                    
                                                </Grid>
                                                {details.applicationMessage && (
                                                    <Grid item xs={12}  sm={8} md={6}>
                                                        <div className={` ${isMobile ? `${formClasses.mt12} ${formClasses.mb12}` : ''}`}>
                                                            <span className={classes.addDescriptionText}>poruka:</span>
                                                            <span className={classes.moreInfoDetails}>{details.applicationMessage}</span>
                                                        </div>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} sm={3} md={4}>
                                    <Grid container>
                                        <Grid className={formClasses.pb12} item xs={12} display="flex" justifyContent="flex-end">
                                            {details.isFavorite && (<Chip label="Omiljeni" className={classes.sponsoredChip} />)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <UserDialogCard
                            open={isDialogOpen} 
                            onClose={handleDialogClose} 
                            onAddToFavorites={handleAddCandidateToFavorite} 
                            user={hoveredUser}
                            addedToFavorite={isCandidateFavorite}
                        />
                    </Grid>
                    {errorModal && (
                        <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                            <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModal(false)} severity="error">Došlo je do greške!</Alert>
                        </Backdrop>
                    )}
                </Grid>
            ))}
        </>
    )
}

export default DetailedCard