import axios, { AxiosResponse } from "axios";
import { APP_CORE } from "../environment";

const GET_NUMBER_OF_FAVORITE_CANDIDATES = '/Favorite/getNumberOfFavoriteCandidates'
const GET_NUMBER_OF_FAVORITE_ADS = '/Favorite//getNumberOfFavoriteAds'
const GET_FAVORITE_CANDIDATES = '/Favorite/getFavoriteCandidates'
const GET_FAVORITE_ADS = '/Favorite//getFavoriteAds';
const ADD_CANDIDATE_TO_FAVORITE = '/Favorite/addFavoriteCandidate'
const ADD_FAVORITE_AD = '/Favorite/addFavoriteAd'

const api = axios.create({
    baseURL: APP_CORE
});

const apiGet = (
    operation: string, 
    accessToken: string | null
): Promise<AxiosResponse<any>> => {
    return new Promise((resolve, reject) => {
        api.get(operation, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error)
        })
    })
}

const apiPost = (
    operation: string, 
    data: object, 
    accessToken: string | null) => {
    return new Promise((resolve, reject) => {
      api.post(operation, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          data
        }
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
};

const getNumberOfFavoriteCandidates = async (accessToken: string | null) => {
  
    return apiGet(GET_NUMBER_OF_FAVORITE_CANDIDATES, accessToken)

}

const getNumberOfFavoriteAds = async(accessToken: string | null) => {

    return apiGet(GET_NUMBER_OF_FAVORITE_ADS, accessToken)
}

const getFavoriteCandidates = async (accessToken: string | null) => {
  
    return apiGet(GET_FAVORITE_CANDIDATES, accessToken)

}

const getFavoriteAds = async (accessToken: string | null) => {

    return apiGet(GET_FAVORITE_ADS, accessToken)
}

const AddCandidateToFavorite = async (data: object, accessToken: string | null) => {
    
    return apiPost(ADD_CANDIDATE_TO_FAVORITE, data, accessToken)
}

const AddFavoriteAd = async (data: object, accessToken: string | null) => {
    
    return apiPost(ADD_FAVORITE_AD, data, accessToken)
}

const FavoritesApi = {
    getNumberOfFavoriteCandidates,
    getNumberOfFavoriteAds,
    getFavoriteCandidates,
    getFavoriteAds,
    AddCandidateToFavorite,
    AddFavoriteAd
}

export default FavoritesApi
