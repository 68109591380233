import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles((theme) => ({
    table: {
        borderRadius: '18px !important',
    },
    tableRow: {
        '&:nth-child(odd)': {
            backgroundColor: '#EBEFFF !important'
        }
    },
    detailsText: {
        color: '#636366 !important',
        fontSize: '14px !important',
        fontWeight: 'bold !important'
    },
    detailsHeadlines: {
        color: '#636366 !important',
        fontSize: '14px !important',
    },
    addDescriptionTextPreview: {
        fontSize: '16px',
        color: '#636366'
    },
}));

export default useStyles