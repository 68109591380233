import { styled } from '@mui/system';
import {  Button } from '@material-ui/core';


const variantStyles = {
  contained: {
    backgroundColor: '#0033FF !important',
    hoverBackgroundColor: '#FFFFFF !important',
    color: '#FFFFFF !important',
    hoverColor: '#0033FF !important',
    border: ''
  },
  outlined: {
    backgroundColor: '#FFFFFF !important',
    hoverBackgroundColor: '#FFFFFF !important',
    color: '#FF221A !important',
    hoverColor: '#FF221A !important',
    border: '1px solid #FF221A !important'
  },
  text: {
    backgroundColor: '#FFFFFF !important',
    hoverBackgroundColor: '#0033FF !important',
    color: '#0033FF !important',
    hoverColor: '#FFFFFF !important',
    border: '1px solid #0033FF !important',
  }
};

const StyledButton = styled(Button)(({ variant }) => ({
       
  borderRadius: '30px !important',
  textTransform: 'none',
  backgroundColor: variant ? variantStyles[variant]?.backgroundColor : '',
  color: variant ? variantStyles[variant]?.color : '',
  border: variant ? variantStyles[variant]?.border :  '',
  width: '320px',
  height: '48px !important',
  padding: '10px 20px !important',
  fontSize: '14px !important',
  lineHeight: '12px !important',
  position: 'relative',
  '&:hover': {
    backgroundColor: variant ? variantStyles[variant]?.hoverBackgroundColor : '',
    color: variant ? variantStyles[variant]?.hoverColor : '',
    border: variant ? variantStyles[variant]?.border : ''
  },
  '.circular-progress': {
    color: '#FFFFFF'
  },
  '&:hover .circular-progress': {
    color: '#0033FF'
  },
  '&:active .circular-progress': {
    color: '#0033FF'
  }
}));

export default StyledButton