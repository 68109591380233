import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles((theme) => ({
    addDescriptionText: {
        fontSize: '14px',
        color: '#636366'
    },
    moreInfoDetails: {
        fontSize: '14px',
        fontWeight: 'bold',
        paddingLeft: '6px',
        paddingRight: '6px'
    },
}));

export default useStyles