import Grid from '@mui/material/Grid';
import LandingBody from './landingPageBody/landingBody';


function LandingPage() {

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <LandingBody/>
                </Grid>
            </Grid>
        
        </>
    );
}

export default LandingPage

