import axios, { AxiosResponse } from "axios";
import { APP_CORE } from "../environment";

const GET_ALL_EMPLOYER_RATINGS = '/Ratings/getAllEmployerRatings'
const GET_ALL_EMPLOYEE_RATINGS = '/Ratings/getAllEmployeeRatings'
const ADD_RATING_FOR_EMPLOYER = '/Ratings/addRatingForEmployer'
const ADD_RATING_FOR_EMPLOYEE = '/Ratings/addRatingForEmployee'
const EMPLOYER_REPLY_TO_RATING = '/Ratings/addEmployerReplyToRating'
const EMPLOYEE_REPLY_TO_RATING = '/Ratings/addEmployeeReplyToRating'
const GET_ABUSE_TYPES = '/Ratings/getAbuseTypes'
const EMPLOYER_REPORT_ABUSE = '/Ratings/employerReportsAbuse'
const EMPLOYEE_REPORT_ABUSE = '/Ratings//employeeReportsAbuse'

const api = axios.create({
    baseURL: APP_CORE
});

const apiGet = (
    operation: string, 
    accessToken: string | null, 
    userId?: string | undefined,
    roleType?: 'employee' | 'employer'
    ): Promise<AxiosResponse<any>> => {
    return new Promise((resolve, reject) => {
        const params: Record<string, string> = {};
        if (roleType === 'employee') {
            params.EmployeeId =  `${userId}`
        } else if (roleType === 'employer') {
            params.EmployerId = `${userId}`
        }
        api.get(operation, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error)
        })
    })
}

const apiPost = (
    operation: string, 
    data: object, 
    accessToken: string | null) => {
    return new Promise((resolve, reject) => {
      api.post(operation, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          data
        }
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
};

const GetAllEmployeeRatings = async (accessToken: string | null, employeeId: string | undefined) => {

    return apiGet(GET_ALL_EMPLOYEE_RATINGS, accessToken, employeeId, 'employee')
}

const GetAllEmployerRatings = async (accessToken: string | null, employerId: string | undefined) => {

    return apiGet(GET_ALL_EMPLOYER_RATINGS, accessToken, employerId, 'employer')
}

const GetAbuseTypes = async (accessToken: string | null) => {
    
    return apiGet(GET_ABUSE_TYPES, accessToken)
}

const AddRatingForEmployer = async (data: object, accessToken: string | null) => {

    return apiPost(ADD_RATING_FOR_EMPLOYER, data, accessToken)
}

const AddRatingForEmployee = async (data: object, accessToken: string | null) => {

    return apiPost(ADD_RATING_FOR_EMPLOYEE, data, accessToken)
}

const EmployerReplyToRating = async (data: object, accessToken: string | null) => {

    return apiPost(EMPLOYER_REPLY_TO_RATING, data, accessToken)
}

const EmployeeReplyToRating = async (data: object, accessToken: string | null) => {

    return apiPost(EMPLOYEE_REPLY_TO_RATING, data, accessToken)
}

const EmployerReportAbuse = async (data: object, accessToken: string | null) => {

    return apiPost(EMPLOYER_REPORT_ABUSE, data, accessToken)
}

const EmployeeReportAbuse = async (data: object, accessToken: string | null) => {

    return apiPost(EMPLOYEE_REPORT_ABUSE, data, accessToken)
}

const RatingsApi = {
    GetAllEmployeeRatings,
    GetAllEmployerRatings,
    AddRatingForEmployer,
    AddRatingForEmployee,
    EmployerReplyToRating,
    EmployeeReplyToRating,
    GetAbuseTypes,
    EmployeeReportAbuse,
    EmployerReportAbuse
}

export default RatingsApi
