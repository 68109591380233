import './App.css';
import { Routes, Route, Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme/theme'
import LandingPage from './components/wwwLandingPage/landingPage';
import RolePicker from './components/wwwApp/rolePicker/rolePicker';
import Register from './components/wwwApp/register/register'
import EmployeeFinalStep from './components/wwwApp/employeeFinalStep/employeeFinal';
import EmployerFinalStep from './components/wwwApp/employerFinalStep/employerFinal';
import VerificationScreen from './components/wwwApp/verificationScreen/verificationScreen';
import EmployerDashboard from './components/wwwApp/employerDashboard/employerDashboard';
import NewJob from './components/wwwApp/newJob/newJob';
import Login from './components/wwwApp/login/login';
import ForgotPassword from './components/wwwApp/forgotPassword/forgotPassword';
import ForgotPasswordConfirmation from './components/wwwApp/forgotPasswordConfirmation/forgotPasswordConfirmation';
import PasswordResetVerify from './components/passwordResetVerify/passwordResetVerify';
import { UserProvider } from './context/UserContext'
import EmailVerify from './components/wwwApp/emailVerify/emailVerify';
import EmployerProfile from './components/wwwApp/employerProfile/employerProfile';
import MyAddDetailed from './components/wwwApp/myAddDetailed/myAddDetailed';
import EditAdd from './components/wwwApp/editAdd/editAdd';
import FavoriteCandidates from './components/wwwApp/favoriteCandidates/favoriteCandidates';
import ActiveAds from './components/wwwApp/activeAds/activeAds';
import ReceivedApplications from './components/wwwApp/receivedApplications/receivedApplications';
import EmployeeDashboard from './components/wwwApp/employeeDashboard/employeeDashboard';
import AdProfile from './components/wwwApp/adProfile/adProfile';
import EmployeeProfile from './components/wwwApp/employeeProfile/employeeProfile';
import AdsDashboard from './components/wwwApp/adsDashboard/adsDashboard';
import EmployersHub from './components/wwwApp/employersHub/employersHub';
import FavoriteAds from './components/wwwApp/favoriteAds/favoriteAds';
import ApliedAds from './components/wwwApp/appliedAds/apliedAds';
import EmployerReadOnlyProfile from './components/wwwApp/employerReadOnlyProfile/employerReadOnlyProfile';
import PrivateRoutes from './privateRoute';
import TermsAndConditionsComponent from './components/wwwApp/termsAndConditions/termsAndConditions';
import PrivacyPolicyComponent from './components/wwwApp/privacyPolicy/privacyPolicy';
import AdProfilePreview from './components/wwwApp/adProfilePreview/adProfilePreview';
import SearchAdsLanding from './components/wwwApp/searchAdsLanding/searchAdsLanding';
import TermsAndConditionsGeneral from './components/wwwLandingPage/termsAndConditionsGeneral/termsAndConditionsGeneral';
import PrivacyPolicyGeneral from './components/wwwLandingPage/privacyPolicyGeneral/privacyPolicyGeneral';
import AdminPanel from './components/wwwApp/adminPanel/adminPanel';
import AdsBulletLinks from './components/wwwApp/adsBulletLinks/adsBulletLinks';
import EmployersHubLanding from './components/wwwApp/employersHubLanding/employersHubLanding';

function App() {
  return (
    <UserProvider>
        <ThemeProvider theme={theme}>
            <div className="App">
              <Routes>
                <Route element={<LandingPage />} path="/"/>
                <Route element={<AdProfilePreview />} path="/ad-profile-preview/:addId"/>
                <Route element={<AdsBulletLinks />} path="/ads/:token" />
                <Route element={<SearchAdsLanding />} path="browse-ads"/>
                <Route element={<EmployersHubLanding />} path="browse-employers"/>
                <Route element={<RolePicker />} path="/role-picker"/>
                <Route element={<Register />} path="/register/:selectedCard"/>
                <Route element={<EmployeeFinalStep />} path="/employee/final-step/:userId"/>
                <Route element={<EmployerFinalStep />} path="/employer/final-step/:userId"/>
                <Route element={<VerificationScreen />} path="/account-verification/:userId"/>
                <Route element={<EmailVerify />} path="/email-verify/:token"/>
                <Route element={<Login />} path="/login"/>
                <Route element={<ForgotPassword />} path="/forgot-password-email"/>
                <Route element={<ForgotPasswordConfirmation />} path="/forgot-password-confirmation"/>
                <Route element={<PasswordResetVerify />} path="/forgot-password/:token" />
                <Route element={<TermsAndConditionsGeneral />} path="/terms-and-conditions-general"/>
                <Route element={<PrivacyPolicyGeneral />} path="/privacy-policy-general"/>
                <Route element={<PrivateRoutes />}>
                  <Route element={<AdminPanel />} path="/admin-panel/"/>
                  <Route element={<EmployerDashboard />} path="/employer-dashboard/"/>
                  <Route element={<EmployeeDashboard />} path="/employee-dashboard/"/>
                  <Route element={<NewJob />} path="/new-job/"/>
                  <Route element={<EmployerProfile />} path="/employer-profile"/>
                  <Route element={<EmployeeProfile />} path="/employee-profile"/>
                  <Route element={<MyAddDetailed />} path="/my-ad/:addId"/>
                  <Route element={<AdProfile />} path="/ad-profile/:addId"/>
                  <Route element={<EditAdd />} path="/edit-ad/:addId"/>
                  <Route element={<FavoriteCandidates />} path="/my-favorite-candidates"/>
                  <Route element={<ActiveAds />} path="/my-active-ads"/>
                  <Route element={<ReceivedApplications />} path="/my-received-applications"/>
                  <Route element={<AdsDashboard />} path="/ads-dashboard"/>
                  <Route element={<EmployersHub />} path="/employers-hub"/>
                  <Route element={<EmployerReadOnlyProfile />} path="/employer-detailed/:userId"/>
                  <Route element={<FavoriteAds />} path="/my-favorite-ads"/>
                  <Route element={<ApliedAds />} path="/my-applied-ads"/>
                  <Route element={<TermsAndConditionsComponent />} path="/terms-and-conditions"/>
                  <Route element={<PrivacyPolicyComponent />} path="/privacy-policy"/>
                </Route>
              </Routes>
            </div>
        </ThemeProvider>
    </UserProvider>
  );
}

export default App;

