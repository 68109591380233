import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQueries } from '../../../utils/global-utils';
import { useEffect, useState } from 'react';
import { AdResponse } from '../../../utils/types.d';
import AddApi from '../../../services/addApi';
import useStyles from './cardsSwipeable.module';
import AdsCardLanding from '../../wwwApp/adsCardLanding/adsCardLanding';
import Grid from "@mui/material/Grid/Grid"
import Skeleton from '@mui/material/Skeleton';


function CardsSwipeable() {
    const { isMobile, isTablet } = useMediaQueries();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [fetchAds, setFetchAds] =  useState<AdResponse[]>([]);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const addResponse = await AddApi.GetAllAds({});
            setFetchAds(addResponse.data)
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        };

    
        fetchData();
    }, []);

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1,
        }
    };

    const skeletonItems: number = isMobile ? 1 : 3
    return (
        <>
            <div className={classes.carouselContainer}>
                {loading ? (
                    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                        {Array.from({ length: skeletonItems }).map((_, index) => (
                            <Grid item xs={12} sm={12} md={4} key={index}>
                                <Skeleton className={classes.skeleton} variant="rectangular" width="100%" height={300} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        infinite={false}
                        autoPlay={false}
                        autoPlaySpeed={5000}
                        keyBoardControl={false}
                        removeArrowOnDeviceType={["desktop"]}
                        containerClass="carousel-container"
                        itemClass="carousel-item-padding-40-px"
                        responsive={responsive}
                    >
                        {fetchAds?.map((addDetails: any, index: number) => (
                            <AdsCardLanding key={index} addDetails={addDetails} />
                        ))}  
                    </Carousel>
                )}
            </div>
        </>
    )
}

export default CardsSwipeable