import { useNavigate, useParams } from "react-router-dom";
import { 
    formatBillingType,
    formatCity,
    formatCountry,
    formatCurrency,
    formatDate, 
    generateOfferedItemsText, 
    getUserId, 
    handleNavigate, 
    useMediaQueries 
} from "../../../utils/global-utils";
import TextField from '@mui/material/TextField';
import useFormStyles from "../../styledComponents/globalFormStying.module";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../context/UserContext";
import FunctionalHeader from "../../functionalHeader/functionalHeader";
import useStyles from "./adProfile.module";
import Grid from "@mui/material/Grid/Grid"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import StyledButton from "../../styledComponents/styledButton.module";
import { AddDetails, SelectedAddResponse } from "../../../utils/types.d";
import FavoritesApi from "../../../services/favoritesApi";
import AddApi from "../../../services/addApi";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import StyledSpinningIcon from "../../styledComponents/spinningIcon/spinningIcon";
import StyledApplyingModal from "../../styledComponents/styledApplyingModal/styledApplyingModal";
import { Controller, useForm } from "react-hook-form";
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function AdProfile() {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const { addId } = useParams();
    const { userData, accessToken } = useAuthContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fetchSelectedAdd, setfFetchSelectedAdd] = useState<SelectedAddResponse[]>([]);
    const [errorModal, setErrorModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [errorApplyForAd, setErrorAplyForAd] = useState(false);
    const [successfulApply, setSuccessfulApply] = useState(false);
    const [errorWithdrawApplication, setErrorWithDrawApplication] = useState(false);
    const [successfulWithdrawal, setSuccessfulWithdrawal] =  useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDetails = async () => {
          try {
            if (accessToken) {

                const selectedAddResponse: any = await AddApi.GetSelectedAd(accessToken, addId);
                setfFetchSelectedAdd([selectedAddResponse.data]);
                setLoading(false);
            }
          } catch (error) {
            setfFetchSelectedAdd([]);
            setLoading(false);
          }
        };

        if (accessToken) {
            fetchDetails();
        }
    }, [accessToken])
   
    const userId = getUserId(userData);
    const { 
        control, 
        handleSubmit, 
        trigger, 
        formState
    } = useForm();

    const handleAddFavoriteAd = async () => {
        if (!accessToken) {
            return; 
        }
        try {
            const dataObject = {
                employeeId: userId,
                adId: addId,
            }
            const response: any = await FavoritesApi.AddFavoriteAd(dataObject, accessToken);
            const { status } = response;
            if (status === 200) {
                setSuccessModal(true);
            } 
        } catch (error) {
            setErrorModal(true)
        }
    }

    const handleWithdrawApplication = async () => {
        try {
            
            const response: any = await AddApi.WithdrawApplication(accessToken, addId, userId);
            const { status } = response;
            if (status === 200) {
                setSuccessfulWithdrawal(true);
            } 
        } catch (error) {
            setErrorWithDrawApplication(true);
        }
    };

    const handleApply = async () => {
        setIsModalOpen(true);
    };

    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    const onSubmit = async (formData: any) => {   
        try {
            const dataObject = {
                employeeId: userId,
                adId: addId,
                message: formData?.message
            }

            const response: any = await AddApi.AddAplication(dataObject, accessToken);
            const { status } = response;
            if (status === 200) {
                setSuccessfulApply(true);
            } 
        } catch (error) {
            setErrorAplyForAd(true);
        }
    }

    const handleButtonClick = async () => {
        try {
            setIsModalOpen(false);
            await trigger();
            if (Object.keys(formState.errors).length === 0) {
                await handleSubmit(onSubmit)();
            }
        }
        catch (err) {
            //
        }
    };
    
    return (
        <Grid container>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} className={isMobile ? `${formClasses.ml12} ${formClasses.mr12}` : ''}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <FunctionalHeader />
                    </Grid>
                </Grid>
                <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                    <div className={formClasses.companyNameHeadline}>
                        <ArrowBackIcon 
                            className={formClasses.navigateBack}
                            onClick={() => navigate(-1)} /> Profil oglasa
                    </div>
                </div>
                <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                    <div className={formClasses.bodyItemsHolder}>
                    {loading ? (
                        <Backdrop open={true}>
                            <div className={formClasses.circularProgressContainer}>
                                <StyledSpinningIcon />
                            </div>
                        </Backdrop>
                    ) : (
                        <>
                        <Grid item xs={12} sm={12} md={12}>
                            {fetchSelectedAdd.map((selectedAdDetails, index) => (
                                <div key={index}>
                                    <div className={`${formClasses.actionCardsText}`}>
                                        {selectedAdDetails?.adTitle}
                                    </div>
                                    {!fetchSelectedAdd[0]?.isOpenForApplications && (

                                        <div className={`${formClasses.detailsText} ${formClasses.mt12}`}>
                                            <span className={classes.addDescriptionText}>Prijave na ovom oglasu su zatvorene</span>
                                        </div>
                                    )}
                                    <div className={formClasses.inputContainerTop}>
                                            <span className={classes.addDescriptionTextPreview}>
                                                {selectedAdDetails?.adDescription}
                                            </span>
                                    </div>
                                    <div className={formClasses.inputContainerTop}>
                                        <Divider />
                                    </div>
                                    <TableContainer component={Paper} className={classes.table}>
                                        <Table>
                                            <TableBody>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                        Poslodavac
                                                    </TableCell>
                                                    <TableCell align="right" onClick={() => handleNavigate(navigate, `/employer-detailed/${fetchSelectedAdd[0]?.employerId}`)}>
                                                        <span className={`${formClasses.fcolorLInki}`}>{selectedAdDetails?.companyName}</span>
                                                        <StarRoundedIcon className={`${formClasses.star} ${formClasses.pl6} ${formClasses.pr6} ${formClasses.pt3}`}/>
                                                        <span className={`${formClasses.grade} ${formClasses.p0i}`}>{selectedAdDetails?.averageRating}</span>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                            Industrija/e
                                                    </TableCell>
                                                    <TableCell align="right" className={`${classes.detailsText}`}>
                                                        {selectedAdDetails?.industries.map((industry) => industry?.industryName).join(', ')}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Država</TableCell>
                                                    <TableCell align="right" className={`${classes.detailsText}`}>
                                                        {formatCountry(selectedAdDetails?.country?.countryName as string)}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Grad</TableCell>
                                                    <TableCell align="right" className={`${classes.detailsText}`}>
                                                        {formatCity(selectedAdDetails?.city?.city as string)}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`} >
                                                        {selectedAdDetails?.workingEndDate === null ? 'Početak rada' : 'Trajanje'}
                                                    </TableCell>
                                                    <TableCell align="right" className={`${classes.detailsText}`}>
                                                        {formatDate(selectedAdDetails?.workingStartDate as string)} / {selectedAdDetails?.workingEndDate === null ? '_' : formatDate(selectedAdDetails?.workingEndDate as string)}
                                                    </TableCell>
                                                </TableRow>
                                                {selectedAdDetails?.billingType?.typeOfBilling  && (
                                                    <TableRow className={classes.tableRow}>
                                                        <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Tip naplate</TableCell>
                                                        <TableCell align="right" className={`${classes.detailsText}`}>
                                                            {formatBillingType(selectedAdDetails?.billingType?.typeOfBilling as string)}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                                <TableRow className={classes.tableRow}>
                                                    {selectedAdDetails?.salaryValue && selectedAdDetails?.currency?.currencyName ? (
                                                        <>
                                                            <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Iznos</TableCell>
                                                            <TableCell align="right" className={`${classes.detailsText}`}>
                                                                {selectedAdDetails?.salaryValue} {formatCurrency(selectedAdDetails?.currency?.currencyName as string)}
                                                            </TableCell>
                                                        </>
                                                        
                                                    ) : (
                                                        <>
                                                            <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Isplata</TableCell>
                                                            <TableCell align="right" className={`${classes.detailsText}`}>Po dogovoru</TableCell>
                                                        </>
                                                        
                                                    )}
                                                </TableRow>
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                        Benefiti
                                                    </TableCell>
                                                    <TableCell align="right" className={`${classes.detailsText}`}>
                                                        {generateOfferedItemsText(selectedAdDetails as AddDetails) || "Bez benefita"}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            ))}
                        </Grid>
                        <div className={formClasses.inputContainerTop}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    <form onSubmit={handleButtonClick}>
                                        <div className={formClasses.mb12}>
                                            <StyledButton
                                                style={{ textTransform: 'none'}}
                                                className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                                                variant="contained"
                                                onClick={handleApply}
                                                disabled={fetchSelectedAdd[0]?.userAppliedToTheAd ||  !fetchSelectedAdd[0]?.isOpenForApplications}
                                            >
                                                {fetchSelectedAdd[0]?.userAppliedToTheAd ? 'Prijava poslata' : 'Prijavi se'}
                                            </StyledButton>
                                            <StyledApplyingModal
                                                open={isModalOpen}
                                                onClose={handleModalCancel}
                                                onConfirm={handleButtonClick}
                                                titleText="Prijava na oglas"
                                                bodyText={`Ovim putem prijavićete se na oglas "${fetchSelectedAdd[0]?.adTitle}". 
                                                    Preporučujemo Vam da ostavite kratku poruku poslodavcu što će potencijalno povećati vaše šanse da vas poslodavac izabere.`}
                                                inputText={`Poruka poslodavcu (opciono)`}
                                                confirmButtonText="Prijavi se"
                                            >
                                                <Controller
                                                    name="message"
                                                    control={control}   
                                                    render={({field, fieldState}) => {
                                                        return (
                                                            <TextField  
                                                                {...field}
                                                                className={formClasses.applyTextField}
                                                                multiline={true}
                                                                maxRows={4}
                                                                inputRef={field.ref}
                                                            />
                                                        )
                                                    }}
                                                />
                                            </StyledApplyingModal>
                                            {successfulApply && (
                                                <Backdrop className={formClasses.zIndexHighest} open={successfulApply}>
                                                    <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setSuccessfulApply(false)} severity="success">Uspešna prijava</Alert>
                                                </Backdrop>
                                            )}
                                            {errorApplyForAd && (
                                                <Backdrop className={formClasses.zIndexHighest} open={errorApplyForAd}>
                                                    <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorAplyForAd(false)} severity="error">Prijava je već poslata</Alert>
                                                </Backdrop>
                                            )}
                                        </div>
                                    </form>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <div className={formClasses.mb12}>
                                        <StyledButton
                                            style={{ textTransform: 'none'}}
                                            className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                                            disabled={fetchSelectedAdd[0]?.isFavorite}
                                            variant="text"
                                            onClick={() => handleAddFavoriteAd()}
                                            >
                                            {fetchSelectedAdd[0]?.isFavorite === true ? 'Omiljen oglas' : 'Dodaj u omiljene'}
                                        </StyledButton>
                                        {successModal && (
                                            <Backdrop className={formClasses.zIndexHighest} open={successModal}>
                                                <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setSuccessModal(false)} severity="success">Uspešno dodavanje oglasa u omiljene</Alert>
                                            </Backdrop>
                                        )}
                                        {errorModal && (
                                            <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                                                <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModal(false)} severity="error">Oglas je već u omiljenima</Alert>
                                            </Backdrop>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {fetchSelectedAdd[0]?.userAppliedToTheAd && (
                                        <>
                                            <div>
                                                <StyledButton
                                                    style={{ textTransform: 'none'}}
                                                    className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                                                    variant="outlined"
                                                    onClick={() => handleWithdrawApplication()}
                                                >
                                                    Povuci prijavu
                                                </StyledButton>
                                            </div>
                                            {successfulWithdrawal && (
                                                <Backdrop className={formClasses.zIndexHighest} open={successfulWithdrawal}>
                                                    <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setSuccessfulWithdrawal(false)} severity="success">Prijava je povučena</Alert>
                                                </Backdrop>
                                            )}
                                            {errorWithdrawApplication && (
                                                <Backdrop className={formClasses.zIndexHighest} open={errorWithdrawApplication}>
                                                    <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorWithDrawApplication(false)} severity="error">Prijava na ovom oglasu je već povučena</Alert>
                                                </Backdrop>
                                            )}
                                        </>
                                        
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                        </>
                    )}
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
        </Grid>
    )

}

export default AdProfile