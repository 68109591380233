import { useMediaQueries, handleNavigate } from "../../../utils/global-utils";
import { FilteredEmployerResponse } from "../../../utils/types.d";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import useStyles from "./detailedEmployerCard.module";
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Grid from "@mui/material/Grid/Grid"
import { useNavigate } from "react-router-dom";
import StyledProfilePicture from "../../styledComponents/profileImage/profileImage";


function DetailedEmployerCard({ employerDetails }: any) {

    const formClasses = useFormStyles();
    const classes = useStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate(); 

    const handleNavigateToProfile = (employerId: string) => {
        handleNavigate(navigate, `/employer-detailed/${employerId}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
    return (
       <>
            {employerDetails?.map((details: FilteredEmployerResponse, index: number) => (
                <Grid 
                    key={index} container 
                    onClick={() => handleNavigateToProfile(details.employerId)}>
                    <Grid item xs={12} sm={12} md={12} >
                        <div className={formClasses.detailedCardContainer}>
                            <Grid container>
                                <Grid item xs={3} sm={3} md={1}>
                                    {details?.image ? (
                                        <div className={`${formClasses.avatarsHolderContainerMedium}`}>
                                            <StyledProfilePicture
                                                style={{ pointerEvents: 'none' }}
                                                width={60}
                                                height={60}
                                                alt={`employer-profile-image-${index}`}
                                                imageData={details?.image as string}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            {details.companyName && (
                                                <div className={formClasses.avatarContainer}>
                                                    <div key={index} className={formClasses.initialsHolderMedium}>{`${details?.companyName[0]}`}</div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={6} sm={6} md={9}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div className={formClasses.personalDetailsContainer}>
                                                <div  className={formClasses.canditateName}>
                                                    {`${details.companyName}`}
                                                </div>
                                                <FiberManualRecordIcon className={formClasses.dotSeparator}/>
                                                <StarRoundedIcon className={formClasses.star}/>
                                                <div className={formClasses.grade}>{details.averageRating}</div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Grid container>
                                                <span className={classes.addDescriptionText}>aktivnih oglasa:</span>
                                                <span className={classes.moreInfoDetails}>{details.numberOfActiveAds}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            ))}
       </>
    )
}

export default DetailedEmployerCard;