import useStyles from "./employerProfile.module"
import useFormStyles from "../../styledComponents/globalFormStying.module";
import { getInitials, getUserId, handleNavigate, useMediaQueries } from "../../../utils/global-utils";
import Grid from "@mui/material/Grid/Grid"
import FunctionalHeader from "../../functionalHeader/functionalHeader";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Backdrop from "@mui/material/Backdrop/Backdrop";
import StyledSpinningIcon from "../../styledComponents/spinningIcon/spinningIcon";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import Switch from "@mui/material/Switch";
import { CircularProgress } from "@material-ui/core";
import { Button, Typography } from "@material-ui/core";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from "@mui/material/Box/Box";
import Rating from '@mui/material/Rating';
import Chip from "@mui/material/Chip/Chip";
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import logoBlue from '../../../assets/logo_blue.svg'
import { StyledInput } from "../../styledComponents/styledInput/styledInput";
import StyledButton from "../../styledComponents/styledButton.module";
import { 
    AbusementTypeResponse,
        EmployerDetailsResponse, 
        EmployerRatingsResponse, 
        Industry 
} from '../../../utils/types.d'
import { SelectChangeEvent } from '@mui/material/Select';
import { StyledSelect } from "../../styledComponents/styledSelect/styledSelect";
import EmployerApi from '../../../services/employerApi'
import { useAuthContext } from "../../../context/UserContext";
import BillingAndIndustriesApi from "../../../services/billingAndIndustries";
import SOCIAL_MEDIA_ICONS from "../../../assets/media";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import RatingsApi from "../../../services/ratingsApi";
import UserRatingsWithReplies from "../userRatingsWithReplies/userRatingsWithReplies";
import StyledApplyingModal from "../../styledComponents/styledApplyingModal/styledApplyingModal";
import StyledProfilePicture from "../../styledComponents/profileImage/profileImage";
import ImageUploaderThumbnail from "../../styledComponents/imagePreviewThumbnail/imagePreviewThumbnail";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


function EmployerProfile() {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();

    const [currentComponent, setCurrentComponent] = useState('details');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAbusementModalOpen, setIsAbusementModalOpen] = useState(false);
    const [ratingValue, setRatingValue] = useState<number | null>(3);

    const [fetchEmployerDetails, setFetchEmployerDetails] = useState<EmployerDetailsResponse | null>(null);
    const [fetchEmployerRatings, setFetchEmployerRatings] = useState<EmployerRatingsResponse[] | []>([]);

    const [fetchAbuseTypes, setFetchAbuseTypes] = useState<AbusementTypeResponse[] | null>(null);
    const [selectedAbusementTypeId, setSelectedAbusementTypeId] = useState<string | null>(null);

    const [industry, setIndustry] = useState<string[]>([]);
    const [fetchIndustries, setFetchIndustries] = useState<Industry[]>([]);

    const [isSwitched, setIsSwitched] = useState<boolean>(false);
    const [isNotifyMeSwitched, setIsNotifyMeSwitched] = useState<boolean>(true);

    const [loading, setLoading] = useState(false);
    const [imageData, setImageData] = useState<string | null>("");
    const [notifyProfileSetup, setNotifyProfileSetup] = useState(false);


    const { userData, accessToken } = useAuthContext();
    const userId = getUserId(userData);
    const initials = getInitials(userData);

    useEffect(() => {

        const hasNotificationBeenShown = localStorage.getItem('profileSetupNotificationShown');
        if (accessToken && !hasNotificationBeenShown) {
            
            setNotifyProfileSetup(true);
            localStorage.setItem('profileSetupNotificationShown', 'true');
        }
        
    }, [accessToken]);

    const handleCloseProfileSetupModal = () => {
        setNotifyProfileSetup(false);
        
    }

    useEffect(() => {

        const fetchDetails = async () => {
          try {
            if (accessToken) {
              const employerDetailsResponse = await EmployerApi.GetEmployerDetails(accessToken, userId);
              setFetchEmployerDetails(employerDetailsResponse.data);
              setIsSwitched(employerDetailsResponse.data?.isRegistered || false);
            }
          } catch (error) {
            //
          }
        };

        if (accessToken) {
          fetchDetails();
        }
    }, [accessToken]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const industriesResponse = await BillingAndIndustriesApi.GetIndustries({})
                setFetchIndustries(industriesResponse.data);
            } catch (error) {
                //
            }
        };

        fetchData();
    }, [])

    useEffect(() => {
        const fetchRatings = async () => {
            try {
                if (accessToken) {
                    const employerRatingsResponse = await RatingsApi.GetAllEmployerRatings(accessToken, userId);
                    setFetchEmployerRatings(employerRatingsResponse.data)
                }
            } catch (error) {
                //
            }
        };

        if (accessToken) {
            fetchRatings();
        }
        
    }, [accessToken])

    useEffect(() => {

        const fetcAbuseTypes = async () => {
            try {
                if (accessToken) {
                    const abuseTypesResponse = await RatingsApi.GetAbuseTypes(accessToken);
                    setFetchAbuseTypes(abuseTypesResponse.data)
                }
            } catch (error) {
                //
            }
        };

        if (accessToken) {
            fetcAbuseTypes();
        }
    }, [accessToken]);

    const { 
        handleSubmit: handleFormSubmit,
        formState,
        control,
        trigger,
        setValue } = useForm({
            mode: 'onChange',
            defaultValues: {
                employerId: userId,
                companyName: '',
                phoneNumber: '',
                countryId: '',
                cityName: '',
                isRegistered: false,
                pib: '',
                aboutMe: '',
                industryIds: [],
                notifyByEmail: true,
                profileImagePath: '',
            }
    });

    useEffect(() => {
        if (fetchEmployerDetails) {
          const { 
            companyName, 
            phoneNumber, 
            aboutMe, 
            industries, 
            profileImagePath,
            city,
            country
        } = fetchEmployerDetails;

            const selectedIndustryNames = industries.map((industry) => industry.industryName)
            const selectedIndustryIds = industries.map((industry) => industry.id)
            const countryName = country.countryName
            const cityName = city.city
    
            setIndustry(selectedIndustryNames)
            setValue('companyName', companyName);
            setValue('phoneNumber', phoneNumber);
            setValue('industryIds', selectedIndustryIds as never[])
            setValue('countryId', countryName)
            setValue('cityName', cityName)
            setValue('aboutMe', aboutMe);

            if (fetchEmployerDetails.profileImagePath) {
                setImageData(profileImagePath);
            }
        }
    }, [fetchEmployerDetails, setValue]);

    const onSubmit = async (formData: any) => {

        const {
            countryId, 
            cityName, 
            isRegistered, 
            pib, 
            ...editProfileData
        } = formData

        try {
            const response: any = await EmployerApi.EditEmployerProfile(editProfileData, accessToken);
            const { status } = response;
            if(status === 200) {
                const employerDetailsResponse = await EmployerApi.GetEmployerDetails(accessToken, userId);
                setImageData(employerDetailsResponse?.data.profileImagePath as string)
            }
        } catch (error) {
            //
        }
    }

    const handleButtonClick = async () => {
        try {
            await trigger();
            if (Object.keys(formState.errors).length === 0) {
                setLoading(true);
                await handleFormSubmit(onSubmit)();
                setLoading(false);
            }
        }
        catch (err) {
            //
        }
    };

    const { 
        handleSubmit: handleRatingsSubmit,
        formState: ratingsFormState,
        control: ratingsFormControl,
        reset: ratingsReset,
        trigger: triggerRatings } = useForm({
            mode: 'onChange',
            defaultValues: {
                comment: '',
                rating: 3,
            }
    });

    const onReplySubmit = async (ratingsData: any) => {
        const replyId = fetchEmployerRatings.find(replyDetails => replyDetails)?.rating.id || '';
        const dataObject = {
            ratingForEmployerId: replyId,
            employerId: userId,
            ...ratingsData
        }
        try {
            await RatingsApi.EmployerReplyToRating(dataObject, accessToken);
            ratingsReset({
                rating: 0,
                comment: '',
            })
        } catch (error) {
            //
        } 
    }

    const handleReplyToRatingButtonClick = async () => {
        
        try {
            await triggerRatings();
            if (Object.keys(ratingsFormState.errors).length === 0) {
                await handleRatingsSubmit(onReplySubmit)();
            }
        }
        catch (err) {
            //
        }
    }

    const { 
        handleSubmit: handleReportAbusementSubmit,
        formState: abusementFormState,
        control: abusementFormControl,
        reset: resetAbusement,
        trigger: triggerAbusement,
        setValue: setReportAbusementValue, 
        clearErrors: clearAbusementTypeErrors } = useForm({
            mode: 'onChange',
            defaultValues: {
                abuseTypeId: '',
                abuseAdditionalDescription: '',
            }
    });

    const onReportAbuseSubmit = async (reportAbuseData: any) => {
        const replyId = fetchEmployerRatings?.find(replyDetails => replyDetails)?.rating.id || '';
        const dataObject = {
            employerId: userId,
            ratingForEmployerId: replyId,
            ...reportAbuseData
        }
        try {
            const response: any = await RatingsApi.EmployerReportAbuse(dataObject, accessToken)
            const { status } = response;
            if (status === 400) {
                setReportAbusementValue('abuseAdditionalDescription', 'Već ste prijavili zloupotrebu za ovog korisnika')
            }
            resetAbusement();
        } catch (error) {
            //
        }
    }

    const handleReportAbusementButtonClick = async () => {
        
        try {
            await triggerRatings();
            if (Object.keys(ratingsFormState.errors).length === 0) {
                await handleReportAbusementSubmit(onReportAbuseSubmit)();
            }
        }
        catch (err) {
            //
        }
    }
    const handleComponentChange = (_event: any, newComponent: string) => {

        if (currentComponent === newComponent) {
            return;
        }

        setCurrentComponent(newComponent);
    };

    const handleSwitchChange = (event: { target: { checked: boolean }; }) => {
        const { checked } = event.target;
        
        setValue('isRegistered', checked);
        setIsSwitched(checked);
    };

    const handleNotifyMeSwitchChange = (event: { target: { checked: boolean }; }) => {
        const { checked } = event.target;

        setValue('notifyByEmail', checked);
        setIsNotifyMeSwitched(checked)
    }

    const handleOpenRateUserModal = () => {
        setIsModalOpen(true);
    }

    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    const handleAbusementModalOpen = () => {
        setIsAbusementModalOpen(true);
    }

    const handleAbusementModalClose = () => {
        setIsAbusementModalOpen(false);
    }

    return (
        <>
            <Grid container>
                <Grid item xs={0} sm={0} md={2}>
                    <div className={formClasses.bannersContainer}></div>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    {loading ? (
                        <Backdrop open={true}>
                            <div className={formClasses.circularProgressContainer}>
                                <StyledSpinningIcon />
                            </div>
                        </Backdrop>
                    ) : (
                        <>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FunctionalHeader />
                                </Grid>
                            </Grid>
                            <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                                <div className={formClasses.companyNameHeadline}>
                                    <ArrowBackIcon 
                                        className={formClasses.navigateBack}
                                        onClick={() => handleNavigate(navigate, `/employer-dashboard/`)} /> Moj profil
                                </div>
                            </div>
                            <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <ToggleButtonGroup
                                            className={formClasses.buttonsGroup}
                                            value={currentComponent}
                                            exclusive
                                            onChange={handleComponentChange}
                                            aria-label="component-switcher"
                                        >
                                            <ToggleButton
                                                className={formClasses.singleToggleButton}
                                                value="details" 
                                                aria-label="details-layout"
                                                disabled={currentComponent === 'details'}
                                                >
                                                Detalji
                                            </ToggleButton>
                                            <ToggleButton
                                                className={formClasses.singleToggleButton}
                                                value="ratings" 
                                                aria-label="ratings-layout"
                                                disabled={currentComponent === 'ratings'}
                                                >
                                                Ocene
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                            <Grid container className={`${isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer} ${formClasses.toggleLayoutsContainer}`}>
                                {notifyProfileSetup && (
                                    <Dialog
                                        maxWidth={'sm'}
                                        open={notifyProfileSetup}
                                        aria-describedby="profile-setup-dialog"
                                    >
                                        <DialogContent>
                                            <DialogContentText id="profile-setup-dialog">
                                                Poštovani, <br/>
                                                <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                                    <span>
                                                        Da biste povećali šanse za pronalaženje idealnih kandidata, <br />
                                                        predlažemo vam da ažurirate svoj profil. Evo nekoliko koraka: <br />
                                                    </span>
                                                </div>
                                                <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                                    <span>
                                                        <strong>Opis kompanije:</strong> Dodajte detaljniji opis vaše kompanije. <br />
                                                        Opišite svoju misiju, vrednosti i ono što je jedinstveno kod vas. <br />
                                                    </span>
                                                </div>
                                                <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                                    <span>
                                                        <strong>Industrije:</strong> Odaberite industrije kojima se bavite. <br />
                                                        To će pomoći kandidatima da pronađu vašu kompaniju lakše. <br />
                                                    </span>
                                                </div>
                                                <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                                    <span>
                                                        <strong>Profilna slika:</strong> Dodajte prepoznatljivu profilnu sliku.<br />
                                                        Prvi utisak je važan! <br />
                                                    </span>
                                                </div>
                                                <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                                    <span>
                                                        Vaš <img height={12} alt="Sezonal" src={`${logoBlue}`}/>
                                                    </span>
                                                </div>
                                            </DialogContentText>
                                            <DialogActions>
                                            <Button 
                                                onClick={() => handleCloseProfileSetupModal()}  autoFocus>
                                                Razumem
                                            </Button>
                                            </DialogActions>
                                        </DialogContent>
                                    </Dialog>
                                )}
                                {(currentComponent === 'details') ? (
                                    <>
                                        <Grid item xs={12} sm={12} md={6} order={{xs: 2, sm: 1, md: 1}} className={formClasses.toggleButtonsContainer}>
                                            <form onSubmit={handleButtonClick}>
                                                <div className={formClasses.inputContainer}>
                                                    <Controller
                                                        name="companyName"
                                                        control={control}
                                                        rules={{ 
                                                            required: 'Polje je obavezno',
                                                        }}
                                                        render={({field, fieldState}) => {
                                                            return (
                                                                <StyledInput
                                                                    {...field}
                                                                    variant="outlined"
                                                                    required={true}
                                                                    type="text"
                                                                    label="Naziv kompanije / Gazdinstva"
                                                                    error={!!fieldState.error}
                                                                    helperText={fieldState.error?.message}
                                                                    inputRef={field.ref}
                                                                />
                                                            )
                                                        }} 
                                                    />
                                                </div>
                                                <div className={formClasses.inputContainer}>
                                                    <Controller
                                                        name="phoneNumber"
                                                        control={control}
                                                        rules={{ 
                                                            required: 'Polje je obavezno',
                                                            pattern: {
                                                                value: /^[0-9]+$/,
                                                                message: 'Unesite broj',
                                                            },
                                                            minLength: {
                                                                value: 9,
                                                                message: 'Broj telefona mora imati najmanje 9 cifara',
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message: 'Broj telefona ne sme imati više od 10 cifara',
                                                            }
                                                        }}
                                                        render={({field, fieldState}) => {
                                                            return (
                                                                <StyledInput
                                                                    variant="outlined"
                                                                    required={false}
                                                                    type="tel"
                                                                    label="Broj telefona"
                                                                    error={!!fieldState.error}
                                                                    helperText={fieldState.error?.message}
                                                                    inputRef={field.ref}
                                                                    {...field}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className={formClasses.inputContainer}>
                                                    <Divider />
                                                </div>
                                                <div className={formClasses.inputContainer}> 
                                                    <Controller
                                                        name="countryId"
                                                        control={control}
                                                        render={({field, fieldState}) => {
                                                            return (
                                                                <StyledInput
                                                                    {...field}
                                                                    disabled={true}
                                                                    variant="outlined"
                                                                    required={false}
                                                                    type="text"
                                                                    InputProps={{
                                                                        // startAdornment: (
                                                                        //     <img width="24" height="16" src={`${SOCIAL_MEDIA_ICONS.serbiaFlagIcon}`}/>
                                                                        // ),
                                                                    }}
                                                                    label="Država"
                                                                    error={!!fieldState.error}
                                                                    helperText={fieldState.error?.message}
                                                                    inputRef={field.ref}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className={formClasses.inputContainer}>
                                                    <Controller
                                                        name="cityName"
                                                        control={control}
                                                        render={({field, fieldState}) => {
                                                            return (
                                                                <StyledInput
                                                                    {...field}
                                                                    disabled={true}
                                                                    variant="outlined"
                                                                    required={false}
                                                                    type="text"
                                                                    label="Grad"
                                                                    error={!!fieldState.error}
                                                                    helperText={fieldState.error?.message}
                                                                    inputRef={field.ref}
                                                                /> 
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className={formClasses.inputContainer}>
                                                    <Divider />
                                                </div>
                                                <div className={formClasses.inputContainer}>
                                                    <Controller
                                                        name="isRegistered"
                                                        control={control}
                                                        render={({field, fieldState}) => {
                                                            return (
                                                                <FormControlLabel 
                                                                    control={
                                                                        <Switch 
                                                                            {...field}
                                                                            disabled={true}
                                                                            inputRef={field.ref}
                                                                            size="medium"
                                                                            checked={isSwitched}
                                                                            onChange={handleSwitchChange}
                                                                            
                                                                        />} 
                                                                    label="Registrovan/na sam" 
                                                                />
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                {isSwitched && (
                                                    <div className={formClasses.inputContainer}>
                                                        <Controller
                                                            name="pib"
                                                            control={control}
                                                            rules={{ 
                                                                pattern: {
                                                                    value: /^[a-zA-Z0-9]*$/,
                                                                    message: 'Može sadržati samo slova i brojeve',
                                                                },
                                                            }}
                                                            render={({field, fieldState}) => {
                                                                return (
                                                                    <StyledInput
                                                                        disabled={true}
                                                                        variant="outlined"
                                                                        required={false}
                                                                        type="text"
                                                                        label="PIB / OIB / JIB / VATIN"
                                                                        error={!!fieldState.error}
                                                                        helperText={fieldState.error?.message}
                                                                        inputRef={field.ref}
                                                                        {...field}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                <div className={formClasses.inputContainer}>
                                                    <Divider />
                                                </div>
                                                <div className={formClasses.inputContainer}>
                                                    <Controller
                                                        name="industryIds"
                                                        control={control}
                                                        render={({field, fieldState}) => {
                                                            const handleChange = (event: SelectChangeEvent<typeof industry>) => {
                                                                const {target: { value } } = event;
                                                                const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                                                
                                                                field.onChange(selectedValues);
                                                                setIndustry(selectedValues);

                                                                const selectedIndustryIds = fetchIndustries
                                                                .filter(industryObj => selectedValues.includes(industryObj.industryName))
                                                                .map(industryObj => industryObj.id);

                                                                setValue('industryIds', selectedIndustryIds as unknown as never[]);
                                                            };
                                                            return (
                                                                <div className={formClasses.customFormErrorsContainer}>
                                                                    {fieldState.error && (
                                                                            <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                                                {fieldState.error.message}
                                                                            </Typography>
                                                                    )}
                                                                    <FormControl className={formClasses.selectContainer}>
                                                                        <InputLabel 
                                                                            id="field-of-industry" 
                                                                            className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                                                Industrija
                                                                        </InputLabel>
                                                                        <StyledSelect
                                                                            {...field}
                                                                            required
                                                                            labelId="field-of-industry"
                                                                            multiple
                                                                            value={industry}
                                                                            onChange={handleChange}
                                                                            input={
                                                                                <OutlinedInput 
                                                                                    label="Industrija" 
                                                                                    className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                                                />
                                                                            }
                                                                            error={!!fieldState.error}
                                                                            inputRef={field.ref}
                                                                            renderValue={(selected) => {
                                                                                return (
                                                                                    <Box className={formClasses.chipContainer}>
                                                                                        {selected.map((value: any) => (
                                                                                            <Chip key={value} label={value} />
                                                                                        ))}
                                                                                    </Box>
                                                                                )  
                                                                            }}
                                                                        >
                                                                            {fetchIndustries.map((industryObj) => (
                                                                                <MenuItem
                                                                                    key={industryObj.id}
                                                                                    value={industryObj.industryName}
                                                                                    className={formClasses.menuItemContainer}
                                                                                >
                                                                                    {industryObj.industryName}
                                                                                    {industry.includes(industryObj.industryName) ? <CheckCircleIcon className={formClasses.checkIcon}/> : undefined}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </StyledSelect>
                                                                    </FormControl>
                                                            </div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className={formClasses.inputContainer}>
                                                    <Controller
                                                        name="aboutMe"
                                                        control={control}
                                                        render={({field, fieldState}) => {
                                                            return (
                                                                <StyledInput
                                                                    variant="outlined"
                                                                    multiline={true}
                                                                    rows={4}
                                                                    required={false}
                                                                    type="text"
                                                                    label="Opis kompanije / poljoprivrednog gazdinstva" 
                                                                    error={!!fieldState.error}
                                                                    helperText={fieldState.error?.message}
                                                                    inputRef={field.ref}
                                                                    {...field}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className={formClasses.inputContainer}>
                                                    <Divider />
                                                </div>
                                                <div className={formClasses.inputContainer}>
                                                    <Controller
                                                        name="notifyByEmail"
                                                        control={control}
                                                        render={({field, fieldState}) => {
                                                            return (
                                                                <FormControlLabel 
                                                                    control={
                                                                        <Switch 
                                                                            {...field}
                                                                            inputRef={field.ref}
                                                                            size="medium"
                                                                            checked={isNotifyMeSwitched}
                                                                            onChange={handleNotifyMeSwitchChange}
                                                                            
                                                                        />} 
                                                                    label="Želim da primam obaveštenja putem email-a" 
                                                                />
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className={formClasses.inputContainer}>
                                                    <StyledButton
                                                        style={{ textTransform: 'none'}}
                                                        className={formClasses.w100pi}
                                                        variant="contained"
                                                        onClick={handleButtonClick}
                                                        >
                                                            {loading ? <CircularProgress 
                                                                size={24}
                                                                className="circular-progress"
                                                                />
                                                                : ' Sačuvaj promene'}
                                                    </StyledButton>
                                                </div>
                                                <div>
                                                    <StyledButton
                                                        style={{ textTransform: 'none'}}
                                                        className={formClasses.w100pi}
                                                        variant="text"
                                                        onClick={() => navigate(-1)}
                                                        >
                                                            Nazad
                                                    </StyledButton>
                                                </div>
                                            </form>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} order={{xs: 1, sm: 2, md: 2}} className={formClasses.profileImageInitialsContainer}>
                                            <div className={formClasses.imageInitialsUploaderContainer}>
                                                {fetchEmployerDetails?.profileImagePath ? (
                                                    <div className={`${formClasses.avatarRatingsContainer} ${formClasses.mb12}`}>
                                                        <div className={`${formClasses.avatarsHolderContainerLarge}`}>
                                                            <StyledProfilePicture 
                                                                width={180}
                                                                height={180}
                                                                alt={`profile-image-${initials}`}
                                                                imageData={imageData as string}
                                                            />
                                                        </div>
                                                        <div className={formClasses.UserDetailsRatingsLarge}>
                                                            <StarRoundedIcon className={`${formClasses.star}`}/>
                                                            <div className={formClasses.gradeOverlay}>{fetchEmployerDetails?.averageRating}</div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className={`${formClasses.avatarRatingsContainer} ${formClasses.mb12}`}>
                                                        <div className={`${formClasses.avatarContainerLarge} ${formClasses.avatarsHolderContainerLarge}`}>
                                                            <div className={formClasses.profileInitialsLarge}>
                                                                <span className={formClasses.initialsHolderLarge}>
                                                                    {initials}
                                                                </span>
                                                            </div>
                                                            <div className={formClasses.UserDetailsRatingsLarge}>
                                                                <StarRoundedIcon className={formClasses.star}/>
                                                                <div className={formClasses.grade}>{fetchEmployerDetails?.averageRating}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <Controller 
                                                    name="profileImagePath"
                                                    control={control}
                                                    render={({field, fieldState}) => {
                                                        return (
                                                            <ImageUploaderThumbnail onChange={(file: File) => field.onChange(file)} />
                                                        )
                                                    }}
                                                />
                                                  <div>
                                               
                                            </div>
                                            </div>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <UserRatingsWithReplies 
                                            onRateUser={handleOpenRateUserModal}
                                            onReportAbuse={handleAbusementModalOpen}
                                            ratings={fetchEmployerRatings}/>
                                        <StyledApplyingModal
                                            open={isModalOpen}
                                            onClose={handleModalCancel}
                                            onConfirm={handleReplyToRatingButtonClick}
                                            titleText="Ocena kandidata"
                                            confirmButtonText="Oceni kandidata"
                                        >
                                            
                                            {fetchEmployerRatings.map((ratingDetails: EmployerRatingsResponse, index: number) => (
                                                <div key={index} className={classes.ratingModalDetailsContainer}>
                                                    <div className={classes.ratingDetailsUpper}>
                                                        <div className={classes.ratingDetails}>
                                                            <div className={formClasses.avatarsHolderContainerExtraSmall}>
                                                                <span className={formClasses.initialsHolderExtraSmall}>{ratingDetails?.employeeFirstName[0]}</span> 
                                                            </div>
                                                            <span className={`${classes.name} ${formClasses.pr6} ${formClasses.pl6}`}>{ratingDetails?.employeeFirstName} {ratingDetails?.employeeLastName}</span>
                                                            <FiberManualRecordIcon className={formClasses.dotSeparator}/>
                                                            <StarRoundedIcon className={formClasses.star}/>
                                                            <span className={formClasses.grade}>{ratingDetails?.rating.rating}</span>
                                                        </div>
                                                    </div>
                                                    <div className={formClasses.inputContainerTop}>
                                                        <span className={classes.ratingDetailsText}>{ratingDetails?.rating.comment}</span>
                                                    </div>
                                                </div>
                                            ))}
                                            <form onSubmit={handleReplyToRatingButtonClick}>
                                                <div className={formClasses.inputContainer}>
                                                    <Controller 
                                                        name="rating"
                                                        control={ratingsFormControl}
                                                        render={({field, fieldState}) => {
                                                            return (
                                                                <Box sx={{
                                                                    '& > legend': { mt: 2 },
                                                                    }}>
                                                                        <Rating
                                                                            {...field}
                                                                            value={ratingValue}
                                                                            size="large"
                                                                            onChange={(event, newValue) => {
                                                                                field.onChange(newValue);
                                                                                setRatingValue(newValue)
                                                                            }}
                                                                        
                                                                        />
                                                                </Box>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className={formClasses.inputContainer}>
                                                    <Controller
                                                        name="comment"
                                                        control={ratingsFormControl}
                                                        rules={{
                                                            required: 'Polje je obavezno',
                                                            maxLength: 200
                                                        }}
                                                        render={({field, fieldState}) => {
                                                            return (
                                                                <StyledInput
                                                                    variant="outlined"
                                                                    multiline={true}
                                                                    rows={4}
                                                                    required={false}
                                                                    type="text"
                                                                    label="Komentar" 
                                                                    error={!!fieldState.error}
                                                                    helperText={fieldState.error?.message}
                                                                    inputRef={field.ref}
                                                                    {...field}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </form>
                                        </StyledApplyingModal>
                                        <StyledApplyingModal
                                            open={isAbusementModalOpen}
                                            onClose={handleAbusementModalClose}
                                            onConfirm={handleReportAbusementButtonClick}
                                            titleText="Prijava zloupotrebe"
                                            confirmButtonText="Prijavi zloupotrebu"
                                        >
                                            <form onSubmit={handleReportAbusementButtonClick}>
                                                <span>Izaberite jedan od ponudjenih tipova zloupotrebe:</span>
                                                <div className={formClasses.inputContainer}>
                                                    {fetchAbuseTypes?.map((abusementDetails, index) => (
                                                        <Controller
                                                            name="abuseTypeId"
                                                            control={abusementFormControl}
                                                            rules={{
                                                                required: 'Morate izabrati tip zloupotrebe'
                                                            }}
                                                            render={({field, fieldState}) => {
                                                                const currentCard = selectedAbusementTypeId === abusementDetails?.id ? true : false;
                                                                const handleAbusementTypeClick = (abusementTypeId: string) => {
                                                                    clearAbusementTypeErrors('abuseTypeId');
                                                                    setReportAbusementValue('abuseTypeId', abusementTypeId);
                                                                    setSelectedAbusementTypeId(abusementTypeId);
                                                                }
                                                                return (
                                                                    <div
                                                                        {...field}
                                                                        key={abusementDetails?.id} 
                                                                        className={`${classes.reportAbusementContainer}`}
                                                                        onClick={() => handleAbusementTypeClick(abusementDetails?.id)}
                                                                    >
                                                                    
                                                                        <div className={`${fieldState.error ? classes.abusementTypeError : ' '} ${currentCard ? classes.abusementTypeSelected : classes.abusementType}`}>
                                                                            {fieldState.error ? (
                                                                                <div className={classes.abusementTypeHeadline}>
                                                                                    {fieldState.error.message}
                                                                                </div>
                                                                            ) : (
                                                                                <div className={currentCard ? classes.abusementTypeHeadlineSelected : classes.abusementTypeHeadline}>{abusementDetails?.abuseTitle}</div>
                                                                            )}
                                                                            
                                                                            <div className={classes.abusementTypeExplanation}>{abusementDetails?.abuseDescription}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }}
                                                        
                                                        />

                                                    ))}
                                                </div>
                                                <div className={formClasses.inputContainer}>
                                                    <Controller
                                                        name="abuseAdditionalDescription"
                                                        control={abusementFormControl}
                                                        render={({field, fieldState}) => {
                                                            return (
                                                                <StyledInput
                                                                    variant="outlined"
                                                                    multiline={true}
                                                                    rows={4}
                                                                    required={false}
                                                                    type="text"
                                                                    label="Dodatno objašnjenje (opciono)"
                                                                    error={!!fieldState.error}
                                                                    helperText={fieldState.error?.message}
                                                                    inputRef={field.ref}
                                                                    {...field}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </form>
                                        </StyledApplyingModal>
                                        
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                    
                </Grid>
                <Grid item xs={0} sm={0} md={2}>
                    <div className={formClasses.bannersContainer}></div>
                </Grid>
            </Grid>
        </>
    )
    
}

export default EmployerProfile