import FunctionalHeader from "../../functionalHeader/functionalHeader";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid/Grid"
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BODY_ITEMS from '../../../assets/bodyItems/'
import { StyledInput } from "../../styledComponents/styledInput/styledInput";
import useStyles from "./newJob.module";
import { Controller, useForm } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box/Box";
import Chip from "@mui/material/Chip/Chip";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import StyledButton from "../../styledComponents/styledButton.module";
import Checkbox from '@mui/material/Checkbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import validateValue, { useMediaQueries, handleNavigate } from "../../../utils/global-utils";
import { StyledSelect } from "../../styledComponents/styledSelect/styledSelect";
import BillingAndIndustriesApi from "../../../services/billingAndIndustries";
import DatePicker from "react-datepicker";
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import logoBlue from '../../../assets/logo_blue.svg'
import Button from '@mui/material/Button';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styledComponents/styledDateRangePicker.css'
import UserContext from "../../../context/UserContext";
import AddApi from "../../../services/addApi";
import {
    Industry,
    Currency,
    BillingType,
    Place,
    PaymentRequest,
    Country
} from '../../../utils/types.d'
import Typography from "@mui/material/Typography/Typography";
import PricingDialog from "../pricingDialog/pricingDialog";
import Backdrop from "@mui/material/Backdrop/Backdrop";

function NewJob() {

    const navigate = useNavigate();
    const formClasses = useFormStyles();
    const classes = useStyles();
    const { isMobile, } = useMediaQueries();
    const today = new Date();
    const { userData } = useContext(UserContext);
    const employerId: string | undefined = userData?.nameid;
    const [industry, setIndustry] = useState<string[]>([]);
    const [fetchIndustries, setFetchIndustries] = useState<Industry[]>([]);
    const [fetchPaymentType, setFetchPaymentType] = useState<BillingType[]>([])
    const [paymentType, setpaymentType] = useState<string[]>([]);
    const [fetchCurrencies, setFetchCurrencies] = useState<Currency[]>([])
    const [coinType, setCoinType] = useState<string[]>([]);
    const [fetchPlaces, setFetchPlaces] = useState<Place[]>([])
    const [fetchCountries, setFetchCountrues] = useState<Country[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<string[]>([])
    const [selectedCountryById, setSelectedCountryById] = useState<string>('')
    const [places, setPlaces] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<Date | null>();
    const [endDate, setEndDate] = useState<Date | null>();
    const [openPricingModal, setOpenPricingModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [anyPaymentFieldFilled, setAnyPaymentFieldFilled] = useState(false);
    const [, setBillingType] = useState(null);
    const [, setCurrency] = useState(null);
    const { accessToken } = useContext(UserContext);
    const [, setFetchPaymentRequest] = useState<PaymentRequest[]>([]);
    const [, setSelectedAdType] = useState<string | undefined>();
    const [adId, setAdId] = useState<string | null>(null);
    const [errorModal, setErrorModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const {
        handleSubmit,
        control,
        trigger,
        setValue,
        watch,
        reset } = useForm({
            mode: 'onChange',
            defaultValues: {
                employerId: employerId,
                industryIds: [],
                adTitle: '',
                adDescription: '',
                countryId: '',
                cityId: '',
                workingStartDate: '',
                workingEndDate: '',
                workingHours: '',
                billingTypeId: '',
                currencyId: '',
                salaryValue: '',
                offeredAccomodation: false,
                offeredFood: false,
                offeredTransport: false
            }
        });

    useEffect(() => {
        const fetchData = async () => {

            try {
            const industriesResponse = await BillingAndIndustriesApi.GetIndustries({});
            setFetchIndustries(industriesResponse.data);
            } catch (error) {
            //
            }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const billingTypeResponse = await BillingAndIndustriesApi.getBiillingTypes({});
            setFetchPaymentType(billingTypeResponse.data);
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

            try {
            const currenciesResponse = await BillingAndIndustriesApi.GetCurrencies({});
            setFetchCurrencies(currenciesResponse.data);
            } catch (error) {
            //
            }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const countriesResponse = await BillingAndIndustriesApi.getAllCountries({});
            setFetchCountrues(countriesResponse.data);
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const response: any = await BillingAndIndustriesApi.getAllPlaces(selectedCountryById);
            const { status } = response;
            if (status === 200) {
                setFetchPlaces(response.data);
            }
            
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, [selectedCountryById]);

    useEffect(() => {
        setPlaces([]);
    }, [selectedCountryById]);

    const onSubmit = async (formData: any) => {
        try {

            if (formData.workingEndDate === "") {
                delete formData.workingEndDate
            }
            if (formData.salaryValue === "") {
                delete formData.salaryValue
            }
            if (formData.billingTypeId === "") {
                delete formData.billingTypeId
            }
            if (formData.currencyId === "") {
                delete formData.currencyId
            }

            const response: any = await AddApi.AddNewPost(formData, accessToken);
            const { status, data } = response;
            if (status === 200) {
                const adId = data.adId;
                setAdId(adId);
            }

            reset({
                employerId: '',
                industryIds: [],
                adTitle: '',
                adDescription: '',
                countryId: '',
                cityId: '',
                workingStartDate: '',
                workingEndDate: '',
                workingHours: '',
                billingTypeId: '',
                currencyId: '',
                salaryValue: '',
                offeredAccomodation: false,
                offeredFood: false,
                offeredTransport: false
            })
            setOpenPricingModal(true);
        } catch (error) {
            //
        }
    }

    const handleButtonClick = async () => {
        try {
            const isValid = await trigger();
            if (isValid) {
                setLoading(true);
                await handleSubmit(onSubmit)();
                setLoading(false);
            }
        }
        catch (err) {
           //
        }
    };

    const handleAdTypeSubmit = async (type: string | undefined) => {

        try {
            setSelectedAdType(type);
            setLoading(true);
            const adDataSecondStep = {
                adId: adId,
                adTypeId: type
            }
            const response: any = await AddApi.PostAdSecondStep(adDataSecondStep, accessToken);
            const { status, data } = response;

            if (data) {
                setFetchPaymentRequest(data)
            }

            if (status === 200) {
                setSuccessModal(true);
            }
            setLoading(false);
        } catch (error) {
            setErrorModal(true)
        }

        setOpenPricingModal(false);
    }

    const handleClosePricingModal = () => {
        setOpenPricingModal(false);
        handleNavigate(navigate, `/employer-dashboard/`)
    };

    const handlePricingModalConfirm = (type: string | undefined) => {
        setSelectedAdType(type);
        handleAdTypeSubmit(type);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={0} sm={0} md={2}>
                    <div className={formClasses.bannersContainer}></div>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <FunctionalHeader />
                        </Grid>
                    </Grid>
                    <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                        <div className={formClasses.companyNameHeadline}>
                            <ArrowBackIcon
                                className={formClasses.navigateBack}
                                onClick={() => handleNavigate(navigate, `/employer-dashboard/`)} /> Postavi novi oglas
                        </div>
                    </div>
                    <Grid container className={isMobile ? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                        <Grid item xs={12} sm={12} md={6}>
                            <form onSubmit={handleButtonClick} className={isMobile ? formClasses.mb24 : ''}>
                                <div className={formClasses.inputContainer}>
                                    <Controller
                                        name="adTitle"
                                        control={control}
                                        rules={{
                                            required: 'Polje je obavezno',
                                        }}
                                        render={({ field, fieldState }) => {
                                            return (
                                                <StyledInput
                                                    variant="outlined"
                                                    required={true}
                                                    type="text"
                                                    label="Naziv oglasa"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                    inputRef={field.ref}
                                                    {...field}
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Controller
                                        name="adDescription"
                                        control={control}
                                        rules={{
                                            required: 'Polje je obavezno',
                                            maxLength: 200
                                        }}
                                        render={({ field, fieldState }) => {
                                            const CHARACTER_LIMIT = 200
                                            return (
                                                <StyledInput
                                                    variant="outlined"
                                                    multiline={true}
                                                    rows={4}
                                                    required={true}
                                                    type="text"
                                                    label="Opis oglasa"
                                                    error={!!fieldState.error}
                                                    InputProps={{
                                                        maxLength: CHARACTER_LIMIT
                                                    }}
                                                    helperText={`${field.value.length} / ${CHARACTER_LIMIT}`}
                                                    inputRef={field.ref}
                                                    {...field}
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Controller
                                        name="industryIds"
                                        control={control}
                                        rules={{
                                            required: 'Polje je obavezno'
                                        }}
                                        render={({ field, fieldState }) => {
                                            const handleChange = (event: SelectChangeEvent<typeof industry>) => {
                                                const { target: { value } } = event;
                                                const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);

                                                field.onChange(selectedValues);
                                                setIndustry(selectedValues);

                                                const selectedIndustryIds = fetchIndustries
                                                    .filter(industryObj => selectedValues.includes(industryObj.industryName))
                                                    .map(industryObj => industryObj.id);

                                                setValue('industryIds', selectedIndustryIds as unknown as never[]);
                                            };

                                            return (
                                                <div className={formClasses.customFormErrorsContainer}>
                                                    {fieldState.error && (
                                                        <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                            {fieldState.error.message}
                                                        </Typography>
                                                    )}
                                                    <FormControl className={formClasses.selectContainer}>
                                                        <InputLabel
                                                            id="field-of-industry"
                                                            className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                            Industrija
                                                        </InputLabel>
                                                        <StyledSelect
                                                            {...field}
                                                            required={true}
                                                            labelId="field-of-industry"
                                                            multiple
                                                            value={industry}
                                                            onChange={handleChange}
                                                            input={
                                                                <OutlinedInput
                                                                    label="Industrija / e"
                                                                    className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                                />
                                                            }
                                                            error={!!fieldState.error}
                                                            inputRef={field.ref}
                                                            renderValue={(selected) => {
                                                                return (
                                                                    <Box className={formClasses.chipContainer}>
                                                                        {selected.map((value: any) => (
                                                                            <Chip key={value} label={value} />
                                                                        ))}
                                                                    </Box>
                                                                )
                                                            }}
                                                        >
                                                            {fetchIndustries.map((industryObj) => (
                                                                <MenuItem
                                                                    key={industryObj.id}
                                                                    value={industryObj.industryName}
                                                                    className={isMobile ? formClasses.menuItemContainerMobile : ''}
                                                                >
                                                                    {industryObj.industryName}
                                                                    {industry.includes(industryObj.industryName) ? <CheckCircleIcon className={formClasses.checkIcon} /> : undefined}
                                                                </MenuItem>
                                                            ))}
                                                        </StyledSelect>
                                                    </FormControl>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Divider />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Controller
                                        name="countryId"
                                        control={control}
                                        rules={{
                                            required: 'Polje je obavezno'
                                        }}
                                        render={({ field, fieldState }) => {
                                            const handleCountryChange = (event: SelectChangeEvent<typeof selectedCountry>) => {
                                                const { target: { value } } = event;
                                                const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                                field.onChange(selectedValues);
                                                setSelectedCountry(selectedValues)
        
                                                const selectedCountryId: string | undefined = fetchCountries.find(
                                                    (place) => place.countryName === value
                                                )?.id
        
                                                setSelectedCountryById(selectedCountryId as string)
                                                setValue('countryId', selectedCountryId || '');
                                            }
                                            return (
                                                <div className={formClasses.customFormErrorsContainer}>
                                                    {fieldState.error && (
                                                        <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                            {fieldState.error.message}
                                                        </Typography>
                                                    )}
                                                    <FormControl className={formClasses.selectContainer}>
                                                        <InputLabel 
                                                            id="select-country" 
                                                            className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                                Država
                                                        </InputLabel>
                                                        <StyledSelect
                                                            required={true}
                                                            labelId="select-country"
                                                            value={selectedCountry}
                                                            onChange={handleCountryChange}
                                                            input={
                                                                <OutlinedInput 
                                                                    label="Država" 
                                                                    className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                                />
                                                            }
                                                            error={!!fieldState.error}
                                                            inputRef={field.ref}

                                                        >
                                                            {fetchCountries.slice().sort((a, b) => a.countryName.localeCompare(b.countryName))
                                                                .map((region, index) => (
                                                                    <MenuItem
                                                                        className={formClasses.menuItemContainer}
                                                                        key={index} 
                                                                        value={region.countryName}
                                                                    >
                                                                        {region.countryName}
                                                                    </MenuItem>
                                                            ))}
                                                        </StyledSelect>
                                                    </FormControl>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Controller
                                        name="cityId"
                                        control={control}
                                        rules={{
                                            required: 'Polje je obavezno'
                                        }}
                                        render={({ field, fieldState }) => {
                                            const handleCityChange = (event: SelectChangeEvent<typeof places>) => {
                                                const { target: { value } } = event;
                                                const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                                field.onChange(selectedValues);
                                                setPlaces(selectedValues)

                                                const selectedCityId = fetchPlaces.find(
                                                    (place) => place.city === value
                                                )?.id

                                                setValue('cityId', selectedCityId || '');
                                            }
                                            return (
                                                <div className={formClasses.customFormErrorsContainer}>
                                                    {fieldState.error && (
                                                        <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                            {fieldState.error.message}
                                                        </Typography>
                                                    )}
                                                    <FormControl className={formClasses.selectContainer}>
                                                        <InputLabel
                                                            id="select-city"
                                                            className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                            Grad
                                                        </InputLabel>
                                                        <StyledSelect
                                                            {...field}
                                                            required={true}
                                                            labelId="select-city"
                                                            value={places}
                                                            onChange={handleCityChange}
                                                            input={
                                                                <OutlinedInput
                                                                    label="Grad"
                                                                    className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                                />
                                                            }
                                                            error={!!fieldState.error}
                                                            inputRef={field.ref}

                                                        >
                                                            {fetchPlaces.slice().sort((a, b) => a.city.localeCompare(b.city))
                                                                .map((region, index) => (
                                                                    <MenuItem
                                                                        className={isMobile ? formClasses.menuItemContainerMobile : ''}
                                                                        key={index}
                                                                        value={region.city}
                                                                    >
                                                                        {region.city}
                                                                    </MenuItem>
                                                                ))}
                                                        </StyledSelect>
                                                    </FormControl>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Divider />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Grid container className={classes.datePickerContainer}>
                                        <Grid item xs={12} sm={12} md={6} className={isMobile ? `${formClasses.pSides0} ${formClasses.pb28}` : formClasses.pr6}>
                                            <div className="date-range-container">
                                                <div className="date-range-input-container">
                                                    <Controller
                                                        name="workingStartDate"
                                                        control={control}
                                                        rules={{
                                                            required: 'Polje je obavezno'
                                                        }}
                                                        render={({ field, fieldState }) => {
                                                            const handleStartDateChange = (date: Date) => {
                                                                const selectedDate = date ? new Date(date) : null;
                                                                field.onChange(selectedDate)
                                                                setStartDate(selectedDate);
                                                            };
                                                            return (
                                                                <div className={formClasses.customFormErrorsContainer}>
                                                                    {fieldState.error && (
                                                                        <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                                            {fieldState.error.message}
                                                                        </Typography>
                                                                    )}
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={startDate}
                                                                        onChange={handleStartDateChange}
                                                                        startDate={startDate}
                                                                        endDate={endDate}
                                                                        minDate={today}
                                                                        placeholderText="MM/DD/YYYY"
                                                                        className={fieldState.error ? `${'date-range-inputs-error'} ${'date-range-inputs-error::placeholder'}` : 'date-range-inputs'}
                                                                    />
                                                                </div>
                                                            )
                                                        }}
                                                    />

                                                </div>
                                                <span className="MuiTypography-root MuiTypography-body1 css-14am5op">–</span>
                                                <div className="date-range-input-container">
                                                    <Controller
                                                        control={control}
                                                        name="workingEndDate"
                                                        render={({ field, fieldState }) => {
                                                            const handleEndDateChange = (date: Date) => {
                                                                const selectedDate = date ? new Date(date) : null;
                                                                field.onChange(selectedDate)
                                                                setEndDate(selectedDate);
                                                            };
                                                            return (
                                                                <div className={formClasses.customFormErrorsContainer}>
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={endDate}
                                                                        onChange={handleEndDateChange}
                                                                        startDate={startDate}
                                                                        endDate={endDate}
                                                                        minDate={startDate}
                                                                        placeholderText="MM/DD/YYYY"
                                                                        className={fieldState.error ? `${'date-range-inputs-error'} ${'date-range-inputs-error::placeholder'}` : 'date-range-inputs'}
                                                                    />
                                                                </div>
                                                            )
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} className={isMobile ? formClasses.pSides0 : formClasses.pl6}>
                                            <Controller
                                                name="workingHours"
                                                control={control}
                                                rules={{
                                                    required: 'Polje je obavezno',
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message: 'Unesite broj',
                                                    },
                                                    validate: validateValue
                                                }}
                                                render={({ field, fieldState }) => {
                                                    return (
                                                        <StyledInput
                                                            {...field}
                                                            variant="outlined"
                                                            required={true}
                                                            type="text"
                                                            label="Radno vreme (sati)"
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            inputRef={field.ref}
                                                        />
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={4} className={isMobile ? `${formClasses.pSides0} ${formClasses.pb28}` : formClasses.pr6}>
                                            <Controller
                                                name="salaryValue"
                                                control={control}
                                                rules={{
                                                    required: anyPaymentFieldFilled,
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message: 'Unesite broj',
                                                    },
                                                }}
                                                render={({ field, fieldState }) => {
                                                    const handleSalaryChange = (e: { target: { value: any; }; }) => {
                                                        const value = e.target.value;
                                                        field.onChange(value);
                                                        setAnyPaymentFieldFilled(!!value || !!watch("billingTypeId") || !!watch("currencyId"));

                                                        if (!value) {
                                                            setBillingType(null)
                                                            setCurrency(null)
                                                        }
                                                    };
                                                    return (
                                                        <StyledInput
                                                            {...field}
                                                            variant="outlined"
                                                            required={false}
                                                            type="text"
                                                            label="Iznos"
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            inputRef={field.ref}
                                                            onChange={handleSalaryChange}
                                                        />
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} className={isMobile ? `${formClasses.pSides0} ${formClasses.pb28}` : ''}>
                                            <Controller
                                                name="billingTypeId"
                                                control={control}
                                                rules={{
                                                    required: anyPaymentFieldFilled,
                                                }}
                                                render={({ field, fieldState }) => {
                                                    const handlePaymentTypeChange = (event: SelectChangeEvent<typeof paymentType>) => {
                                                        const { target: { value } } = event;
                                                        const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                                        field.onChange(value);
                                                        setpaymentType(selectedValues);

                                                        const selectedBillingTypeId = fetchPaymentType.find(
                                                            (type) => type.typeOfBilling === value
                                                        )?.id
                                                        setValue('billingTypeId', selectedBillingTypeId || '');

                                                        setAnyPaymentFieldFilled(!!value);
                                                    };
                                                    return (
                                                        <div className={formClasses.customFormErrorsContainer}>
                                                            {fieldState.error && (
                                                                <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                                    {fieldState.error.message}
                                                                </Typography>
                                                            )}
                                                            <FormControl className={formClasses.selectContainer}>
                                                                <InputLabel
                                                                    id="payment-type"
                                                                    className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                                    Tip naplate
                                                                </InputLabel>
                                                                <StyledSelect
                                                                    {...field}
                                                                    required={true}
                                                                    disabled={!anyPaymentFieldFilled}
                                                                    labelId="payment-type"
                                                                    value={paymentType}
                                                                    onChange={handlePaymentTypeChange}
                                                                    input={
                                                                        <OutlinedInput
                                                                            label="Tip naplate"
                                                                            className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                                        />
                                                                    }
                                                                    error={!!fieldState.error}
                                                                >
                                                                    {fetchPaymentType.map((type, index) => (
                                                                        <MenuItem
                                                                            key={index}
                                                                            value={type.typeOfBilling}
                                                                        >
                                                                            {type.typeOfBilling}
                                                                        </MenuItem>
                                                                    ))}
                                                                </StyledSelect>
                                                            </FormControl>
                                                        </div>
                                                    )
                                                }}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} className={isMobile ? `${formClasses.pSides0} ${formClasses.pb28}` : formClasses.pl6}>
                                            <Controller
                                                name="currencyId"
                                                control={control}
                                                rules={{
                                                    required: anyPaymentFieldFilled,
                                                }}
                                                render={({ field, fieldState }) => {
                                                    const handleCurrencyChange = (event: SelectChangeEvent<typeof coinType>) => {
                                                        const { target: { value } } = event;
                                                        const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                                        field.onChange(selectedValues);
                                                        setCoinType(selectedValues);

                                                        const selectedCurrencyId = fetchCurrencies.find(
                                                            (currency) => currency.currencyName === value
                                                        )?.id;

                                                        setValue('currencyId', selectedCurrencyId || '');

                                                        setAnyPaymentFieldFilled(!!value);
                                                    }
                                                    return (
                                                        <div className={formClasses.customFormErrorsContainer}>
                                                            {fieldState.error && (
                                                                <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                                    {fieldState.error.message}
                                                                </Typography>
                                                            )}
                                                            <FormControl className={formClasses.selectContainer}>
                                                                <InputLabel
                                                                    id="currency"
                                                                    className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                                    Valuta
                                                                </InputLabel>
                                                                <StyledSelect
                                                                    {...field}
                                                                    required={true}
                                                                    disabled={!anyPaymentFieldFilled}
                                                                    labelId="currency"
                                                                    value={coinType}
                                                                    onChange={handleCurrencyChange}
                                                                    input={
                                                                        <OutlinedInput
                                                                            label="Valuta"
                                                                            className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                                        />
                                                                    }
                                                                    error={!!fieldState.error}
                                                                    inputRef={field.ref}
                                                                >
                                                                    {fetchCurrencies.map((currency) => (
                                                                        <MenuItem
                                                                            key={currency.id}
                                                                            value={currency.currencyName}
                                                                        >
                                                                            {currency.currencySymbol}
                                                                        </MenuItem>
                                                                    ))}
                                                                </StyledSelect>
                                                            </FormControl>
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Grid container className={isMobile ? classes.checkboxesMobileContainer : ''}>
                                        <Grid item xs={4} sm={4} md={3}>
                                            <Controller
                                                name="offeredAccomodation"
                                                control={control}
                                                render={({ field, fieldState }) => {
                                                    return (
                                                        <div className={formClasses.additionalBenefitsContainer}>
                                                            <Checkbox
                                                                {...field}
                                                                className={formClasses.checkAdditionalBenefits}
                                                            />
                                                            <Typography className={formClasses.benefitsText}>Smestaj</Typography>
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={3}>
                                            <Controller
                                                name="offeredFood"
                                                control={control}
                                                render={({ field, fieldState }) => {
                                                    return (
                                                        <div className={formClasses.additionalBenefitsContainer}>
                                                            <Checkbox
                                                                {...field}
                                                                className={formClasses.checkAdditionalBenefits}
                                                            />
                                                            <Typography className={formClasses.benefitsText}>Hrana</Typography>
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={3}>
                                            <Controller
                                                name="offeredTransport"
                                                control={control}
                                                render={({ field, fieldState }) => {
                                                    return (
                                                        <div className={formClasses.additionalBenefitsContainer}>
                                                            <Checkbox
                                                                {...field}
                                                                className={formClasses.checkAdditionalBenefits}
                                                            />
                                                            <Typography className={formClasses.benefitsText}>Prevoz</Typography>
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={isMobile ? formClasses.inputContainerMobile : formClasses.inputContainer}>
                                    <StyledButton
                                        style={{ textTransform: 'none' }}
                                        className={formClasses.w100pi}
                                        variant="contained"
                                        onClick={handleButtonClick}
                                    >
                                        {loading ? <CircularProgress
                                            size={24}
                                            className="circular-progress"
                                        />
                                            : 'Dodaj oglas'}
                                    </StyledButton>
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <StyledButton
                                        style={{ textTransform: 'none' }}
                                        className={formClasses.w100pi}
                                        variant="outlined"
                                        onClick={() => navigate(-1)}
                                    >
                                        Odustani
                                    </StyledButton>
                                </div>
                            </form>
                            {openPricingModal && (
                                <Backdrop open={true}>
                                    <PricingDialog
                                        open={openPricingModal}
                                        onClose={handleClosePricingModal}
                                        onConfirm={handlePricingModalConfirm}
                                    />
                                </Backdrop>
                            )}
                            {successModal && (
                                <Backdrop className={formClasses.zIndexHighest} open={successModal}>
                                    <Dialog
                                        maxWidth={'sm'}
                                        open={successModal}
                                        aria-describedby="successful-payment-dialog"
                                    >
                                        <DialogContent>
                                            <DialogContentText id="successful-payment-dialog">
                                                <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                                    <span>
                                                        Vaš oglas je uspešno dodat.
                                                    </span>
                                                </div>
                                                <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                                    <span>
                                                        Sezonal će vam poslati detalje plaćanja na vašu e-mail adresu.
                                                    </span>
                                                </div>
                                                <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                                    <span>
                                                        Vaš, <img height={12} alt="Sezonal" src={`${logoBlue}`} />
                                                    </span>
                                                </div>
                                            </DialogContentText>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => {
                                                        setSuccessModal(false);
                                                        handleNavigate(navigate, `/employer-dashboard/`)
                                                    }}
                                                >
                                                    Razumem
                                                </Button>
                                            </DialogActions>
                                        </DialogContent>
                                    </Dialog>
                                </Backdrop>
                            )}
                            {errorModal && (
                                <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                                    <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModal(false)} severity="error">Došlo je do greške</Alert>
                                </Backdrop>
                            )}
                        </Grid>
                        <Grid className={isMobile ? formClasses.dne : formClasses.bodyMegaphoneContainer} item md={6}>
                            <img className={formClasses.bodyMegaphone} src={`${BODY_ITEMS.MEGAPHONE}`} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0} sm={0} md={2}>
                    <div className={formClasses.bannersContainer}></div>
                </Grid>
            </Grid>
        </>
    )
}

export default NewJob