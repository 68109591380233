import { useNavigate, useParams } from "react-router-dom";
import { useMediaQueries, handleNavigate } from "../../../utils/global-utils";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import useStyles from "./employerFinal.module";
import Grid from "@material-ui/core/Grid/Grid";
import MobileHeader from "../../mobileHeader/mobileHeader";
import StyledLayoutItem from "../../layoutItem/layoutItem";
import BODY_ITEMS from "../../../assets/bodyItems";
import Typography from "@material-ui/core/Typography/Typography";
import { Controller, useForm } from "react-hook-form";
import { StyledInput } from "../../styledComponents/styledInput/styledInput";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import { useState, useEffect } from "react";
import StyledButton from "../../styledComponents/styledButton.module";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { StyledSelect } from "../../styledComponents/styledSelect/styledSelect";
import RegisterUserApi from "../../../services/registerApi";
import BillingAndIndustriesApi from "../../../services/billingAndIndustries";
import { Country, Place } from '../../../utils/types.d'
import Switch from '@mui/material/Switch';



function EmployerFinalStep() {
    const { isMobile, isTablet } = useMediaQueries();
    const { userId } = useParams();
    const formClasses = useFormStyles();
    const navigate = useNavigate();
    const [isSwitched, setIsSwitched] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [fetchPlaces, setFetchPlaces] = useState<Place[]>([])
    const [fetchCountries, setFetchCountrues] = useState<Country[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
    const [selectedCountryById, setSelectedCountryById] = useState<string>('');
    const [places, setPlaces] = useState<string[]>([]);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const countriesResponse = await BillingAndIndustriesApi.getAllCountries({});
            setFetchCountrues(countriesResponse.data);
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const response: any = await BillingAndIndustriesApi.getAllPlaces(selectedCountryById);
            const { status } = response;
            if (status === 200) {
                setFetchPlaces(response.data);
            }
            
          } catch (error) {
            //
          }
        };

        fetchData();
    }, [selectedCountryById]);

    useEffect(() => {
        setPlaces([]);
    }, [selectedCountryById]);

    const { 
        handleSubmit,
        formState,
        control,
        trigger,
        setValue,
        reset } = useForm({
            mode: 'onChange',
            defaultValues: {
                userId: userId,
                phoneNumber: '',
                country: '',
                place: '',
                companyName: '',
                isRegistered: false,
                pib: ''
            }
    });

    const handleSwitchChange = (event: { target: { checked: boolean }; }) => {
        const { checked } = event.target;
        
        setValue('isRegistered', checked);
        setIsSwitched(checked);
    };

    const onSubmit = async (formData: any) => {

        try {
            await RegisterUserApi.RegisterFinalStepEmployer(formData);
            setSuccessModal(true)
            reset({
                userId: '',
                phoneNumber: '',
                place: '',
                companyName: '',
                isRegistered: false,
                pib: ''
            })

            handleNavigate(navigate, `/account-verification/${userId}`)
        }
        catch (error) {
            setErrorModal(true);
        }
    }

    const handleButtonClick = async () => {
        try {
            await trigger();
            if (Object.keys(formState.errors).length === 0) {
                setLoading(true);
                await handleSubmit(onSubmit)();
                setLoading(false);
            }
        }
        catch (err) {
            //
        }
    }

    return (
    <>
        <Grid container className={formClasses.container}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                        {(isMobile || isTablet) ? (
                            <MobileHeader />
                        ) : (
                            <StyledLayoutItem 
                                alt="construct"
                                imageSrc={`${BODY_ITEMS.FARM}`}
                                children
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className={isMobile ? `${formClasses.pt84} ${formClasses.pb84}` : formClasses.formContainer}>
                                    <div className={formClasses.formText}>
                                        <Typography className={formClasses.headline}>
                                            Još samo ovaj korak!
                                        </Typography>
                                        <Typography className={formClasses.headlineSmall}>
                                            Popunite ostatak neophodnih informacija za Vaš profil
                                        </Typography>
                                    </div>
                                    <form onSubmit={handleButtonClick}>
                                        <div className={formClasses.inputContainer}>
                                            <Controller
                                                name="companyName"
                                                control={control}
                                                render={({field, fieldState}) => {
                                                    const isMobileCheck = isMobile ? {width: '100%'} : {};
                                                    return (
                                                        <StyledInput
                                                            required={false}
                                                            type="text"
                                                            label="Naziv kompanije / Gazdinstva"
                                                            style={isMobileCheck}
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            inputRef={field.ref}
                                                            {...field}
                                                        />
                                                    )
                                                }} 
                                            />
                                        </div>
                                        <div className={formClasses.inputContainer}>
                                            <Controller
                                                name="phoneNumber"
                                                control={control} 
                                                rules={{ 
                                                    required: 'Polje je obavezno',
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message: 'Unesite broj',
                                                    },
                                                    minLength: {
                                                        value: 9,
                                                        message: 'Broj telefona mora imati najmanje 9 cifara',
                                                    },
                                                    maxLength: {
                                                        value: 10,
                                                        message: 'Broj telefona ne sme imati više od 10 cifara',
                                                    }
                                                }}
                                                render={({field, fieldState}) => {
                                                    const isMobileCheck = isMobile ? {width: '100%'} : {};
                                                    return (
                                                        <StyledInput
                                                            required
                                                            type="tel"
                                                            style={isMobileCheck}
                                                            label="Broj telefona"
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            inputRef={field.ref}
                                                            {...field}
                                                        />
                                                    )
                                                }}    
                                            />
                                        </div>
                                        <div className={formClasses.inputContainer}>
                                            <Controller
                                                name="country"
                                                control={control}
                                                rules={{
                                                    required: 'Polje je obavezno'
                                                }}
                                                render={({field, fieldState}) => {
                                                    const isMobileCheck = isMobile ? {width: '100%'} : {};
                                                    const handleCountryChange = (event: SelectChangeEvent<typeof selectedCountry>) => {
                                                        const { target: { value } } = event;
                                                        const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                                        field.onChange(selectedValues);
                                                        setSelectedCountry(selectedValues)
                
                                                        const selectedCountryId: string | undefined = fetchCountries.find(
                                                            (place) => place.countryName === value
                                                        )?.id
                
                                                        setSelectedCountryById(selectedCountryId as string)
                                                        setValue('country', selectedCountryId || '');
                                                    }
                                                    return (
                                                        <div className={formClasses.customFormErrorsContainer}>
                                                            {fieldState.error && (
                                                                <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                                    {fieldState.error.message}
                                                                </Typography>
                                                            )}
                                                            <FormControl className={formClasses.selectContainer}>
                                                                <InputLabel 
                                                                    id="select-country" 
                                                                    className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                                        Država
                                                                </InputLabel>
                                                                <StyledSelect
                                                                    required={true}
                                                                    labelId="select-country"
                                                                    value={selectedCountry}
                                                                    onChange={handleCountryChange}
                                                                    style={isMobileCheck}
                                                                    input={
                                                                        <OutlinedInput 
                                                                            label="Država" 
                                                                            className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                                        />
                                                                    }
                                                                    error={!!fieldState.error}
                                                                    inputRef={field.ref}
                                                                >
                                                                    {fetchCountries.slice().sort((a, b) => a.countryName.localeCompare(b.countryName))
                                                                        .map((region, index) => (
                                                                            <MenuItem
                                                                                className={formClasses.menuItemContainer}
                                                                                key={index} 
                                                                                value={region.countryName}
                                                                            >
                                                                                {region.countryName}
                                                                            </MenuItem>
                                                                    ))}
                                                                </StyledSelect>
                                                            </FormControl>
                                                        </div>
                                                    )
                                                }}    
                                            />
                                        </div>
                                        <div className={formClasses.inputContainer}>
                                            <Controller
                                                name="isRegistered"
                                                control={control}
                                                render={({field, fieldState}) => {
                                                    return (
                                                        <FormControlLabel 
                                                            control={
                                                                <Switch 
                                                                    {...field}
                                                                    inputRef={field.ref}
                                                                    size="medium"
                                                                    checked={isSwitched}
                                                                    onChange={handleSwitchChange}
                                                                    
                                                                />} 
                                                            label="Registrovan/na sam" 
                                                        />
                                                    )
                                                }}
                                            />
                                        </div>
                                        {isSwitched && (
                                            <div className={formClasses.inputContainer}>
                                                <Controller
                                                    name="pib"
                                                    control={control}
                                                    rules={{ 
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9]*$/,
                                                            message: 'Može sadržati samo slova i brojeve',
                                                        },
                                                    }}
                                                    render={({field, fieldState}) => {
                                                        return (
                                                            <StyledInput
                                                                required
                                                                type="text"
                                                                label="PIB / OIB / JIB / VATIN"
                                                                error={!!fieldState.error}
                                                                helperText={fieldState.error?.message}
                                                                inputRef={field.ref}
                                                                {...field}
                                                            />
                                                        )
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div className={formClasses.inputContainer}>
                                            <Controller
                                                name="place"
                                                control={control}
                                                rules={{
                                                    required: 'Polje je obavezno'
                                                }}
                                                render={({field, fieldState}) => {
                                                    const handleCityChange = (event: SelectChangeEvent<typeof places>) => {
                                                        const { target: { value } } = event;
                                                        const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                                        field.onChange(selectedValues);
                                                        setPlaces(selectedValues)

                                                        const selectedCityId = fetchPlaces.find(
                                                            (place) => place.city === value
                                                        )?.id

                                                        setValue('place', selectedCityId || '');
                                                    }
                                                    return (
                                                        <div className={formClasses.customFormErrorsContainer}>
                                                            {fieldState.error && (
                                                                <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                                    {fieldState.error.message}
                                                                </Typography>
                                                            )}
                                                            <FormControl className={formClasses.selectContainer}>
                                                                <InputLabel 
                                                                    id="select-city" 
                                                                    className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                                        Grad
                                                                </InputLabel>
                                                                <StyledSelect
                                                                    {...field}
                                                                    required={false}
                                                                    labelId="select-city"
                                                                    value={places}
                                                                    onChange={handleCityChange}
                                                                    input={
                                                                        <OutlinedInput 
                                                                            label="Grad" 
                                                                            className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                                        />
                                                                    }
                                                                    error={!!fieldState.error}
                                                                    inputRef={field.ref}
                                                                >
                                                                    {fetchPlaces.slice().sort((a, b) => a.city.localeCompare(b.city))
                                                                        .map((region, index) => (
                                                                            <MenuItem
                                                                                className={formClasses.menuItemContainer}
                                                                                key={index} 
                                                                                value={region.city}
                                                                            >
                                                                                {region.city}
                                                                            </MenuItem>
                                                                    ))}
                                                                </StyledSelect>
                                                            </FormControl>
                                                        </div>
                                                    )
                                                }} 
                                            />
                                        </div>
                                        <div className={formClasses.inputContainer}>
                                            <StyledButton
                                                className={isMobile? formClasses.w100pi : ''}
                                                style={{ textTransform: 'none'}}
                                                onClick={handleButtonClick}
                                                variant="contained"
                                            >
                                                {loading ? <CircularProgress 
                                                                size={24}
                                                                className="circular-progress"
                                                            />
                                                : 'Završi registraciju'}
                                            </StyledButton>
                                        </div>
                                        <div className={formClasses.inputContainer}>
                                            <StyledButton 
                                                className={isMobile? formClasses.w100pi : ''}
                                                style={{ textTransform: 'none'}}
                                                variant="text" onClick={() => navigate(-1)}
                                            >
                                                Vrati se nazad
                                            </StyledButton>
                                        </div>
                                        {successModal && (
                                            <Backdrop className={formClasses.zIndexHighest} open={successModal}>
                                                <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setSuccessModal(false)} severity="success">Vaš profil je uspešno kreiran!</Alert>
                                            </Backdrop>
                                        )}
                                        {errorModal && (
                                            <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                                                <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModal(false)} severity="error">Došlo je do greške!</Alert>
                                            </Backdrop>
                                        )}
                                    </form>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
    )
}

export default EmployerFinalStep