import Grid from "@material-ui/core/Grid/Grid";
import { handleNavigate, useMediaQueries } from "../../../utils/global-utils";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import MobileHeader from "../../mobileHeader/mobileHeader";
import StyledLayoutItem from "../../layoutItem/layoutItem";
import BODY_ITEMS from "../../../assets/bodyItems";
import Typography from "@material-ui/core/Typography/Typography";
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from "@material-ui/core";
import StyledButton from "../../styledComponents/styledButton.module";
import { useForm, Controller } from "react-hook-form";
import { StyledInput } from "../../styledComponents/styledInput/styledInput";
import { useState } from "react";
import RegisterUserApi from "../../../services/registerApi";




function ForgotPassword() {
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { 
        handleSubmit,
        formState,
        control,
        trigger, 
        reset } = useForm({
            mode: 'onChange',
            defaultValues: {
                emailAddress: '',
            }
    });

    const onSubmit = async (formData: any) => {
        try {  
            const response: any = await RegisterUserApi.ForgotPassword(formData);
            const { status } = response;
            reset({
                emailAddress: ''
            })
            if (status === 200) {
                handleNavigate(navigate,  `/forgot-password-confirmation` )
            } 
        } catch (error) {
            //
        }
    };

    const handleButtonClick = async () => {
        try {
            await trigger();
            if (Object.keys(formState.errors).length === 0) {
                setLoading(true);
                await handleSubmit(onSubmit)();
                setLoading(false);
            }
        }
        catch (err) {
            //
        }
    };

    return (
        <>
            <Grid container className={formClasses.container}>
                {(isMobile || isTablet) ? (
                    <MobileHeader />
                ) : (  
                    <StyledLayoutItem 
                        alt="lifeguard" 
                        imageSrc={`${BODY_ITEMS.BARTENDER}`} 
                        children
                    />
                )}
                <Grid item xs={12} sm={12} md={6}>
                    <div className={isMobile ? formClasses.formContainerMobile : formClasses.formContainer}>
                        <div className={formClasses.rolePickerText}>
                            <Typography className={formClasses.headline}>
                                Zaboravio/la sam lozinku
                            </Typography>
                        </div>
                        <form>
                            <div className={formClasses.inputContainer}>
                                <Controller
                                    name="emailAddress"
                                    control={control}
                                    rules={{ 
                                        required: 'Polje je obavezno',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Unesi validnu e-mail adresu',
                                        }, 
                                    }}
                                    render={({field, fieldState}) => {
                                        return (
                                            <StyledInput
                                                required={true}
                                                type="email"
                                                label="Unesi e-mail adresu"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                                inputRef={field.ref}
                                                {...field}
                                            />
                                        )
                                    }}
                                />
                            </div>
                            <div className={formClasses.inputContainer}>
                                <StyledButton
                                    style={{ textTransform: 'none'}}
                                    onClick={handleButtonClick}
                                    variant="contained"
                                >
                                    {loading ? <CircularProgress 
                                                size={24}
                                                className="circular-progress"
                                            />
                                            : ' Pošalji'}
                                   
                                </StyledButton>
                            </div>
                            <div className={formClasses.inputContainer}>
                                <StyledButton 
                                    style={{ textTransform: 'none'}}
                                    variant="text" onClick={() => navigate(-1)}
                                >
                                    Vrati se nazad
                                </StyledButton>
                            </div>
                        </form>
                    </div>  
                </Grid>
            </Grid>
        </>
    )

}

export default ForgotPassword