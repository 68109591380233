import { createTheme } from '@mui/material/styles';


const theme = createTheme({
    typography: {
      fontFamily: 'Inter'
    },
    palette: {
      primary: {
        main: '#0033FF',
      },
      secondary: {
        main: '#FFFFFF',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    spacing: 8
});

export default theme;