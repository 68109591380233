import farm from '../bodyItems/farma.jpg'
import construct from '../bodyItems/gradjevina.jpg'
import bartender from '../bodyItems/konobar.jpg'
import beach from '../bodyItems/plaza.jpg'
import background from '../bodyItems/landing_background.jpg'
import megaphone from '../bodyItems/graphic_megaphone.png'
import no_results from '../bodyItems/no_results.png'

const BODY_ITEMS = {
    FARM: farm,
    CONSTRUCT: construct,
    BARTENDER: bartender,
    BEACH: beach,
    LANDING_BACKGROUND: background,
    MEGAPHONE: megaphone,
    NO_RESULTS: no_results

}


export default BODY_ITEMS