import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles((theme) => ({
  cookiesConsentDialog: {
    '& .MuiPaper-root': {
      width: '90% !important',
      maxWidth: '90% !important',
      margin: '12px !important',
      position: 'absolute !important',
      bottom: '0 !important'
    }
  },
  contentContainer: {
    width: '80%'
  },
  contentContainerMobile: {
    paddingLeft: '24px',
    paddingRight: '24px',
    marginBottom: '48px'
  },
  contentContainerTablet: {
    paddingBottom: '24px',
    paddingLeft: '0',
    paddingRight: '0'
  },
  mobileScreensContainer: {
    width: '598px',
    height: '624px',
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  mobileScreensContainerSmall: {
    height: '336px',
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  teamMembersContainerSmall: {
    width: '100%'
  },
  mobileScreens: {
    width: '100%',
    height: '100%'
  },
  teamMates: {
    width: '100%',
  },
  newsletterForm: {
    paddingRight: '124px'
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '990px',

  },
  containerMobile: {
    height: '708px'
  },
  carouselContainer: {
    width: '100%',
    height: '990px'
  },
  carouselContainerMobile: {
    width: '100%',
    height:'940px'
  },
  carouselImages: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  carouselImagesMobile: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: '1',
    width: '100%',
    height: '100%',
    display: 'block',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'start',
    paddingTop: '60px'
  },
  overlayTablet: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: '1',
    width: '100%',
    height: '100%',
    display: 'block',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'start',
    paddingLeft: '60px',
    paddingTop: '180px'
  },
  overlayMobile: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: '1',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'start',
  },
  gridContainer: {
    marginTop: '128px',
    marginBottom: '48px'

  },
  gridContainerMobile: {
    marginTop: '84px',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  textContainer: {
    marginTop: '36px',
    marginBottom: '60px'
  },
  textContainerMobilePrimary: {
    marginTop: '72px',
    marginBottom: '24px',
    width: '80%'
  },
  buttonsContainer: {
    marginLeft: '12px !important',
  },
  textContainerMobileSecondary: {
    marginTop: '24px',
    marginBottom: '176px',
    width: '80%'
  },
  primaryTextContainer: {
    marginBottom: '24px',
    marginLeft: '12px'
  },
  primaryText: {
    color: '#FFFFFF',
    fontSize: '60px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '72px',
  },
  primaryTextMobile: {
    color: '#FFFFFF',
    fontSize: '30px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '42px',
  },
  secondaryText: {
    color: '#FFFFFF',
    fontWeight: '200',
    fontSize: '16px',
    lineHeight: '24px'
  },
  secondaryTextMobile: {
    color: '#FFFFFF',
    fontWeight: '200',
    fontSize: '16px',
    lineHeight: '24px'
  },

  newsletterButton: {
    borderRadius: '30px',
    backgroundColor: '#FFFFFF',
    width: '266px',
    color: '#0033FF',
    padding: '10px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0033FF',
      color: 'white',
    },
  },
  mobileButtonContainer: {
    display: 'flex', 
    justifyContent: 'center',
    marginRight: '24px',
    paddingRight: '24px'
  },
  mobileNewsletterButton: {
    width: '100%',
    height: '60px',
    borderRadius: '30px',
    backgroundColor: 'white',
    color: '#0033FF',
    padding: '10px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0033FF',
      color: 'white',
    },
    
  },
  newsletterButonContainer: {
    marginTop: '67px'
  },
  applyContainer: {
    float: 'right'
  },
  newsletterButtonApply: {
    float: 'right',
    borderRadius: '30px',
    width: '266px',
    backgroundColor: '#0033FF',
    color: '#FFFFFF',
    padding: '10px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#0033FF',
    },
  },
  newsletterButtonApplyMobile: {
    width: '100%',
    height: '60px',
    borderRadius: '30px',
    backgroundColor: '#0033FF',
    color: '#FFFFFF',
    padding: '10px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0033FF',
      color: '#FFFFFF',
    },
    
  },
  indicator: {
    padding: 0,
    color: "#FFFFFF",
    opacity: '0.5 !important',
    '&:active': {
        opacity: '1 !important'
        
    }
  },
  middleSection: {
    paddingTop: '180px',
    paddingLeft: '120px',
    paddingBottom: '256px'
  },
  middleSectionTablet: {
    paddingLeft: '60px'
  },
  middleSectionMobile: {
    paddingTop: '96px',
  },
  middleSectionMobileTeam: {
    paddingTop: '96px',
  },
  headlineBottomLarge: {
    marginBottom: '100px'
  },
  headlineBottomSmall: {
    paddingBottom: '40px'
  },
  middleHeadline: {
    color: '#1C1C1E',
    fontSize: '48px',
    fontWeight: 'bold',
  },
  middleHeadlineMobile: {
    color: '#1C1C1E',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  middleText: {
    fontSize: '16px',
    color: '#636366',
    lineHeight: '24px',
    fontWeight: '200'
  },
  benefitsContainer: {
    marginTop: '96px',
    marginBottom: '96px'
  },
  benefitsPlaceholders: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '48px',
    marginBottom: '72px',
  },
  benefitsHeadline: {
    display: 'flex',
    justifyContent: 'center',
    color: '#1C1C1E',
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: '900',
  },
  benefitsBanners: {
    
  },
  benefitsList: {
    '& li': {
      fontSize: '14px',
      color: '#636366',
      paddingTop: '6px',
      paddingBottom: '6px'
    }
  }, 
  newsletterInputs: {
    width: '100%',
    height: '60px',
    borderRadius: '6px',
    marginBottom: '5px'
  },
  newsletterInputsMobile: {
    width: '100%',
    height: '60px',
    borderRadius: '6px',
    marginBottom: '15px'
  },
  inputsContainer: {
    paddingRight: '124px'
  },
  textField: {
    marginBottom: '56px'
  },
  inputsDivider: {
    marginBottom: '56px'
  },
  consentContainer: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'flex-start'
  },
  consentCheckbox: {
    padding: '0px'
  },
  manageConsent: {
    color: '#707070',
    fontSize: '14px',
    lineHeight: '17px',
    marginLeft: '12px'
  },
  manageConsentError: {
    color: '#FF221A',
    fontSize: '14px',
    lineHeight: '17px',
    marginLeft: '12px',
  },
  footerSection: {
    backgroundColor: '#001A80',
    height: '360px',
    color: '#FFFFFF'
  },
  footerSectionMobile: {
    backgroundColor: '#001A80',
    height: '480px',
    color: '#FFFFFF',
    marginTop: '128px'
  },
  footerItemsContainer: {
    paddingLeft: '120px',
    paddingRight: '120px',
    paddingTop: '60px'
  },
  footerItemsContainerMobile: {
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '60px',
  },
  footerText: {
    fontSize: '16px',
    color: '#FFFFFF',
  },
  copyrightContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px'
  },
  manageConsentLink: {
    cursor: 'pointer'
  },
  mobileDialog: {
    bottom: '30%' 
  },
  tabletDialog: {
    bottom: '20%'
  },
  mobileDialogBody: {
    maxHeight: '400px'
  },
  mediaIcons: {
    width: '48px',
    height: '48px',
    paddingLeft: '6px',
    paddingRight: '6px'

  },
  // UTILS
  p32: {
    padding: '32px'
  },
  pl0i: {
    paddingLeft: '0px !important'
  },
  pl6: {
    paddingLeft: '6px'
  },
  pr6: {
    paddingRight: '6px'
  },
  mr6: {
    marginRight: '6px'
  },
  ml6: {
    marginLeft: '6px'
  },

  mtb12: {
    marginTop: '12px',
    marginBottom: '12px'
  },
  
}));

export default useStyles
