import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles((theme) => ({
    ratingDetailsUpper: {
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '6px'
    },
    ratingDetails: {
        display: 'flex', 
        alignItems: 'center'
    },
    name: {
        fontSize: '12px',
        color: '#1C1C1E',
        fontWeight: 'bold'
    },
    ratingDetailsText: {
        fontSize: '14px',
        color: '#1C1C1E',
        fontWeight: 'bold'
    },
    abusementType: {
        cursor: 'pointer',
        width: '100%',
        border: '1px solid #B8B8BD',
        borderRadius: '6px',
        marginTop: '6px',
        marginBottom: '6px'
    },
    abusementTypeSelected: {
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#EBEFFF',
        border: '1px solid #0033FF',
        borderRadius: '6px',
        marginTop: '6px',
        marginBottom: '6px'
    },
    abusementTypeError: {
        cursor: 'pointer',
        width: '100%',
        border: '1px solid #FF221A',
        borderRadius: '6px',
        marginTop: '6px',
        marginBottom: '6px'
    },
    abusementTypeHeadline: {
        paddingTop: '12px',
        paddingLeft: '6px',
        fontWeight: 'bold',
        color: '#1C1C1E'
    },
    abusementTypeHeadlineSelected: {
        paddingTop: '12px',
        paddingLeft: '6px',
        fontWeight: 'bold',
        color: '#0033FF'
    },
    abusementTypeExplanation: {
        paddingBottom: '12px',
        paddingLeft: '6px',
        color: '#636366'
    }
}));

export default useStyles