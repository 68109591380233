import facebookIcon from './facebook.png'
import instagramIcon from './instagram.png'
import linkedinIcon from './linkedin.png'
import serbiaFlagIcon  from './serbia_flag.svg'

const SOCIAL_MEDIA_ICONS = {
    facebook: facebookIcon,
    instagram: instagramIcon,
    linkedIn: linkedinIcon,
    serbiaFlagIcon: serbiaFlagIcon
}

export default SOCIAL_MEDIA_ICONS