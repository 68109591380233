import Grid from "@mui/material/Grid/Grid"
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Divider from '@mui/material/Divider';
import { EmployeeRatingsResponse, EmployerRatingsResponse } from "../../../utils/types.d";
import useStyles from "./userRatingsWithReplies.module";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import StyledButton from "../../styledComponents/styledButton.module";


interface UserRatingsWithRepliesProps {
    ratings: any;
    onReportAbuse?: () => void,
    onRateUser?: () => void
}

function UserRatingsWithReplies({ ratings, onReportAbuse, onRateUser }: UserRatingsWithRepliesProps) {
    const classes = useStyles();
    const formClasses = useFormStyles();

    return (
        <Grid item xs={12} sm={12} md={12}>
            {ratings.length === 0 ? (
                <>
                    <div className={classes.ratingsContainer}>
                        <div className={formClasses.companyNameHeadline}>
                            Bez ocena
                        </div>
                    </div>
                    <Divider />
                </>
              
            ) : (
                <>
                    {ratings.map((ratingDetails: EmployeeRatingsResponse | EmployerRatingsResponse, index: number) => (
                        <>
                        <div key={index} className={classes.ratingRepliesContainer}>
                            <div  className={classes.ratingsContainer}>
                                <div className={classes.ratingDetailsUpper}>
                                    <div className={classes.ratingDetails}>
                                        <StarRoundedIcon className={formClasses.star}/>
                                        <div className={formClasses.grade}>{ratingDetails?.rating?.rating}</div>
                                        <FiberManualRecordIcon className={formClasses.dotSeparator}/>
                                        <div className={classes.name}>{ratingDetails?.employeeFirstName} {ratingDetails?.employeeLastName}</div>
                                    </div>
                                    <div className={classes.addedDate}>
                                        <div className={classes.date}>{ratingDetails?.rating?.assessmentTime.split('T')[0]}</div>
                                    </div>
                                </div>
                                <div className={classes.ratingDetailsBottom}>
                                    <div className={classes.ratingDetailsText}>
                                        {ratingDetails?.rating?.comment}
                                    </div>
                                </div>
                            </div>
                            {ratingDetails?.reply && (
                                <>
                                    <div className={`${classes.commentReplyLine} ${classes.commentLineVertical}`}>
                                    </div>
                                    <div className={`${classes.commentReplyLine} ${classes.commentLineHorizontal}`}>
                                    </div>
                                </>
                               
                            )}
                            {ratingDetails?.reply ? (
                                <div  className={classes.ratingsReplyContainer}>
                                    <div className={classes.ratingDetailsUpper}>
                                        <div className={classes.ratingDetails}>
                                            <StarRoundedIcon className={formClasses.star}/>
                                            <div className={formClasses.grade}>{ratingDetails?.reply?.rating}</div>
                                            <FiberManualRecordIcon className={formClasses.dotSeparator}/>
                                            <div className={classes.name}>{ratingDetails?.companyName}</div>
                                        </div>
                                        <div className={classes.addedDate}>
                                            <div className={classes.date}>{ratingDetails?.reply?.assessmentTime.split('T')[0]}</div>
                                        </div>
                                    </div>
                                    <div className={classes.ratingDetailsBottom}>
                                        <div className={classes.ratingDetailsText}>
                                            {ratingDetails?.reply?.comment}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <div className={formClasses.inputContainer}>
                                            <StyledButton
                                                style={{ textTransform: 'none'}}
                                                variant="outlined"
                                                onClick={onReportAbuse}
                                            >
                                                Prijavi zloupotrebu
                                            </StyledButton>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6}>
                                        <div className={formClasses.inputContainer}>
                                            <StyledButton
                                                style={{ textTransform: 'none'}}
                                                variant="contained"
                                                onClick={onRateUser}
                                            >
                                                Oceni korisnika
                                            </StyledButton>
                                        </div>
                                    </Grid>
                                </Grid>
                                
                            )}
                        </div>
                        <div className={formClasses.inputContainerTop}>
                            <Divider />
                        </div>
                        </>
                    ))}
                </> 
            )}
            
        </Grid>
    )
}

export default UserRatingsWithReplies;