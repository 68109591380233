import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles(() => ({
    carouselContainer: {
        minHeight: '295px',
        '& .react-multi-carousel-dot-list ': {
            bottom: '10px !important',
        }
    },
    skeleton: {
        backgroundColor: 'rgba(235, 239, 255, 0.6)',
    }
}));

export default useStyles