import { useNavigate } from "react-router-dom";
import { useMediaQueries, handleNavigate } from "../../../utils/global-utils";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import { useEffect, useState } from "react";
import { Candidate } from "../../../utils/types.d";
import { useAuthContext } from "../../../context/UserContext";
import FavoritesApi from "../../../services/favoritesApi";
import Grid from "@mui/material/Grid/Grid";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FunctionalHeader from "../../functionalHeader/functionalHeader";
import DetailedCard from '../../wwwApp/detailedCard/detailedCard'
import Backdrop from "@mui/material/Backdrop/Backdrop";
import StyledSpinningIcon from "../../styledComponents/spinningIcon/spinningIcon";
import Divider from '@mui/material/Divider';

function FavoriteCandidates() {
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const { accessToken } = useAuthContext();

    const [fetchFavoriteCandidates, setFetchFavoriteCandidates] = useState<Candidate[]>([]);

    useEffect(() => {
        const fetchDetails = async () => {
          try {
            if (accessToken) {

                const favoriteCandidatesResponse = await FavoritesApi.getFavoriteCandidates(accessToken);
                setFetchFavoriteCandidates(favoriteCandidatesResponse.data)
                
                setLoading(false);
            }
          } catch (error) {
            setLoading(false);
          }
        };

        if (accessToken) {
          setLoading(true);
          fetchDetails();
        }
    }, [accessToken]);

    return (
        <>
            <Grid container>
                <Grid item xs={0} sm={0} md={2}>
                    <div className={formClasses.bannersContainer}></div>
                </Grid>
                <Grid item xs={12} sm={12} md={8} className={isMobile ? `${formClasses.ml12} ${formClasses.mr12}` : ''}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <FunctionalHeader />
                        </Grid>
                    </Grid>
                    <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                        <div className={formClasses.companyNameHeadline}>
                            <ArrowBackIcon 
                                className={formClasses.navigateBack}
                                onClick={() => handleNavigate(navigate, `/employer-dashboard/`)} /> Omiljeni kandidati
                        </div>
                    </div>
                    <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                        <div className={formClasses.bodyItemsHolder}>
                            {loading ? (
                                <Backdrop open={true}>
                                    <div className={formClasses.circularProgressContainer}>
                                        <StyledSpinningIcon />
                                    </div>
                                </Backdrop>
                            ) : (
                                <>
                                    <Grid item xs={12} sm={12} md={12}>
                                   
                                        {fetchFavoriteCandidates.length !== 0 ? (
                                            <DetailedCard addDetails={fetchFavoriteCandidates} />
                                        ) : (
                                            <>
                                            <div className={formClasses.inputContainerTop}>
                                                <div className={`${formClasses.canditateName} ${formClasses.ml28}`}>
                                                    Nemate omiljenih kandidata
                                                </div>
                                            </div>
                                            <Divider />
                                            </>   
                                        )}
                                     
                                    </Grid>
                                </>
                            )}
                       </div>
                    </Grid>
                </Grid>
                <Grid item xs={0} sm={0} md={2}>
                    <div className={formClasses.bannersContainer}></div>
                </Grid>
            </Grid>
        </>
    )
}

export default FavoriteCandidates