import { Button, Typography } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface ConfirmationModalProps {
    open: boolean;
    onClose?: () => void;
    onConfirm?: () => void;
    titleText?: string;
    bodyText?: string;
    inputText?: string;
}

function StyledConfirmationModal({ open, onClose, onConfirm, titleText, bodyText, inputText }: ConfirmationModalProps) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{titleText}</DialogTitle>
            <DialogContent>
                <Typography>{bodyText}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Ne
                </Button>
                <Button onClick={onConfirm} autoFocus>
                    Da
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default StyledConfirmationModal