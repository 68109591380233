import { makeStyles } from  '@mui/styles';

const useFormStyles: any = makeStyles(() => ({
    container: {
        height: '100vh'
    },
    layoutContainer: {
        paddingLeft: '12px',
        paddingRight: '12px',
        marginBottom: '24px'
    },
    bannersContainer: {
        backgroundColor: '#EBEFFF',
        height: '100%'
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '84px',
        paddingBottom: '24px',
    },
    formContainerMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '84px',
        paddingBottom: '24px',
    },
    headlineContainer: {
        margin: '28px',
        marginTop: '106px'
    },
    headerMenuItems: {
        color: '#ffffff',
        marginLeft: '6px',
        marginRight: '6px',
        fontSize: '16px',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    bodyItemsHolder: {
        minHeight: '1200px',
        width: '100%',
    },
    detailedCardContainer: {
        backgroundColor: '#EBEFFF',
        border: '1px solid #B8B8BD',
        borderRadius: '6px',
        padding: '12px',
        marginBottom: '6px',
        cursor: 'pointer'
    },  
    avatarContainer: {
        backgroundColor: '#001A80',
        width: '60px',
        height: '60px',
        borderRadius: '60px',
        display: 'flex',
        justifyContent: 'center'
    },
    avatarContainerLarge: {
        backgroundColor: '#001A80',
        width: '180px',
        height: '180px',
        borderRadius: '180px',
        display: 'flex',
        justifyContent: 'center'
    },
    initialsHolderExtraSmall: {
        color: '#EBEFFF',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        justifyContent: 'center',
        fontSize: '16px'
    },
    initialsHolderMedium: {
        color: '#EBEFFF',
        display: 'flex',
        fontSize: '24px',
        alignItems: 'center',
        fontWeight: 'bold',
        justifyContent: 'center'
    },
    newAppliesChip: {
        backgroundColor: '#FF5C0D !important',
        color: '#FFFFFF !important',
        borderRadius: '4px !important',
        height: 'fit-content !important',
        padding: '6px 0px 6px 0px !important',
        marginLeft: '12px',
    },
    activeChip: {
        backgroundColor: '#1FCC5A',
        color: '#1C1C1E',
        opacity: '75%',
        fontSize: '12px',
        borderRadius: '6px',
        padding: '6px 8px 6px 8px',
    },
    inactiveChip: {
        backgroundColor: '#FF221A',
        opacity: '75%',
        fontSize: '12px',
        borderRadius: '6px',
        padding: '6px 8px 6px 8px',
    },
    idustriesChip: {
        borderRadius: '6px',
        border: '1px solid: #0033FF',
        color: '#0033FF',
        fontSize: '12px'
    },
    star: {
        fontSize: '12px !important',
        color: '#FFD740 !important'
    },
    grade: {
        color: '#1C1C1E',
        fontWeight: 'bold',
        paddingLeft: '4px !important',
    },
    gradeMobile: {
        fontSize: '14px',
        color: '#1C1C1E',
        fontWeight: 'bold',
        paddingLeft: '4px !important',
    },
    gradeOverlay: {
        fontSize: '12px',
        color: '#1C1C1E',
        fontWeight: 'bold',
        paddingLeft: '4px !important',
    },
    dotSeparator: {
        width: '4px !important',
        height: '4px !important',
        color: '#B8B8BD !important',
        paddingLeft: '4px !important',
        paddingRight: '4px !important'
    },
    toggleLayoutsContainer: {
        marginTop: '48px',
        marginBottom: '185px',
        minHeight: '1200px'
    },
    toggleLayoutsContainerMobile: {
        marginTop: '48px',
        marginBottom: '24px',
    },
    personalDetailsContainer: {
        display: 'flex',
        justifyContent: 'start',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '12px'
    },
    canditateName: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#1C1C1E'
    },
    candidateNameMobile: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#1C1C1E',
        width: '50%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    middleSectionContainer: {
        margin: '18px 12px 12px 12px'
    },
    buttonsGroup: {
        width: '100% !important',
        '& .Mui-selected': {
            backgroundColor: '#0033FF !important',
            color: '#FFFFFF !important'
        },
        '& .css-ueukts-MuiButtonBase-root-MuiToggleButton-root': {
            color: '#0033FF'
        },
        '& .MuiToggleButton-root': {
            border: '1px solid #0033FF !important',
        }
    },
    singleToggleButton: {
        fontSize: '12px !important',
        width: '50% !important',
        height: '36px !important'
    },
    buttonDisabled: {
        backgroundColor: 'rgba(0, 0, 0, 0.12) !important'
    },
    bodyContainer: {
        paddingLeft: '28px',
        paddingRight: '28px'
    },
    bodyContainerMobile: {
        marginLeft: '0px !important',
        marginRight: '0px !important',
        paddingLeft: '8px !important',
        paddingRight: '8px !important'
    },
    navigationContainer: {
        marginLeft: '28px',
        marginRight: '28px',
        marginTop: '106px',
        marginBottom: '48px'
    },
    navigationContainerMobile: {
        marginLeft: '8px',
        marginRight: '0px',
        marginTop: '106px',
        marginBottom: '24px'
    },
    navigateBack: {
        backgroundColor: '#EBEFFF',
        color: '#0033FF',
        marginRight: '12px',
        fontWeight: 'bold',
        borderRadius: '6px',
        cursor: 'pointer'
    },
    companyNameHeadline: {
        display: 'flex',
        color: '#1C1C1E',
        fontSize: '24px',
        lineHeight: '24px',
        fontWeight: '600',
    },
    userRatingsName: {
        display: 'flex',
        color: '#1C1C1E',
        lineHeight: '24px',
        fontWeight: '900',
    },
    actionCardsMobile: {
        height: '112px !important',
        marginBottom: '12px'
    },
    actionCardsText: {
        color: '#1C1C1E',
        fontWeight: 'bold',
        fontSize: '16px',
        paddingTop: '4px',
        cursor: 'pointer'
    },
    actionCardsTextSelected: {
        color: '#FFFFFF !important'
    },
    formText: {
        width: '320px',
        paddingBottom: '45px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    rolePickerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '84px',
    },
    rolePickerText: {
        paddingBottom: '24px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    styledCardBody: {
        borderRadius: '6px',
        width: '320px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        border: '1px solid #B8B8BD',
        cursor: 'pointer',
    },
    headline: {
        color: '#1C1C1E !important',
        fontSize: '24px !important',
        lineHeight: '24px !important',
        fontWeight: 'bold !important',
        marginBottom: '12px !important'
    },
    headlineSmall: {
        fontSize: '12px',
        color: '#B8B8BD',
        lineHeight: '16px'
    },
    inputContainer: {
        '& .MuiStack-root': {
            paddingTop: '35px !important'
        },
        paddingBottom: '35px',
    },
    inputContainerTop: {
        paddingTop: '35px',
        paddingBottom: '35px'
    },
    inputContainerSmall: {
        display: 'flex',
        justifyContent: 'end',
        paddingTop: '12px',
        paddingBottom: '35px'
    },
    inputContainerMobile: {
        paddingBottom: '15px',
    },
    selectContainer: {
        width: '100%',
        '& .MuiChip-root': {
            borderRadius: '6px !important',
            color: '#FFFFFF !important',
            fontWeight: 'bold !important',
            backgroundColor: '#0033FF !important'
        },
        '& .MuiInputLabel-root': {
            zIndex: '0 !important',
            fontWeight: 'bold !important'
        },
        '& .MuiSelect-outlined': {
            color: 'rgba(0, 0, 0, 0.6) !important',
            fontWeight: 'bold'
        }
    },
    chipContainer: {
        display: 'flex',     
        flexWrap: 'wrap', 
        gap: 5
    },
    menuItemContainer: {
        display: 'flex !important',
        paddingLeft: '18px !important',
        paddingTop: '12px !important',
        justifyContent: 'space-between !important',
        alignItems: 'center !important',
        '&:hover, &:focus': {
            background: '##EBEFFF !important', 
        }
    },
    menuItemContainerMobile: {
        display: 'flex !important',
        paddingLeft: '18px !important',
        paddingTop: '12px !important',
        left: '8px !important',
        justifyContent: 'space-between !important',
        alignItems: 'center !important',
        '&:hover, &:focus': {
            background: '##EBEFFF !important', 
        }
    },
    checkIcon: {
        color: '#1FCC5A !important',
        fontSize: '18px !important',
    },
    customValidationErrors: {
        position: 'absolute',
        top: '-26px',
        right: '8px',
        fontSize: '0.65rem !important'
    },
    customFormErrorsContainer: {
        position: 'relative',
    },
    customFormErrors: {
        color: '#d32f2f !important',
    },
    manageConsent: {
        color: '#707070',
        fontSize: '14px',
        lineHeight: '17px',
        marginLeft: '6px'
    },
    manageConsentLink: {
        cursor: 'pointer'
    },
    manageConsentError: {
        color: '#FF221A',
        fontSize: '14px !important',
        lineHeight: '17px',
        marginLeft: '6px',
    },
    manageConsentContainerStart: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
    }, 
    manageConsentContainerCenter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    notifierButtons: {
        width: '126px !important',
        height: '36px !important',
    },
    notifierButtonsMobile: {
        width: '96px !important',
        height: '24px !important',
        fontSize: '12px !important'
    },
    dividersSmall: {
        marginTop: '6px',
        marginBottom: '6px'
    },
    uploaderComponentButton: {
        height: '48px !important',
        borderRadius: '30px !important',
        padding: '10px 20px !important',
        textTransform: 'none',
        fontSize: '14px !important',
        backgroundColor: '#FFFFFF !important',
        color: '#0033FF !important',
        border: '1px solid #0033FF !important',
        '&:hover': { 
            backgroundColor: '#0033FF !important',
            color: '#FFFFFF !important',
        }
    },
    circularProgressContainer: {
        zIndex: '1200 !important',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.3)'
    },
    backdropContainer: {
        zIndex: '1200 !important',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(10px)',
        transition: 'width 0.7s, height 0.7s, opacity 0.7s',
        backgroundSize: '20px 20px',
    },
    additionalBenefitsContainer: {
        display: 'flex',
    },
    bodyMegaphoneContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    bodyMegaphone: {
        width: '180px',
        height: '180px',
    },
    checkAdditionalBenefits: {
        padding: '0px !important'
    },
    benefitsText: {
        color: '#636366',
    },
    benefitsTextSmall: {
        color: '#636366',
        fontSize: '12px',
    },
    bodyLinks: {
        fontSize: '14px',
        color: '#0033FF',
        lineSpacing: '12px',
        display: 'flex',
        alignItems: 'center'
    },
    arrowIcon: {
        fontSize: '15px !important'
    },
    myAddContainers: {
        marginTop: '18px',
        marginBottom: '6px'
    },
    myAddDetails: {
        display: 'flex'
    },
    explanationIcons: {
        color: '#001A80',
        fontSize: '16px !important',
        paddingLeft: '12px',
        paddingRight: '6px',
        '&:first-child': {
            paddingLeft: '0px'
        }
    },
    addDetailsContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    detailsText: {
        display: 'flex',
        color: '#636366',
        fontSize: '14px',
    },
    addDescriptionDetails: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#1C1C1E'
    },
    applyTextField: {
        width: '100% !important',
        paddingTop: '6px !important'
    },
    toggleButtonsContainer: {
        marginTop: '24px !important',
        marginBottom: '24px !important',
    },
    profileImageInitialsContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    imageInitialsUploaderContainer: {
        //marginTop: '60px'
    },
    profileInitialsLarge: {
        display: 'flex', 
        justifyContent: 'center',
        width: '180px',
        height: '180px',
        borderRadius: '180px',
        backgroundColor: '#001A80',
        marginBottom: '24px'
    },
    initialsHolderLarge: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        fontSize: '60px',
        fontWeight: 'bold',
        color: '#EBEFFF'
    },
    styledInputDate: {
        '& .MuiFormHelperText-root': {
            position: 'absolute',
            top: '-10px',
            right: '0',
            transform: 'translateY(-100%)',
            fontSize: '0.65rem !important'
        },
        width: '320px',
        borderRadius: '6px',
        '& .MuiFormLabel-root': {
            fontWeight: '600',
        },
        '& .MuiOutlinedInput-input': {
            fontWeight: '600',
            color: '#636366',
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -14px) scale(0.75) !important'
        },
        '& .MuiInputBase-root': {
            color: '#1C1C1E',
            fontWeight: 'bold',
        }
    },
    avatarRatingsContainer: {
        paddingTop: '24px',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        '&:active, &:focus': {
            outline: 'none'
        }
    },
    avatarsHolderContainer: {
        width: '86px !important',
        height: '86px !important',
        position: 'relative',
        //overflow: 'hidden',
        cursor: 'pointer'
    },
    avatarsHolderContainerMedium: {
        width: '60px !important',
        height: '60px !important',
        position: 'relative',
        //overflow: 'hidden',
        cursor: 'pointer'
    },
    avatarsHolderContainerExtraSmall: {
        width: '24px',
        height: '24px',
        borderRadius: '24px',
        position: 'relative',
        backgroundColor: '#001A80',
        //overflow: 'hidden'
    },
    avatarsHolderContainerLarge: {
        width: '180px !important',
        height: '180px !important',
        position: 'relative',
        //overflow: 'hidden',
        cursor: 'pointer'
    },
    UserDetailsRatings: {
        display: 'flex',
        position: 'absolute',
        bottom: '6px',
        left: '70%',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 6px 12px #0033FF33',
        borderRadius: '24px',
        padding: '4px 12px'
    },
    userDetailsRRatingsImage: {
        display: 'flex',
        position: 'absolute',
        bottom: '6px',
        left: '55%',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 6px 12px #0033FF33',
        borderRadius: '24px',
        padding: '4px 12px'
    },
    UserDetailsRatingsLarge: {
        display: 'flex',
        position: 'absolute',
        bottom: '12px',
        left: '70%',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 6px 12px #0033FF33',
        borderRadius: '24px',
        padding: '8px 18px'
    },
    ratingsTextSmall: {
        color: '#636366',
        fontSize: '12px',
        marginBottom: '12px'
    },
    //UTILS
    w100pi: {
        width: '100% !important'
    },
    w50pi: {
        width: '50% !important'
    },
    w90pi: {
        width: '90% !important'
    },
    p0i: {
        padding: '0px !important'
    },
    pl6: {
        paddingLeft: '6px'
    },
    pr6: {
        paddingRight: '6px'
    },
    pl12: {
        paddingLeft: '12px'
    },
    pr12: {
        paddingRight: '12px'
    },
    pSides0: {
        paddingLeft: '0px',
        paddingRight: '0px'
    },
    p8: {
        padding: '8px'
    },
    p16: {
        padding: '16px'
    },
    pt12: {
        paddingTop: '12px'
    },
    pt3: {
        paddingTop: '3px'
    },
    pt4: {
        paddingTop: '4px'
    },
    pt15: {
        paddingTop: '15px'
    },
    pb12: {
        paddingBottom: '12px'
    },
    pb28: {
        paddingBottom: '28px'
    },
    pt84: {
        paddingTop: '84px'
    },
    pb84: {
        paddingBottom: '84px'
    },
    dne: {
        display: 'none'
    },
    dflexI: {
        display: 'flex !important'
    },
    dflex: {
        display: 'flex'
    },
    jContenntCenterI: {
        justifyContent: 'center !important'
    },
    mt12: {
        marginTop: '12px !important'
    },
    mb12: {
        marginBottom: '12px'
    },
    mb6: {
        marginBottom: '6px'
    },
    mb20: {
        marginBottom: '20px'
    },
    mt24: {
        marginTop: '24px'
    },
    mt36: {
        marginTop: '36px'
    },
    mb24: {
        marginBottom: '24px'
    },
    ml12: {
        marginLeft: '12px !important'
    },
    mr12: {
        marginRight: '12px !important'
    },
    ml18: {
        marginLeft: '18px !important'
    },
    mr18: {
        marginRight: '18px !important'
    },
    mb12i: {
        marginBottom: '12px !important'
    },
    ml28: {
        marginLeft: '28px'
    },
    posAbsolute: {
        position: 'absolute'
    },
    fcolorLInki: {
        color: '#0033FF !important',
        cursor: 'pointer'
    },
    fs16i: {
        fontSize: '16px !important'
    },
    fs18i: {
        fontSize: '18px !important'
    },
    horizontalSpacing: {
        paddingLeft: '12px',
        paddingRight: '12px'
    },
    pEventsNone: {
        pointerEvents: 'none'
    },
    bRadius50: {
        borderRadius: '50%'
    },
    zIndexHighest: {
        zIndex: '1200 !important'
    },
    fwbi: {
        fontWeight: 'bold !important'
    },
    cpr: {
        cursor: 'pointer'
    }
}));

export default useFormStyles