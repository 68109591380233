import Grid from '@mui/material/Grid/Grid';
import Typography from "@mui/material/Typography/Typography";
import  useStyles  from './landingBody.module';
import BODY_ITEMS from '../../../assets/bodyItems/index'
import mobileScreens from '../../../assets/screens.png'
import employerBenefits from '../../../assets/media/poslodavac.png'
import candidateBenefits from '../../../assets/media/radnik.png'
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useEffect, useState } from 'react';
import { 
    handleNavigate, 
    searchParamsCroatia, 
    searchParamsKopaonik, 
    searchParamsMontenegro, 
    searchParamsZlatibor, 
    useMediaQueries 
} from '../../../utils/global-utils'
import { useNavigate } from 'react-router-dom';
import useFormStyles from '../../styledComponents/globalFormStying.module';
import CardsSwipeable from '../../styledComponents/cardsSwipeable/cardsSwipeable';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from "@material-ui/core"
import Cookies from "js-cookie";
import SOCIAL_MEDIA_ICONS from "../../../assets/media";
import LandingHeader from '../../landingHeader/landingHeader';

function LandingBody() {

    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const [displayCookiesNotificationModal, setDisplayCookiesNotificationModal] = useState(false);

    useEffect(() => {
        const hasAcceptedCookies = Cookies.get('acceptedCookies');
        if (!hasAcceptedCookies) {
            setDisplayCookiesNotificationModal(true);
        }
    }, []);

    const handleCloseCookiesModal = () => {
        setDisplayCookiesNotificationModal(false);
        Cookies.set('acceptedCookies', 'true', { expires: 365 });
    }   

    const handleNavigateToTermsAndConditions = () => {
        handleNavigate(navigate,  `/terms-and-conditions-general` );
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleNavigateToPrivacyPolicy = () => {
        handleNavigate(navigate, '/privacy-policy-general');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleNavigateToAdsZlatibor = () => {
        handleNavigate(navigate, `/ads/${searchParamsZlatibor}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleNavigateToAdsKopaonik = () => {
        handleNavigate(navigate, `/ads/${searchParamsKopaonik}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleNavigateToAdsCroatia = () => {
        handleNavigate(navigate, `/ads/${searchParamsCroatia}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleNavigateToAdsMontenegro = () => {
        handleNavigate(navigate, `/ads/${searchParamsMontenegro}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
    return (
        <>
        <div>
            {displayCookiesNotificationModal && (
                <Dialog
                    className={classes.cookiesConsentDialog}
                    open={displayCookiesNotificationModal}
                    aria-describedby="cookies-consent-dialog"
                >
                    <DialogContent>
                        <DialogContentText id="cookies-consent-dialog">
                            <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                <span>
                                    Ova veb stranica koristi funkcionalne kolačiće kako bi Vam pružila bolje korisničko iskustvo.
                                </span>
                            </div>
                            <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                <span>
                                    Molimo Vas da pročitate našu <strong>politiku privatnosti</strong> za detaljne informacije o upotrebi kolačića na našoj veb stranici.
                                </span>
                            </div>
                            <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                <span>
                                    Kolačići će biti aktuelni tokom određenog vremena, nakon čega će biti ponovo zatražen Vaš pristanak.
                                </span>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleCloseCookiesModal()}>
                            Slažem se
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <div className={`${isMobile ? classes.carouselContainerMobile : classes.carouselContainer}`}>
                <img 
                    src={`${BODY_ITEMS.BEACH}`} 
                    width={`${'100%'}`}
                    className={`${isMobile ? classes.carouselImagesMobile : classes.carouselImages}`}
                />
                <div className={`${isMobile ? classes.overlayMobile : classes.overlay} ${isTablet ? classes.overlayTablet : ' '}`}>
                    <Grid container className={isMobile ? classes.gridContainerMobile : classes.gridContainer}>
                        <Grid item xs={12} sm={12} md={12}>
                            <LandingHeader />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.primaryTextContainer}>
                                <span className={isMobile ? classes.primaryTextMobile : classes.primaryText}>
                                    Digitalna berza <br />
                                    sezonskih poslova
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.primaryTextContainer}>
                                <span className={classes.secondaryText}>
                                    Najpopularniji oglasi
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <CardsSwipeable />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div onClick={handleNavigateToAdsZlatibor} className={`${formClasses.mt36} ${classes.primaryTextContainer}`}>
                                <span className={formClasses.headerMenuItems}>
                                    Oglasi Zlatibor
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div onClick={handleNavigateToAdsKopaonik} className={classes.primaryTextContainer}>
                                <span className={formClasses.headerMenuItems}>
                                    Oglasi Kopaonik
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div onClick={handleNavigateToAdsCroatia} className={classes.primaryTextContainer}>
                                <span className={formClasses.headerMenuItems}>
                                    Oglasi Hrvatska
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div onClick={handleNavigateToAdsMontenegro} className={classes.primaryTextContainer}>
                                <span className={formClasses.headerMenuItems}>
                                    Oglasi Crna Gora
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
        <div className={`${isMobile ? classes.middleSectionMobile : classes.middleSection} ${isTablet ? classes.middleSectionTablet : ' '}`} id="what-is-sezonal">
            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    <div className={`${isMobile ? classes.contentContainerMobile : classes.contentContainer} ${isTablet ? classes.contentContainerTablet : ' '}`}>
                        <Typography className={`${isMobile ? classes.middleHeadlineMobile : classes.middleHeadline} ${isMobile ? formClasses.mb24 : classes.headlineBottomLarge}`}>
                            Šta je Sezonal?
                        </Typography>
                        <Typography className={`${classes.middleText} ${classes.mtb12}`}>
                            Sezonal je inovativna digitalna platforma koja omogućava efikasno povezivanje kandidata i poslodavaca u domenu sezonskog zapošljavanja. <br />
                        </Typography>
                        <Typography className={`${classes.middleText} ${classes.mtb12}`}>
                            Bez obzira da li ste kandidat u potrazi za idealnim sezonskim poslom ili poslodavac koji traži kvalifikovane radnike,
                            naša platforma vam pruža jednostavan način da se povežete i ostvarite uspešnu saradnju.< br/>
                        </Typography>
                        <Typography className={`${classes.middleText} ${classes.mtb12}`}>
                            Kandidati mogu uživati u nizu benefita, uključujući pristup sezonskim poslovima na jednom mestu, onlajn.
                            Dodatno, pružamo sigurnije zapošljavanje uz mogućnost pregleda i objavljivanja preporuka i utisaka o poslodavcima, 
                            uključujući ocene, recenzije i uslove rada.<br />
                        </Typography>
                        <Typography className={`${classes.middleText} ${classes.mtb12}`}>
                            Kada je reč o beneficijama za poslodavce, 
                            naša platforma omogućava brzo pronalaženje kvalifikovane radne snage.
                            Efikasno zapošljavanje kroz jednostavno oglašavanje, prijem prijava i pregled preporuka.
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div className={`${isMobile ? classes.mobileScreensContainerSmall : classes.mobileScreensContainer} ${isTablet ? classes.pl0i : ' '}`}>
                        <img alt="Aplikacija" src={`${mobileScreens}`} className={classes.mobileScreens}/>
                    </div>
                </Grid>
               
            </Grid>
            <Grid container className={classes.benefitsContainer}>
                <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.benefitsPlaceholders}>
                        <img alt="employer-benefits" src={`${employerBenefits}`} className={classes.benefitsBanners} />
                    </div>
                    <div className={classes.benefitsHeadline}>
                        Pogodnosti poslodavca
                    </div>
                    <div className={classes.benefitsPlaceholders}>
                        <ul className={classes.benefitsList}>
                            <li>Oglašavanje na jednom mestu, onlajn</li>
                            <li>Oglasi za usko specijalizovanu grupu kandidata</li>
                            <li>Mogućnost upravljanja oglasa</li>
                            <li>Pregled preporuka</li>
                            <li>Brzo i efikasno zapošljavanje</li>
                        </ul>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.benefitsPlaceholders}>
                        <img alt="candidate-benefits" src={`${candidateBenefits}`} className={classes.benefitsBanners} />
                    </div>
                    <div className={classes.benefitsHeadline}>
                        Pogodnosti kandidata
                    </div>
                    <div className={classes.benefitsPlaceholders}>
                        <ul className={classes.benefitsList}>
                            <li>Centralizovana ponuda i potražnja</li>
                            <li>Napredni filteri za precizno pretraživanje</li>
                            <li>Pregled preporuka i komentara za brže odluke</li>
                            <li>Veći izbor poslova</li>
                            <li>Brže i sigurnije zapošljavanje uz moderni dizajn</li>
                        </ul>
                    </div>
                </Grid>
            </Grid>
        </div>
        <div className={`${isMobile ? classes.footerSectionMobile : classes.footerSection}`}>
            <div className={classes.p32}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className={classes.copyrightContainer}>
                            <span className={classes.footerText}>info@sezonal.com</span>
                        </div>
                        <div className={classes.copyrightContainer}>
                            <span
                                onClick={() => handleNavigateToTermsAndConditions()} 
                                className={`${classes.footerText} ${formClasses.cpr}`}>
                                Uslovi korišćenja
                            </span>
                        </div>
                        <div className={classes.copyrightContainer}>
                            <span 
                                onClick={() => handleNavigateToPrivacyPolicy()}
                                className={`${classes.footerText} ${formClasses.cpr}`}>
                                Politika privatnosti
                            </span>
                        </div>
                        <div className={classes.copyrightContainer}>
                            <span className={`${classes.footerText}`}>
                                2023 Sezonal <CopyrightIcon sx={{ fontSize: '14px' }}/> RasTech d.o.o. Sva prava zadržana.
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} display="flex" justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
                        <div className={classes.copyrightContainer}>
                            <div className={`${formClasses.mr12}`}>
                                <a href='https://www.instagram.com/sezonal_com/' target='_blank'>
                                    <img width="24" height="24" src={`${SOCIAL_MEDIA_ICONS.instagram}`}/>
                                </a>
                                
                            </div>
                            <div className={`${formClasses.mr12} ${formClasses.ml12}`}>
                                <a href='https://www.facebook.com/sezonalcom' target='_blank'>
                                    <img width="24" height="24" src={`${SOCIAL_MEDIA_ICONS.facebook}`}/>
                                </a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
        </>
    )

}

export default LandingBody
