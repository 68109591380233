import axios from "axios";
import { APP_AUTH } from '../environment';

const REGISTER_USER = '/User/createUser'
const REGISTER_FINAL_STEP_EMPLOYEE = '/User/createUserSecondStepEmloyee'
const REGISTER_FINAL_STEP_EMPLOYER = '/User/createUserSecondStepEmloyer'
const RESEND_VERIFICATION_LINK = '/User/resendVerificationLink'
const FORGOT_PASSWORD = '/User/forgot-password'
const RESET_PASSWORD = '/User/reset-password'

const api = axios.create({
    baseURL: APP_AUTH
})

const apiPost = (
    operation: string, 
    data: object) => {
    return new Promise((resolve, reject) => {
        api.post(operation, data).then((response) => {
            const userId = response;
            resolve(userId)
        }).catch((error) => {
            reject(error)
        })
    })
}

const RegisterUser = async (data: object) => {
    
    return apiPost(REGISTER_USER, data)
}

const RegisterFinalStepEmployee = async (data: object) => {
    
    return apiPost(REGISTER_FINAL_STEP_EMPLOYEE, data);
};

const RegisterFinalStepEmployer = async (data: object) => {
    
    return apiPost(REGISTER_FINAL_STEP_EMPLOYER, data);
}

const ResendVerificationLink = async (data: object) => {

    return apiPost(RESEND_VERIFICATION_LINK, data);
}

const ForgotPassword = async (data: object) => {

    return apiPost(FORGOT_PASSWORD, data);
}

const ResetPassword = async (data: object) => {

    return apiPost(RESET_PASSWORD, data)
}

const RegisterUserApi = {
    RegisterUser,
    RegisterFinalStepEmployee,
    RegisterFinalStepEmployer,
    ResendVerificationLink,
    ForgotPassword,
    ResetPassword
}

export default RegisterUserApi