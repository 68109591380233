const termsAndConditionsText = [
    {
        content: `Ovim Uslovima korišćenja uređuju se prava i obaveze u vezi sa korišćenjem Sezonal platforme. Sezonal platforma se nalazi na internet stranici www.sezonal.com (u nastavku: Platforma) 
        i predstavlja uslugu informacionog društva koju pruža RasTech d.o.o. Sezonal omogućava korišćenje Platforme, na način i pod uslovima opisanim u ovim Uslovima korišćenja.`
    },
    {
        content: `Ovi Uslovi korišćenja čine sastavni deo Platforme i predstavljaju odredbe ugovora koji se zaključuje između: 
        1. RasTech d.o.o. (u daljem tekstu Kompanija) i 2. Svakog pojedinačnog korisnika Sezonal platforme (u daljem tekstu Korisnik).`
    },
    {
        content:  `Dobrovoljnim korišćenjem bilo kojeg segmenta Platforme, korisnik potvrđuje da je upoznat i saglasan sa Uslovima korišćenja.`
    },
    {
        content: `1. OBAVEZE UGOVORNIH STRANA
        Kompanija se obavezuje da će korisnicima pružiti usluge definisane ovim Uslovima korišćenja, na način opisan u ovim Uslovima korišćenja, 
        a u skladu sa zakonima i propisima Republike Srbije.`
    },
    {
        content: `Pristupom i korišćenjem Platforme, korisnici pristaju na Uslove korišćenja, čime zaključuju ugovor po pristupu sa Kompanijom kao pružaocem usluge informacionog društva. 
        Na svaki pristup sadržajima Platforme, primenjuju se ovi Uslovi korišćenja. Korisnik se obavezuje da će prilikom korišćenja Platforme, poštovati ove Uslove korišćenja.`
    },
    {
        content: `Ugovorne strane su saglasne da je zarad ispunjenja navedenih obaveza neophodno da Kompanija prikuplja, 
        obrađuje i čuva lične podatke korisnika u skladu sa Politikom privatnosti i važećim zakonima i propisima Republike Srbije. 
        Prihvatanjem ovih Uslova korišćenja, svaki korisnik potvrđuje da je upoznat i saglasan sa Politikom privatnosti.`
    },
    {
        content: `2. OPIS PLATFORME I USLUGE
        Kompanija putem Platforme pruža uslugu informacionog društva. 
        Platforma omogućava zaposlenima u kompanijama/gazdinstvima da ostave utisak o tome kako zaista izgleda rad u njihovoj firmi, kompanijama da oglase slobodne pozicije u vidu oglasa
        i da pronađu kandidata koji odgovara njihovim zahtevima,
        kandidatima da pronađu posao koji im najviše odgovara. Platforma je namenjena razmeni iskustava vezano za uslove rada i proces zapošljavanja industriji sezonskog zapošljavanja. 
        Sadržaj Platforme sastoji se od vlastitih sadržaja, sadržaja kreiranih od strane korisnika, sadržaja partnera i oglašivača i linkova ka drugim internet stranicama.`
    },
    {
        content: `Poslovanje Kompanije putem ove Platforme regulisano je prevashodno Zakonom o elektronskoj trgovini, 
        Zakonom o obligacionim odnosima, Zakonom o autorskom i srodnim pravima, Zakonom o žigovima, 
        Zakonom o zaštiti podataka o ličnosti, kao i drugim propisima Republike Srbije. 
        Platforma je informativnog karaktetra i ne predstavlja uslugu posredovanja u zapošljavanju, niti Kompanija pruža ili obezbeđuje bilo kakve druge usluge na Platformi, 
        osim usluge informacionog društva.`
    },
    {
        content: `Platforma sadrži javno dostupne podatke o kompanijama, kao i o kompanijama koje zapošljavaju sezonske i privremene radnike. 
        Pod javno dostupnim podacima podrazumevaju se podaci dostupni od strane zvaničnih državnih institucija i podaci koje kompanije objavljuju na svojim 
        internet prezentacijama i društvenim mrežama. 
        Platforma sadrži linkove ka drugim internet stranicama, 
        pri čemu Kompanija ne odgovara za sadržaj internet stranica trećih strana i ne garantuje i ne preuzima odgovornost za eventualan štetan ili ilegalan sadržaj. 
        Korišćenje Platforme je besplatno za korisnike fizička lica.`
    },
    {
        content:  `Kompanija prenosi elektronske poruke koje su joj predate i kreirane od strane korisnika Platforme, ali ni na koji način ne inicira njihov prenos, 
        ne vrši odabir podataka ili dokumenata koji se prenose, ne izuzima ili menja njihov sadržaj, niti vrši odabir primaoca prenosa. 
        Kompanija zadržava pravo izmene i ukidanja bilo kog sadržaja, elementa ili usluge Platforme, bez obaveze bilo kakvog prethodnog odobrenja ili obaveštenja.`
    },
    {
        content:  `Kompanija ni na koji način ne utiče na sadržaj koji postavljaju korisnici, kao i na sadržaj koji je prikupljen iz javno dostupnih izvora. 
        Kompanija ne garantuje za tačnost informacija koje su prezentovane kroz dostupan sadržaj. 
        Isključivu odgovornost za sadržaj imaju treća lica koja su sadržaj postavila odnosno od kojih je preuzet. 
        Kompanija ima pravo, da bilo koji sadržaj, u bilo kom trenutku ukloni sa Platforme, bez obaveze prethodnog ili naknadnog obaveštenja ili obrazloženja.`
    },
    {
        content: `3. KORISNICI I REGISTRACIJA
        Pod korisnicima usluga Platforme smatraju se: 1. Posetioci; 2. Registrovni korisnici (fizička lica ili kompanije). 
        Posetilac je osoba koja pristupa Platformi bez prijave i registracije. 
        Registrovani korisnici su fizička lica i kompanije koje se registruju i prijavljuju na Platformi. 
        Korišćenje Platforme za posetioce i registrovane korisnike koji su fizička lica je besplatno.
        `
    },
    {
        content: `Za korišćenje Platforme korisnici moraju imati 15 ili više godina. Ukoliko su osobe maloletne, Platformu mogu koristiti uz nadzor roditelja ili staratelja. 
        Kompanijske naloge mogu koristiti korisnici koji su ovlašćeni od strane njihovih kompanija da koriste korisnički nalog u poslovne svrhe.`
    },
    {
        content: `Prilikom registracije korisnici registruju svoju email adresu koja predstavlja korisničko ime, kreiraju lozinku i prihvataju ove Uslove korišćenja.
        Korisnik je odgovoran za tajnost i čuvanje lozinke. Korisnik može otvoriti samo jedan korisnički nalog, osim ako mu Kompanija ne dozvoli drugačije. 
        Prilikom korišćenja Platforme, Kompanija može prikupljati i druge podatke (IP adresa, vreme pristupa, vrsta uređaja, lokacija, itd.) sa ciljem poboljšanja korisničkog iskustva, sprečavanja zloupotreba ili analize.`
    },
    {
        content: `Ukoliko korisnik proceni da je došlo do zloupotrebe i neovlašćenog korišćenja korisničkog naloga, u obavezi je da o tome obavesti Kompaniju. 
        Svaki registrovani korisnik ima pravo da u bilo kojem trenutku obavesti kompaniju o želji da deaktivira svoj korisnički nalog, bez potrebe za pružanjem obrazloženja ili prethodne najave.
        Kompanija će biti u obavezi da se korisnički nalog, kao i svi podaci vezani za taj nalog, trajno uklone sa Platforme.`
    },
    {
        content: `4. KREIRANJE SADRŽAJA OD STRANE KORISNIKA
        Registrovani korisnici mogu ostavljati utiske u vidu komentara, plate i benefita i intervjua, kao i kreirati druge sadržaje. 
        Kompanija ni na koji način ne utiče na sadržaj ostavljenog utiska, ne filtrira sadržaj niti dodaje ili oduzima bilo koji deo teksta, 
        bez obzira da li su utisci pozitivni ili negativni. Kompanija ne menja originalnost i autentičnost utiska, ali zadržava pravo da radi održavanja kvaliteta usluge izvrši pravopisne i gramatičke korekcije.`
    },
    {
        content: `Svi sadržaji i informacije koje ostavljaju korisnici na Platformi moraju biti istiniti i tačni. 
        Odgovornost za istinitost i tačnost unetih sadržaja imaju isključivo korisnici koji su isti sadržaj i uneli.`
    },
    {
        content: `Pravila za ostavljanje utisaka korisnika:`
    },
    {
        content: `Utisak treba da predstavlja istinito i realno stanje o radu u Kompaniji, na osnovu ličnog doživljaja, stava i mišljenja korisnika. Nije dozvoljeno iznošenje neistinitih informacija, 
        bilo koji oblik vređanja na rasnoj, verskoj ili drugoj osnovi. Na platformi nije dozvoljena upotreba psovki.
        Kompanija zadržava pravo na nebjavljivanje ili uklanjanje ovakvih sadržaja, bez prethodnog ili naknadnog obaveštenja ili obrazloženja.
        Svaki utisak treba da bude autentičan. Nije dozvoljeno kopiranje teksta od drugih korisnika ili iz trećih izvora.
        Nije dozvoljeno objavljivanje poverljivih poslovnih informacija kao sto su finansijski podaci, podaci o klijentima, lične informacije o zaposlenima, itd.`
    },
    {
        content: `Nije dozvoljeno vređanje, korišćenje vulgarnog rečnika i svaki vid diskriminacije.
        Nije dozvoljeno korišćenje velikih slova i nepotrebnih uzvičnika. 
        Preporučljivo je korišćenje gramatički ispravnih izraza i stila pisanja, kako bi se obezbedila bolja preglednost utiska za korisnike.
        Nije dozvoljeno navođenje tuđeg imena, kao ni bilo kojih drugih informacija koje bi mogle da ugroze identitet osoba.
        Nije dozvoljeno ostavljanje utiska koje nisu u vezi sa radom u kompaniji i poslodavcem.
        Za istog poslodavca je dozvoljeno ostavljanje nove vrste utiska (komentar, plata i benefit ili intervju proces) jednom u 6 meseci.`
    },
    {
        content: `Pravila za odgovor kompanije na utisak ili ostavljanje utiska o kandidatu:
        Dozvoljeno je navođenje linkova ili citata koji su u vezi sa napisanim utiskom korisnika.
        Nije dozvoljeno pretiti korisniku tužbom, pravnim posledicama ili bilo kojom drugom kaznom, zbog iznošenja svog ličnog stava ili mišljenja.
        Nije dozvoljeno vređanje, korišćenje vulgarnog rečnika i svaki vid diskriminacije.
        Nije dozvoljeno korišćenje velikih slova i nepotrebnih uzvičnika. Preporučljivo je korišćenje gramatički ispravnih izraza i stila pisanja, kako bi se obezbedila bolja preglednost utiska za korisnike.
        Za jedan utisak je moguće ostaviti samo jedan odgovor, a korisnicima nije omogućeno da odgovore na odgovor.
        Registrovani korisnik bezuslovno i neopozivo ovlašćuje Kompaniju da sadržaj koji je postavio na Platformi prenese neodređenom broju lica, kao i da sadržaj ukloni bez prethodnog ili naknadnog obaveštenja ili obrazloženja.`
    },
    {
        content: `Registrovani korisnici zadržavaju sva autorska i srodna prava na sadržaj koji kreiraju, pri čemu garantuju da su vlasnici svih neophodnih autorskih prava. 
        Korisnik lično odgovara za svako eventualno kršenje autorskog ili srodnog prava, kao i drugih prava intelektualne svojine. 
        Kompanija će pružiti podršku korisnicima ili trećim licima u vezi zaštite prava ličnosti, privatnosti, svojinskih prava i prava intelektualne svojine svim licima koji su nosioci tih prava, 
        kao i onim licima čije je pravo povređeno, na osnovu pisanog zahteva i odgovarajuće dokumentacije, a sve u skladu sa propisima Republike Srbije.`
    },
    {
        content: `Registrovani korisnik može obrisati utisak i sadržaj koji je kreirao na Platformi, 
        bez obaveze ikakvog obaveštenja i obrazloženja. 
        Svako korišćenje Platforme koje je u suprotnosti sa ovim Uslovima korišćenja, smatra se kršenjem Uslova korišćenja i zloupotrebom usluga koja pruža Kompanija.`
    },
    {
        content: `5. NEDOZVOLJENE AKTIVNOSTI
        Kompanija ima pravo da nadgleda i prati sve korisničke aktivnosti na Platformi. 
        Ukoliko primeti bilo kakvu nedozvoljenu aktivnost ili kršenje ovih Uslova korišćenja, može preduzeti sve tehničke ili pravne radnje koje smatra odgovarajućim.`
    },
    {
        content: `Pored prethodno navedenih, nedozvoljene aktivnosti na Platformi su:`
    },
    {
        content: `Aktivnosti traženja ličnih podataka, informacija ili lozinki radi lične identifikacije posetioca i korisnika.
        Aktivnosti prikupljanja ili čuvanja podataka i informacija o drugim korisnicima bez njihove saglasnosti.
        Zloupotreba pristupnih podataka drugih korisnika.
        Korišćenje i objavljivanje fotografija ili video zapisa druge osobe bez njene saglasnosti, kao i aktivnosti koje krše privatnost drugih osoba.
        Korišćenje SPAM poruka i neželjene pošte.
        Aktivnosti komercijalne prirode bez saglasnosti Kompanije.
        Aktivnosti koje su kriminalne prirode ili promovišu takve aktivnosti.
        Zloupotreba sadržaja koji je zaštićen autorskim ili drugim pravima.
        Aktivnosti koje uključuju korišćenje virusa, crva, botova, štetnog softvera ili drugih tehnika i alata koje mogu negativno uticati na funkcionisanje Platforme.`
    },
    {
        content: `6. AUTORSKA PRAVA
        Kompanija ima isključivo autorsko pravo i prava intelektualne svojine na Platformi, 
        uključujući sve pojedine elemente: tekstualni, audio i video sadržaji, vizuelni identitet, programski kod, 
        baze podataka i drugi elementi Platforme gde je autor Kompanija. 
        Svako neovlašćeno korišćenje Platforme ili bilo kog njenog elementa bez pisane saglasnosti Kompanije kao nosioca isključivih autorskih prava, 
        smatra se povredom autorskih prava Kompanije.`
    },
    {
        content: `Platforma može sadržati i elemente na kojima isključiva autorska, žigovna i druga prava intelektualne svojine imaju druga lica, 
        koja imaju isključivu odgovornost za sadržaj na kojem su nosioci tih prava, 
        bez obzira na to što se sadržaj nalazi na Platformi Kompanije. Kompanija ima isključivu odgovornost samo za sadržaj na kojem ima autorska prava.`
    },
    {
        content: `Korisnik je saglasan da postavljanjem sadržaja na Platformi on postaje vidljiv i dostupan posetiocima i drugim korisnicima u skladu sa ovim Uslovima korišćenja. 
        Korisnik samostalno snosi odgovornost za sadržaj koji je njegovo autorsko delo, odnosno za sadržaj koji je postavljen na Platformi.`
    },
    {
        content: `7. OGRANIČENJE OD ODGOVORNOSTI I REKLAMACIJE
        Korisnici koriste Platformu na svoju ličnu odgovornost. 
        Korisnik prihvata da Kompanija nije odgovorna za aktivnosti drugih korisnika ili trećih lica, čime rizik od potencijalne štete snose ta lica.`
    },
    {
        content: `Svi sadržaji i informacije koje ostavljaju korisnici na Platformi moraju biti istiniti i tačni. 
        Odgovornost za istinitost i tačnost unetih sadržaja imaju isključivo korisnici koji su isti sadržaj i uneli. 
        Kompanija ne garantuje za istinitost i tačnost sadržaja koje su kreirali korisnici.`
    },
    {
        content: `Ova odredba o odgovornosti odnosi se na svaku štetu koja bi mogla nastati kao posledica grešaka i nedostataka, prekida rada, kvara na sistemu, neovlašćenog pristupa podacima, 
        aktivnostima koje su u suprotnosti sa ovim Uslovima korišćenja, itd. Kao posledica više sile, tehničkih problema ili održavanja sistema, 
        Platforma može biti privremeno nedostupna ili dostupna u ograničenom obimu.`
    },
    {
        content: `Kompanija pruža uslugu informacionog društva, tako da na izvršenu usluge Kompanije nije moguće izvršiti reklamaciju. 
        Usluga informacionog društva koju Kompanija pruža je u potpunosti izvršena jer je pružanje usluge počelo nakon izričite prethodne saglasnosti korisnika da uslugu koristi.`
    },
    {
        content: `8. ZAVRŠNE ODREDBE
        Na sve što nije regulisano ovim Uslovima korišćenja primenjuju se važeći propisi Republike Srbije. 
        U slučaju eventualnog spora, Kompanija i korisnik se obavezuju da spor pokušaju da reše mirnim putem, a ukoliko u tome ne uspeju spor mogu rešavati sudskim putem u nadležnom sudu u Kragujevcu.`
    },
    {
        content: `9. PLAĆANJE CENE USLUGA I POVRAT UPLAĆENIH SREDSTAVA
        Uslovi korišćenja se odnose na proces plaćanja cena usluga i povrat uplaćenih sredstava, 
        posebno u vezi s registrovanim pravnim licima koja koriste usluge Sezonal platforme. 
        Pravna lica su dužna platiti cenu u skladu s važećim Cenovnikom prilikom kupovine usluga. 
        Proces korišćenja usluga uključuje korake koje poslodavac preduzima kako bi objavio oglas. 
        Poslodavac ima mogućnost kreiranja oglasa i odabira vrste oglasa koji želi objaviti. 
        Kada se odluči za objavu oglasa, nakon uspešnog uredjivanja oglasa, na njegovu e-mail adresu stići će predračun o usluzi objave oglasa. 
        Predračun će sadržavati sve neophodne informacije o Kompaniji, kao i poziv na broj koji je potrebno popuniti prilikom izvršavanja plaćanja.
        Nakon što poslodavac izvrši uplatu sredstava, a kompanija Rastech primi uplatu, oglas koji je poslodavac uredio postaće vidljiv na sajtu Sezonal. 
        Eventualne promene u Cenovniku biće odražene na veb stranici Servisa, obezbeđujući ažuriranje informacija o cenama usluga.
        Ukoliko nije došlo do saradnje između Kompanije i Korisnika, a sredstva su uplaćena, 
        Kompanija će inicirati povraćaj naplaćenih sredstava kod svoje banke,
        ali ne može da utiče na rok povraćaja koji zavisi isključivo od banke vlasnika kartice kojom je oglas plaćen.`
    },
    {
        content: `Kompanija zadržava pravo izmene i dopune Uslova korišćenja, pri čemu će nova verzija biti objavljena na Platformi i može biti poslata registrovanim korisnicima na email.
        `
    },
    {
        content: `Ovi Uslovi korišćenja stupaju na snagu danom objavljivanja na Platformi.`
    },
]

export default termsAndConditionsText