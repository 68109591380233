import { useEffect, useState } from 'react';
import EmployerApi from '../../../services/employerApi'
import useFormStyles from "../../styledComponents/globalFormStying.module";
import { useAuthContext } from '../../../context/UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from "@mui/material/Grid/Grid"
import FunctionalHeader from "../../functionalHeader/functionalHeader";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { EmployerAddResponse, EmployerDetailsResponse, EmployerRatingsResponse } from '../../../utils/types.d';
import { getUserId, handleNavigate, useMediaQueries } from '../../../utils/global-utils';
import useStyles from './employerReadOnlyProfile.module';
import AddApi from '../../../services/addApi';
import AdsCard from '../adsCard/adsCard';
import RatingsApi from '../../../services/ratingsApi';
import UserRatings from '../userRatings/userRatings';
import { Controller, useForm } from 'react-hook-form';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import { StyledInput } from '../../styledComponents/styledInput/styledInput';
import StyledButton from '../../styledComponents/styledButton.module';
import StyledProfilePicture from '../../styledComponents/profileImage/profileImage';
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Alert from '@mui/material/Alert';

function EmployerReadOnlyProfile() {

    const classes = useStyles();
    const formClasses = useFormStyles();
    const { userData, accessToken } = useAuthContext();
    const { userId } = useParams();
    const employeeId = getUserId(userData);
    const employerId = userId;

    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();

    const [currentComponent, setCurrentComponent] = useState('details');

    const [fetchEmployerDetails, setFetchEmployerDetails] = useState<EmployerDetailsResponse | null>(null);
    const [fetchEmployerAdds, setFetchEmployerAdds] = useState<EmployerAddResponse[]>([]);
    const [fetchEmployerRatins, setFetchEmployerRatings] = useState<EmployerRatingsResponse[]>([])
    const [ratingValue, setRatingValue] = useState<number | null>(3);

    const [loading, setLoading] = useState(true);
    const [adsLoading, setAdsLoading] = useState(false);
    const [ratingsLoading, setRatingsLoading] = useState(false);
    const [isSwitched, setIsSwitched] = useState<boolean>(false);
    const [errorModal, setErrorModal] = useState(false);

    useEffect(() => {

        const fetchDetails = async () => {
          try {
            if (accessToken) {
                setLoading(true)
                const employerDetailsResponse = await EmployerApi.GetEmployerDetails(accessToken, employerId);
                setFetchEmployerDetails(employerDetailsResponse.data);
                setLoading(false)
                setIsSwitched(employerDetailsResponse.data?.isRegistered || false);
            }
          } catch (error) {
            //
          }
        };

        if (accessToken) {
          fetchDetails();
        }
    }, [accessToken]);

    useEffect(() => {

        const fetchAds = async () => {
          try {
            if (accessToken) {
                setAdsLoading(true);
                const employerAdsResponse = await AddApi.GetAllEmployerAds(accessToken, employerId);
                setFetchEmployerAdds(employerAdsResponse.data)
                setAdsLoading(false);
            }
          } catch (error) {
            //
          }
        };

        if (accessToken) {
            fetchAds();
        }
    }, [accessToken]);

    useEffect(() => {

        const fetchRatings = async () => {
          try {
            if (accessToken) {
                setRatingsLoading(true);
                const employerRatingsResponse = await RatingsApi.GetAllEmployerRatings(accessToken, employerId);
                setFetchEmployerRatings(employerRatingsResponse.data)
                setRatingsLoading(false);
            }
          } catch (error) {
            //
          }
        };

        if (accessToken) {
            fetchRatings();
        }
    }, [accessToken]);

    const { 
        handleSubmit,
        formState,
        control,
        reset,
        trigger,
        setValue } = useForm({
            mode: 'onChange',
            defaultValues: {
                employeeId: employeeId,
                employerId: employerId,
                rating: 3,
                comment: '',
            }
    });

    setValue('employeeId', employeeId);
    setValue('employerId', employerId);

    const handleComponentChange = (_event: any, newComponent: string) => {

        if (currentComponent === newComponent) {
            return;
        }

        setCurrentComponent(newComponent);
    };

    const onSubmit = async (formData: any) => {
        try {

            await RatingsApi.AddRatingForEmployer(formData, accessToken);

            reset({
                rating: 0,
                comment: '',
            })

        } catch (error) {
            setErrorModal(true)
            setValue('comment', 'Već ste ocenili korisnika')
        }
    }

    const handleButtonClick = async () => {
        try {
            await trigger();
            if (Object.keys(formState.errors).length === 0) {
                setLoading(true);
                await handleSubmit(onSubmit)();
                setLoading(false);
            }
        }
        catch (err) {
            //
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={0} sm={0} md={2}>
                    <div className={formClasses.bannersContainer}></div>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <FunctionalHeader />
                        </Grid>
                    </Grid>
                    <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                        <div className={formClasses.companyNameHeadline}>
                            <ArrowBackIcon 
                                className={formClasses.navigateBack}
                                onClick={() => navigate(-1)} /> Profil poslodavca
                        </div>
                    </div>
                    <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                        <Grid item xs={12} sm={12} md={9}>
                            <ToggleButtonGroup
                                className={formClasses.buttonsGroup}
                                value={currentComponent}
                                exclusive
                                onChange={handleComponentChange}
                                aria-label="component-switcher"
                            >
                                <ToggleButton
                                    className={formClasses.singleToggleButton}
                                    value="details" 
                                    aria-label="details-layout"
                                    disabled={currentComponent === 'details'}
                                        >
                                        Detalji
                                </ToggleButton>
                                <ToggleButton
                                    className={formClasses.singleToggleButton}
                                    value="ads" 
                                    aria-label="ads-layout"
                                    disabled={currentComponent === 'ads'}
                                        >
                                        Oglasi
                                </ToggleButton>
                                <ToggleButton
                                    className={formClasses.singleToggleButton}
                                    value="ratings" 
                                    aria-label="ratings-layout"
                                    disabled={currentComponent === 'ratings'}
                                        >
                                        Ocene
                                </ToggleButton>

                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    {currentComponent === 'details' ? (
                        <Grid container className={`${isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer} ${isMobile ? formClasses.toggleLayoutsContainerMobile : formClasses.toggleLayoutsContainer}`}>
                            {loading ? (
                                <>
                                    <Grid item xs={12} sm={12} md={6} className={formClasses.toggleButtonsContainer}>
                                        <Stack spacing={1}>
                                            <Skeleton variant="rectangular" width={300} height={10} />
                                            <Skeleton variant="circular" width={86} height={86} />
                                            <Skeleton variant="rectangular" width={300} height={80} />
                                            <Skeleton variant="rounded" width={300} height={80} />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} className={formClasses.toggleButtonsContainer}>
                                        <Stack spacing={1}>
                                            <Skeleton variant="rectangular" width={300} height={10} />
                                            <Skeleton variant="rectangular" width={300} height={10} />
                                            <Skeleton variant="rectangular" width={300} height={10} />
                                            <Skeleton variant="rectangular" width={300} height={10} />
                                            <Skeleton variant="rectangular" width={300} height={10} />
                                        </Stack>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12} sm={12} md={6} className={formClasses.toggleButtonsContainer}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <div className={isMobile ? classes.personalInfoContainerMobile : classes.personalInfoContainer}>
                                                    {fetchEmployerDetails?.profileImagePath ? (
                                                        <div className={`${formClasses.avatarRatingsContainer}`}>
                                                            <div className={formClasses.avatarsHolderContainer}>
                                                                <StyledProfilePicture
                                                                    style={{ pointerEvents: 'none' }}
                                                                    width={86}
                                                                    height={86}
                                                                    alt={`profile-image-${fetchEmployerDetails?.companyName[0]}`}
                                                                    imageData={fetchEmployerDetails?.profileImagePath as string}
                                                                />
                                                            </div>
                                                            <div className={formClasses.userDetailsRRatingsImage}>
                                                                <StarRoundedIcon className={formClasses.star}/>
                                                                <div className={formClasses.gradeOverlay}>{`${fetchEmployerDetails?.averageRating}`}</div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className={`${formClasses.avatarRatingsContainer}`}>
                                                            <div className={`${formClasses.avatarContainer} ${formClasses.avatarsHolderContainer}`}>
                                                                <div className={formClasses.initialsHolderMedium}>
                                                                    {`${fetchEmployerDetails?.companyName[0]}`}
                                                                </div>
                                                                <div className={formClasses.UserDetailsRatings}>
                                                                <StarRoundedIcon className={formClasses.star}/>
                                                                <div className={formClasses.gradeOverlay}>{`${fetchEmployerDetails?.averageRating}`}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className={`${classes.companyNameContainer} ${formClasses.pr6} ${formClasses.pl6}`}>
                                                        <span className={classes.companyName}>
                                                            {`${fetchEmployerDetails?.companyName}`}
                                                        </span>
                                                    
                                                    </div>
                                                    {fetchEmployerDetails?.aboutMe && (
                                                        <div className={classes.companyDescription}>
                                                         {`${fetchEmployerDetails?.aboutMe}`}
                                                        </div>
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} className={formClasses.toggleButtonsContainer}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={8}>
                                                <div className={formClasses.addDetailsContainer}>
                                                    <div className={formClasses.addDetailsContainer}>
                                                        <div className={formClasses.detailsHeadlinesContainer}>
                                                            <div className={`${formClasses.detailsText} ${formClasses.mb20}`}>
                                                                <span className={classes.addDescriptionText}>Lokacija:</span>
                                                            </div>
                                                            <div className={`${formClasses.detailsText} ${formClasses.mb20}`}>
                                                                <span className={classes.addDescriptionText}>Industrija/e:</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.details}>
                                                        <div className={`${formClasses.detailsText} ${formClasses.mb20}`}>
                                                            <span className={`${formClasses.addDescriptionDetails}`}>{fetchEmployerDetails?.country.countryName}, {fetchEmployerDetails?.city.city}</span>
                                                        </div>
                                                        <div className={`${formClasses.detailsText} ${formClasses.mb20}`}>
                                                            <span className={`${formClasses.addDescriptionDetails} ${classes.handleLongTexts}`}>{fetchEmployerDetails?.industries.map((industry) => industry.industryName).join(', ')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    ) : (currentComponent === 'ads') ? (
                       <Grid container className={`${isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer} ${formClasses.toggleLayoutsContainer}`}>
                        {fetchEmployerAdds.length === 0 ? (
                            <>
                                <div className={formClasses.companyNameHeadline}>
                                    Bez oglasa
                                </div>
                            </>
                        ) : (
                            <>
                            {adsLoading ? (
                                <>
                                    <Grid item xs={12} sm={12} md={12} className={formClasses.toggleButtonsContainer}>
                                        <Stack spacing={1}>
                                            <Skeleton variant="rectangular" width={'100%'} height={120} />
                                            <Skeleton variant="rectangular" width={'100%'} height={120} />
                                            <Skeleton variant="rectangular" width={'100%'} height={120} />
                                            <Skeleton variant="rectangular" width={'100%'} height={120} />
                                            <Skeleton variant="rectangular" width={'100%'} height={120} />
                                        </Stack>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={12} sm={12} md={12} className={formClasses.toggleButtonsContainer}>
                                    {fetchEmployerAdds?.map((addDetails: any, index: number) => (
                                        <AdsCard key={index} addDetails={addDetails} />
                                    ))}
                                </Grid>
                            )}
                            </>
                        )}
                        
                       </Grid>
                    ) : (
                        <Grid container className={`${isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer} ${formClasses.toggleLayoutsContainer}`}>
                           {ratingsLoading ? (
                                <>
                                    <Grid item xs={12} sm={12} md={12} className={formClasses.toggleButtonsContainer}>
                                        <Stack spacing={1}>
                                            <Skeleton variant="rectangular" width={'100%'} height={30} />
                                            <Skeleton variant="rectangular" width={'100%'} height={30} />
                                            <Skeleton variant="rectangular" width={'100%'} height={30} />
                                            <Skeleton variant="rectangular" width={'100%'} height={30} />
                                            <Skeleton variant="rectangular" width={'100%'} height={30} />
                                        </Stack>
                                    </Grid>
                                </>
                           ) : (
                            <>
                            
                                <Grid item xs={12} sm={12} md={12} className={formClasses.toggleButtonsContainer}>
                                    <UserRatings ratings={fetchEmployerRatins}/>
                                    <div className={formClasses.inputContainerTop}>
                                        <Divider />
                                    </div>
                                    <div className={classes.leaveRatingContainer}>
                                        <div className={classes.ratingsExplanationBox}>
                                            <span className={classes.ratingsText}>
                                                Ovim putem možeš oceniti poslodavca ukoliko ste sarađivali ili još uvek sarađujete u cilju olakšanja izbora drugim kandidatima. 
                                                Molimo te da ukoliko nisi saradjivao sa poslodavcem, ili imaš nameru da ga neosnovano klevetaš, 
                                                da to ne radiš.
                                            </span>
                                        </div>
                                        <div className={classes.ratingsExplanationBox}>
                                            <strong>Svaka ocena će postati vidljiva tek nakon odobrenja admina. </strong>
                                        </div>
                                        <form onSubmit={handleButtonClick}> 
                                            <div className={formClasses.inputContainerTop}>
                                                <div className={formClasses.ratingsTextSmall}>
                                                    Ocenite poslodavca *
                                                </div>
                                                <Controller 
                                                    name="rating"
                                                    control={control}
                                                    render={({field, fieldState}) => {
                                                        return (
                                                            <Box sx={{
                                                                '& > legend': { mt: 2 },
                                                                }}>
                                                                    <Rating
                                                                        {...field}
                                                                        value={ratingValue}
                                                                        size="large"
                                                                        onChange={(event, newValue) => {
                                                                            field.onChange(newValue);
                                                                            setRatingValue(newValue)
                                                                        }}
                                                                    
                                                                    />
                                                            </Box>
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className={formClasses.inputContainer}>
                                                <Controller 
                                                    name="comment"
                                                    control={control}
                                                    rules={{
                                                        required: 'Polje je obavezno',
                                                        maxLength: 200
                                                    }}
                                                    render={({field, fieldState}) => {
                                                        return (
                                                            <StyledInput
                                                                variant="outlined"
                                                                multiline={true}
                                                                rows={4}
                                                                required={false}
                                                                type="text"
                                                                label="Komentar" 
                                                                error={!!fieldState.error}
                                                                helperText={fieldState.error?.message}
                                                                inputRef={field.ref}
                                                                {...field}
                                                            />
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={3}>
                                                    <div className={formClasses.inputContainer}> 
                                                        <StyledButton
                                                            style={{ textTransform: 'none'}}
                                                            className={formClasses.w100pi}
                                                            variant="contained"
                                                            onClick={handleButtonClick}
                                                            >   
                                                                Oceni poslodavca
                                                        </StyledButton>
                                                        {errorModal && (
                                                            <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                                                                <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModal(false)} severity="error">Već ste ocenili korisnika</Alert>
                                                            </Backdrop>
                                                        )}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </div>
                                </Grid>
                            </>
                           )}
                       </Grid>
                    )}
                </Grid>
                <Grid item xs={0} sm={0} md={2}>
                    <div className={formClasses.bannersContainer}></div>
                </Grid>
            </Grid>
        </>
    )
}

export default EmployerReadOnlyProfile;


