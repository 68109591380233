import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles((theme) => ({
    functionalHeader: {
        height: '72px',
        position: 'fixed',
        backgroundColor: '#FFFFFF !important'
    },
    functionalHeaderMobile: {
        position: 'fixed',
        backgroundColor: '#FFFFFF !important',
        height: '72px',
    },
    itemsContainer: {
        marginLeft: '24px',
        marginRight: '24px',
        padding: 0,
    },
    logoContainer: {
        cursor: 'pointer',
        paddingTop: '30px',
        paddingBottom: '24px'
    },
    logo: {
        width: '120px',
        height: '18px'
    },
    notifierContainer: {
        cursor: 'pointer',
        display: 'flex',
        paddingTop: '18px',
        paddingBottom: '18px'
    },
    avatarHolder: {
        width: '36px !important',
        height: '36px !important',
        borderRadius: '36px !important',
        fontSize: '16px !important',
        color: '#EBEFFF !important',
        backgroundColor: '#001A80 !important',
        fontWeight: 'bolder !important'
    },
    avatarHolderImage: {
        width: '36px !important',
        height: '36px !important',
        borderRadius: '12px !important',
    },
    drawerIcons: {
        color: '#0033FF !important'
    },
    notifierComponent: {
        padding: '20px 20px 36px 20px'
    },
    notifierHhelperTexts: {
        marginBottom: '6px',
        color: '#636366',
        fontSize: '12px'
    },
    profileDetailsContainer: {
        display: 'flex',
        border: '1px solid #0033FF',
        borderRadius: '12px',
        padding: '12px'
    },
    nameContainer: {
        marginLeft: '6px',
        minWidth: '125px'
    },
    nameContainerSmall: {
        marginLeft: '6px',
        marginRight: '6px',
        minWidth: '80px'
    },
    name: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#1C1C1E',
    },
    buttonContainer: {
        marginLeft: '83px'
    },
    buttonContainerSmall: {
        marginLeft: '0px'
    }
}));

export default useStyles