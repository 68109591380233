import Grid from "@mui/material/Grid/Grid"
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import useStyles from "./userRatings.module";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import { EmployeeRatingsResponse, EmployerRatingsResponse } from "../../../utils/types.d";


function UserRatings({ ratings }: any) {
    const classes = useStyles();
    const formClasses = useFormStyles();

    return (
        <Grid item xs={12} sm={12} md={12}>
            {ratings.length === 0 ? (
                <>
                    <div className={classes.ratingsContainer}>
                        <div className={formClasses.companyNameHeadline}>
                            Bez ocena
                        </div>
                    </div>
                </>
               
               
            ) : (
               <>
                    {ratings.map((ratingDetails: EmployeeRatingsResponse | EmployerRatingsResponse, index: number) => (
                        <div key={index} className={classes.ratingsContainer}>
                            <div className={classes.ratingDetailsUpper}>
                                <div className={classes.ratingDetails}>
                                    <StarRoundedIcon className={formClasses.star}/>
                                    
                                    <div className={formClasses.grade}>{ratingDetails?.rating?.rating}</div>
                                    <FiberManualRecordIcon className={formClasses.dotSeparator}/>
                                    <div className={classes.name}>{ratingDetails?.companyName}</div>
                                </div>
                                <div className={classes.addedDate}>
                                    <div className={classes.date}>{ratingDetails?.rating?.assessmentTime.split('T')[0]}</div>
                                </div>
                            </div>
                            <div className={classes.ratingDetailsBottom}>
                                <div className={classes.ratingDetailsText}>
                                    {ratingDetails?.rating?.comment}
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </Grid>
    )
}

export default UserRatings