import { 
    formatDate, 
    generateOfferedItemsText, 
    handleNavigate, 
    useMediaQueries 
} from "../../../utils/global-utils";
import FunctionalHeader from "../../functionalHeader/functionalHeader";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import useStyles from "./myAddDetailed.module";
import Grid from "@mui/material/Grid/Grid"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import StyledButton from "../../styledComponents/styledButton.module";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Button } from "@material-ui/core"
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useAuthContext } from "../../../context/UserContext";
import { SelectedAddResponse, Candidate } from "../../../utils/types.d";
import AddApi from "../../../services/addApi";
import StyledConfirmationModal from "../../styledComponents/styledConfirmationModal/styledConfirmationModal";
import StyledSpinningIcon from "../../styledComponents/spinningIcon/spinningIcon";
import DetailedCard from '../../wwwApp/detailedCard/detailedCard'
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Alert from '@mui/material/Alert';
import Typography from "@material-ui/core/Typography/Typography";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



function MyAddDetailed() {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const { addId } = useParams();
    const { accessToken, userData } = useAuthContext();
    const [currentComponent, setCurrentComponent] = useState('details');
    const [fetchSelectedAdd, setfFetchSelectedAdd] = useState<SelectedAddResponse[]>([])
    const [fetchReceivedApplications, setFetchReceivedApplications] = useState<Candidate[]>([])
    const [loading, setLoading] = useState(true);
    const [deleteAdd, setDeleteAdd] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [closeApplications, setCloseApplications] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorModalDeleteAd, setErrorModalDeleteAd] = useState(false);
    const employerId = userData?.nameid

    useEffect(() => {
        const fetchAdd= async () => {
          try {
            if (accessToken) {
                const response: any = await AddApi.GetSelectedAd(accessToken, addId);
                const { status, data } = response;
                if(status === 200) {
                    setfFetchSelectedAdd([data]);
                }
                setLoading(false);
            }
          } catch (error) {
            setfFetchSelectedAdd([]);
            setLoading(false);
          }
        };

        if (accessToken) {
            setLoading(true);
            fetchAdd();
        }
    }, [accessToken]);

    useEffect(() => {
        const fetchApplications= async () => {
          try {
            if (accessToken) {
                const response: any = await AddApi.GetReceivedApplicationsByAdId(accessToken, addId);
                const { status, data } = response;
                if(status === 200) {
                    setFetchReceivedApplications(data)
                }
            }
          } catch (error) {
            //
          }
        };

        if (accessToken) {
            fetchApplications();
        }
    }, [accessToken]);

    const handleComponentChange = (_event: any, newComponent: string) => {
        if (currentComponent === newComponent) {
            return;
        }
        setCurrentComponent(newComponent);
    };

    const handleCloseApplications = async () => {
        try {
            const dataObject = {
                employerId: employerId,
                adId: addId
            }
            await AddApi.CloseApplications(accessToken, dataObject);
        } catch (error) {
            setErrorModal(true)
        } finally {
            setCloseApplications(false);
        }
    }

    const handleDeleteAdd = async () => {
        setIsDeleting(true);
        try {
            await AddApi.DeleteAdd(accessToken, addId, employerId);
            handleNavigate(navigate, "/employer-dashboard");
        } catch (error) {
            setErrorModalDeleteAd(true)
        } finally {
            setIsDeleting(false);
            setDeleteAdd(false);
        }
    }

    const adId = fetchSelectedAdd[0]?.adId

    return (
        <>
            <Grid container>
                <Grid item xs={0} sm={0} md={2}>
                    <div className={formClasses.bannersContainer}></div>
                </Grid>
                <Grid item xs={12} sm={12} md={8} className={isMobile ? `${formClasses.ml12} ${formClasses.mr12}` : ''}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <FunctionalHeader />
                        </Grid>
                    </Grid>
                    <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                        <div className={formClasses.companyNameHeadline}>
                            <ArrowBackIcon 
                                className={formClasses.navigateBack}
                                onClick={() => navigate(-1)} /> Moj oglas
                        </div>
                    </div>
                    <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                        <Grid item xs={12} sm={12} md={6}>
                            <ToggleButtonGroup
                                    className={formClasses.buttonsGroup}
                                    value={currentComponent}
                                    exclusive
                                    onChange={handleComponentChange}
                                    aria-label="component-switcher"
                                >
                                    <ToggleButton
                                        className={formClasses.singleToggleButton}
                                        value="details" 
                                        aria-label="details-layout"
                                        disabled={currentComponent === 'details'}
                                        >
                                            Detalji
                                    </ToggleButton>
                                    <ToggleButton
                                        className={formClasses.singleToggleButton}
                                        value="applications" 
                                        aria-label="applications-layout"
                                        disabled={currentComponent === 'applications'}
                                        >
                                            Prijave
                                    </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                        <div className={`${formClasses.bodyItemsHolder} ${formClasses.inputContainerTop}`}>
                        {loading ? (
                            <Backdrop open={true}>
                                <div className={formClasses.circularProgressContainer}>
                                    <StyledSpinningIcon />
                                </div>
                            </Backdrop>
                        ) : (
                            <>   
                                {(currentComponent === 'details') ? (
                                    <>
                                        <Grid item xs={12} sm={12} md={12}>
                                            {fetchSelectedAdd?.map((addDetails, index) => (
                                                <div key={`${index}`}>
                                                    <div className={`${formClasses.actionCardsText}`}>
                                                        {addDetails.adTitle}
                                                    </div>
                                                    {!addDetails.isOpenForApplications && (
                                                        <span className={classes.addDescriptionText}>
                                                            Prijave na ovom oglasu su zatvorene
                                                        </span>
                                                    )}
                                                    <div className={formClasses.inputContainerTop}>
                                                        <span className={classes.addDescriptionText}>
                                                            {addDetails.adDescription}
                                                        </span>
                                                    </div>
                                                    <div className={formClasses.inputContainer}>
                                                        <Divider />
                                                    </div>
                                                    <TableContainer component={Paper} className={classes.table}>
                                                        <Table>
                                                            <TableBody>
                                                                <TableRow className={classes.tableRow}>
                                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                                        Industrija/e
                                                                    </TableCell>
                                                                    <TableCell align="right" className={`${classes.detailsText}`}>
                                                                        {addDetails.industries.map((industry) => industry.industryName).join(', ')}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className={classes.tableRow}>
                                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                                        Država
                                                                    </TableCell>
                                                                    <TableCell align="right" className={`${classes.detailsText}`}>
                                                                        {addDetails.country.countryName}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className={classes.tableRow}>
                                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                                        Grad
                                                                    </TableCell>
                                                                    <TableCell align="right" className={`${classes.detailsText}`}>
                                                                        {addDetails.city.city}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className={classes.tableRow}>
                                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                                        {addDetails.workingEndDate === null  ? 'Početak rada' : 'Trajanje'}
                                                                    </TableCell>
                                                                    <TableCell align="right" className={`${classes.detailsText}`}>
                                                                        {formatDate(addDetails.workingStartDate)} / {addDetails.workingEndDate === null ? '_' : formatDate(addDetails.workingEndDate)}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow className={classes.tableRow}>
                                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Radno vreme</TableCell>
                                                                    <TableCell align="right" className={`${classes.detailsText}`}> {`${addDetails.workingHours}h`}</TableCell>
                                                                </TableRow>
                                                                {addDetails?.billingType?.typeOfBilling && (
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Tip naplate</TableCell>
                                                                        <TableCell align="right" className={`${classes.detailsText}`}>{addDetails.billingType?.typeOfBilling}</TableCell>
                                                                    </TableRow>
                                                                )}
                                                                <TableRow className={classes.tableRow}>
                                                                    {addDetails?.salaryValue && addDetails?.currency?.currencyName ? (
                                                                        <>
                                                                            <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Iznos</TableCell>
                                                                            <TableCell align="right" className={`${classes.detailsText}`}>
                                                                                {addDetails?.salaryValue} {addDetails?.currency?.currencyName}
                                                                            </TableCell>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Isplata</TableCell>
                                                                            <TableCell align="right" className={`${classes.detailsText}`}>Po dogovoru</TableCell>
                                                                        </>
                                                                    )}
                                                                </TableRow>
                                                                <TableRow className={classes.tableRow}> 
                                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Benefiti</TableCell>
                                                                    <TableCell align="right" className={`${classes.detailsText}`}>
                                                                        {generateOfferedItemsText(addDetails) || "Bez benefita"}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            ))}
                                        </Grid>
                                        <div className={formClasses.inputContainerTop}>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={4}>
                                                    <div className={formClasses.mb12}>
                                                        <StyledButton
                                                            className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                                                            style={{ textTransform: 'none'}}
                                                            onClick={() => handleNavigate(navigate, `/edit-ad/${addId}`)}
                                                            variant="contained">
                                                            Izmeni oglas
                                                        </StyledButton>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4}>
                                                    <div className={formClasses.mb12}>
                                                        <StyledButton
                                                            className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                                                            style={{ textTransform: 'none'}}
                                                            onClick={() => setCloseApplications(true)}
                                                            variant="text"
                                                            disabled={fetchSelectedAdd && fetchSelectedAdd[0]?.isOpenForApplications !== true}
                                                        >
                                                            {fetchSelectedAdd[0]?.isOpenForApplications !== true ? "Zatvorene prijave" : "Zatvori prijave"}
                                                        </StyledButton>
                                                        <StyledConfirmationModal
                                                            open={closeApplications}
                                                            onClose={() => setCloseApplications(false)}
                                                            onConfirm={handleCloseApplications}
                                                            bodyText="Da li ste sigurni da želite da zatvorite prijave na oglasu?"
                                                        />
                                                        {errorModal && (
                                                            <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                                                                <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModal(false)} severity="error">Prijave na ovom oglasu su zatvorene</Alert>
                                                            </Backdrop>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4}>
                                                    <StyledButton
                                                        className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                                                        style={{ textTransform: 'none'}}
                                                        onClick={() => setDeleteAdd(true)}
                                                        variant="outlined">
                                                            Obriši oglas
                                                    </StyledButton>
                                                    {errorModalDeleteAd && (
                                                        <Backdrop className={formClasses.zIndexHighest} open={errorModalDeleteAd}>
                                                            <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModalDeleteAd(false)} severity="error">Došlo je do greške pri brisanju oglasa</Alert>
                                                        </Backdrop>
                                                    )}
                                                    <Dialog open={deleteAdd} onClose={() => setDeleteAdd(false)}>
                                                        <DialogContent className={isMobile ? classes.mobileDialogBody : ' '}>
                                                            <Typography>Da li ste sigurni da želite da izbrišete oglas?</Typography>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={() => setDeleteAdd(false)} autoFocus>
                                                                Ne
                                                            </Button>
                                                            <Button onClick={handleDeleteAdd} autoFocus>
                                                                Da
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </> 
                                ) : (
                                    <>
                                        <Grid item xs={12} sm={12} md={12}>
                                            {fetchReceivedApplications.length === 0 ? (
                                            <>
                                            <div className={formClasses.inputContainerTop}>
                                            <div className={`${formClasses.canditateName} ${formClasses.ml28}`}>
                                                Nema prijava na ovom oglasu
                                            </div>
                                            </div>
                                        <Divider />
                                        </>
                                        ) : (
                                            <DetailedCard  addDetails={fetchReceivedApplications} adId={adId} />
                                        )}
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                        </div>
                       
                    </Grid>
                </Grid>
                <Grid item xs={0} sm={0} md={2}>
                    <div className={formClasses.bannersContainer}></div>
                </Grid>
            </Grid>
        </>
    )  
}

export default MyAddDetailed