import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles(() => ({
    dialogTitle: {
        color: '#1C1C1E !important',
        fontSize: '24px !important',
        lineHeight: '24px !important',
        fontWeight: '900 !important',
    },
    dialogTitleMobile: {
        color: '#1C1C1E !important',
        fontSize: '18px !important',
        lineHeight: '24px !important',
        fontWeight: '900 !important',
    },
    pricingModalContainer: {
        padding: '36px !important'
    },
    cardHeight: {
        height: '404px !important'
    },
    cardContent: {
        padding: '12px !important'
    },
    regularCard: {
        backgroundColor: '#FFFFFF',
        cursor: 'pointer !important'
    },
    standardCard: {
        border: '1px solid #B8B8BD !important',
        backgroundColor: '#EBEFFF !important',
        cursor: 'pointer !important'
    },
    sponsoredCard: {
        backgroundColor: '#FFEFD6 !important',
        border: '1px solid #BFA173 !important',
        cursor: 'pointer !important'
    },
    premiumCard: {
        backgroundColor: '#F2F2F7 !important',
        border: '1px solid #0033FF !important',
        cursor: 'pointer !important'
    },
    pricingContainer: {
        marginTop: '12px',
        marginBottom: '18px'
    },
    price: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#1C1C1E',
        marginRight: '6px'
    },
    duration: {
        fontSize: '14px',
        color: '#636366',
        marginLeft: '6px'
    },
    headlineHolder: {
        marginBottom: '12px'
    },
    regularAddHeadline: {
        color: '#1C1C1E',
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    standardAddHeadlineSelected: {
        color: '#0033FF',
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    sponsoreddAddHeadline: {
        color: '#BFA173',
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    premiumAddHeadline: {
        color: '#0033FF',
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    explanationContainer: {
        marginTop: '12px',
        marginBottom: '12px'
    },
    explanationHeadlineSmallRegular: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#1C1C1E',
        marginRight: '6px'
    },
    explanationHeadlineSmallSponsored: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#BFA173',
        marginRight: '6px'
    },
    explanationHeadlineSmallPremium: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#0033FF',
        marginRight: '6px'
    },
    explanationTextSmall: {
        fontSize: '12px',
        color: '#636366'
    }
}));

export default useStyles