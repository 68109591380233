import useStyles from './register.module';
import Grid from '@mui/material/Grid';
import BODY_ITEMS from '../../../assets/bodyItems/index'
import { CircularProgress, Link, Typography } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import StyledButton from '../../styledComponents/styledButton.module';
import StyledLayoutItem from '../../layoutItem/layoutItem';
import { StyledInput } from '../../styledComponents/styledInput/styledInput';
import { handleNavigate, useMediaQueries } from '../../../utils/global-utils';
import MobileHeader from '../../mobileHeader/mobileHeader';
import { Controller, useForm } from 'react-hook-form';
import TermsAndConditionsDialog  from '../../termsAndConditions/termsAndConditions';
import { useState } from 'react';
import RegisterUserApi from '../../../services/registerApi'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useParams, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import useFormStyles from '../../styledComponents/globalFormStying.module';
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Alert from '@mui/material/Alert';


function Register() {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const { selectedCard } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState(null);

    const { 
        handleSubmit,
        formState,
        control,
        trigger, 
        reset } = useForm({
            mode: 'onChange',
            defaultValues: {
                role: selectedCard,
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                emailAddress: '',
                password: '',
                termsAndConditions: false
            }
    });

    const onSubmit = async (formData: any) => {
        try {  
            const response: any = await RegisterUserApi.RegisterUser(formData);
            const { status } = response;
            if (status === 200) {
                const userId = response.data.userId
                reset({
                    firstName: '', 
                    lastName: '', 
                    dateOfBirth: '', 
                    emailAddress: '', 
                    password: '', 
                    termsAndConditions: false
                })
                setUserId(userId);
                if (selectedCard === 'employee') {
                    handleNavigate(navigate, `/employee/final-step/${userId}`)
                }
                else if (selectedCard === 'employer') {
                    handleNavigate(navigate, `/employer/final-step/${userId}`)
                }
            } else {
                setErrorModal(true);
            }
        } catch (error) {
            setErrorModal(true)
        }
    };

    const handleButtonClick = async () => {
        try {
            await trigger();
            if (Object.keys(formState.errors).length === 0) {
                setLoading(true);
                await handleSubmit(onSubmit)();
                setLoading(false);
            }
        }
        catch (err) {
            //
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <section>
                <Grid container className={formClasses.container}>
                   {(isMobile || isTablet) ? (
                        <MobileHeader />
                   ) : (
                    <StyledLayoutItem 
                        alt="construct"
                        imageSrc={`${BODY_ITEMS.CONSTRUCT}`}
                        children
                    />
                   )}
                   <Grid item xs={12} sm={12} md={6}>
                        <div className={isMobile ? formClasses.formContainerMobile : formClasses.formContainer}>
                            <div className={formClasses.formText}>
                                <Typography className={formClasses.headline}>
                                    Napravi besplatan nalog!
                                </Typography>
                                <Typography className={formClasses.headlineSmall}>
                                    U samo par koraka kreirajte besplatan nalog na Sezonal platformi
                                </Typography>
                            </div>
                            <form onSubmit={handleButtonClick}>
                                <div className={formClasses.inputContainer}>
                                    <Controller 
                                        name="firstName"
                                        control={control}
                                        rules={{ 
                                            required: 'Polje je obavezno',
                                            pattern: {
                                                value: /^[A-Za-z]+$/i,
                                                message: 'Ime ne može sadržati ništa sem slova',
                                            }, 
                                        }}
                                        render={({field, fieldState}) => {
                                            return (
                                                <StyledInput
                                                    required
                                                    type="text"
                                                    label="Ime"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                    inputRef={field.ref}
                                                    {...field}
                                                />
                                            )
                                            
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        rules={{ 
                                            required: 'Polje je obavezno',
                                            pattern: {
                                                value: /^[A-Za-z]+$/i,
                                                message: 'Prezime ne može sadržati ništa sem slova',
                                            }, 
                                        }}
                                        render={({field, fieldState}) => {
                                            return (
                                                <StyledInput
                                                    required
                                                    type="text"
                                                    label="Prezime"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                    inputRef={field.ref}
                                                    {...field}
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Controller 
                                        name="dateOfBirth"
                                        control={control}
                                        rules={{ 
                                            required: 'Polje je obavezno',
                                            validate: (value) => {
                                                const selectedDate = new Date(value);
                                                const minDate = new Date();
                                                minDate.setFullYear(minDate.getFullYear() - 14);
                                                return selectedDate <= minDate || 'Morate imati najmanje 15 godina';
                                            },
                                        }}
                                        render={({field, fieldState}) => {
                                            const hasError = !!fieldState.error;
                                            const showError = hasError || (formState.isSubmitted && fieldState.invalid);
                                            return (
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            label="Datum rodjenja*"
                                                            {...field}
                                                            value={field.value}
                                                            disableFuture
                                                            format="YYYY-MM-DD"
                                                            className={formClasses.styledInputDate}
                                                            slotProps={{
                                                                
                                                                textField: {
                                                                    helperText: (showError ? fieldState.error?.message : ''),
                                                                    error: hasError, 
                                                                },
                                                            }}
                                                            inputRef={field.ref}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            )
                                        }}
                                    />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Controller 
                                        name="emailAddress"
                                        control={control}
                                        rules={{
                                            required: 'Polje je obavezno',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Unesite validnu e-mail adresu',
                                            },
                                        }}
                                        render={({ field, fieldState }) => {
                                            return (
                                                <StyledInput
                                                    required
                                                    type="email"
                                                    label="E-mail adresa"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                    inputRef={field.ref}
                                                    {...field}
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{ 
                                            required: 'Polje je obavezno',
                                            pattern: {
                                                value: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
                                                message: 'Pogrešan šablon',
                                            },
                                        }}
                                        render={({ field, fieldState }) => {
                                            return (
                                                <>
                                                    <StyledInput
                                                        {...field}
                                                        required
                                                        value={field.value}
                                                        type={showPassword ? 'text' : 'password'}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <IconButton 
                                                                    onMouseDown={handleTogglePassword}
                                                                    onMouseUp={handleTogglePassword}
                                                                    onMouseLeave={() => setShowPassword(false)}
                                                                    onTouchStart={handleTogglePassword}
                                                                    onTouchEnd={handleTogglePassword}
                                                                    edge="end"
                                                                >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        label="Lozinka"
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                        inputRef={field.ref}
                                                        
                                                    />
                                                    <div className={classes.formHelperContainer}>
                                                        <FormHelperText className={classes.formHelperText}>
                                                            Lozinka mora sadržati barem jedno slovo, jedan broj < br />
                                                            i biti dugačka najmanje 8 karaktera 
                                                        </FormHelperText>
                                                    </div>
                                                </>
                                                
                                            )
                                        }}
                                    />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Controller
                                        name="termsAndConditions" 
                                        control={control}
                                        rules={{
                                            required: 'Polje',
                                        
                                        }}
                                        render={({ field, fieldState }) => {
                                            return (
                                                <div className={formClasses.manageConsentContainerStart}>
                                                    <Checkbox 
                                                        required
                                                        className={`${classes.consentCheckbox}`} 
                                                        size="small"
                                                        {...field}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                        inputRef={field.ref}
                                                        
                                                    />
                                                    {fieldState.error && (
                                                        <Typography className={formClasses.manageConsentError} color="error" variant="caption">
                                                            {fieldState.error.message} <Link onClick={handleClickOpen} className={formClasses.manageConsentLink}>uslovi korišćenja</Link> je obavezno
                                                        </Typography>
                                                    )}
                                                    {!fieldState.error && (
                                                        <Typography className={formClasses.manageConsent}>
                                                            Prihvatam <Link onClick={handleClickOpen} className={formClasses.manageConsentLink}>uslove korišćenja</Link> aplikacije
                                                        </Typography>
                                                    )}
                                                </div>
                                            )
                                        }}
                                    />
                                    <TermsAndConditionsDialog
                                        open={open}
                                        onClose={handleClose}
                                        children
                                     />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <StyledButton
                                        style={{ textTransform: 'none'}}
                                        onClick={handleButtonClick}
                                        variant="contained"
                                    >
                                        {loading ? <CircularProgress 
                                                        size={24}
                                                        className="circular-progress"
                                                    />
                                        : 'Nastavi'}
                                    </StyledButton>
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <StyledButton 
                                        style={{ textTransform: 'none'}}
                                        variant="text" onClick={() => navigate(-1)}
                                    >
                                        Vrati se nazad
                                    </StyledButton>
                                </div>
                                {errorModal && (
                                    <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                                        <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModal(false)} severity="error">Korisnik sa ovom e-mail adresom je već registrovan</Alert>
                                    </Backdrop>
                                )}
                            </form>
                        </div>
                   </Grid>
                </Grid>
            </section>
        </>
    )
}

export default Register