import useFormStyles from "../../styledComponents/globalFormStying.module";
import { Controller, useForm } from "react-hook-form";
import { SelectChangeEvent } from "@mui/material";
import Grid from "@mui/material/Grid/Grid"
import FormControl from '@mui/material/FormControl';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import DatePicker from "react-datepicker";
import { CircularProgress } from "@material-ui/core";
import Typography from "@mui/material/Typography/Typography";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import { StyledSelect } from "../../styledComponents/styledSelect/styledSelect";
import OutlinedInput from "@mui/material/OutlinedInput/OutlinedInput";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Alert from '@mui/material/Alert';
import Box from "@material-ui/core/Box/Box";
import Chip from "@material-ui/core/Chip/Chip";
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FunctionalHeader from "../../functionalHeader/functionalHeader"
import { useEffect, useState, useRef } from "react";
import { countryId, handleNavigate, useMediaQueries } from "../../../utils/global-utils";
import {   
    Industry, 
    BillingType, 
    Place, 
    FilteredAdResponse, 
    AdResponse,
    Country} 
from "../../../utils/types.d";
import '../../styledComponents/styledDateRangePicker.css'
import { useNavigate } from "react-router-dom";
import { StyledInput } from "../../styledComponents/styledInput/styledInput";
import BillingAndIndustriesApi from "../../../services/billingAndIndustries";
import StyledButton from "../../styledComponents/styledButton.module";
import AddApi from "../../../services/addApi";
import AdsCard from "../adsCard/adsCard";

function AdsDashboard() {
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const today = new Date();
    const ref = useRef<HTMLDivElement | null>(null);
    const [industry, setIndustry] = useState<string[]>([]);
    const [fetchIndustries, setFetchIndustries] = useState<Industry[]>([]);
    const [fetchCountries, setFetchCountrues] = useState<Country[]>([]);
    const [fetchPaymentType, setFetchPaymentType] = useState<BillingType[]>([])
    const [paymentType, setpaymentType] = useState<string[]>([]);
    const [fetchPlaces, setFetchPlaces] = useState<Place[]>([]);
    const [places, setPlaces] = useState<string[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<string[]>([])
    const [selectedCountryById, setSelectedCountryById] = useState<string>('')
    const [startDate, setStartDate] = useState<Date | null>();
    const [endDate, setEndDate] = useState<Date | null>();
    const [filterApplied, setFilterApplied] = useState(false);
    const [fetchAds, setFetchAds] =  useState<AdResponse[]>([])
    const [fetchFilteredAds, setFetchFilteredAds] = useState<FilteredAdResponse[]>([]);
    const [adsLoading, setAdsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchErrorModal, setSearchErrorModal] = useState(false);

    useEffect(() => {
        const fetchAdsData = async () => {

          try {
            const addResponse = await AddApi.GetAllAds({});
            setFetchAds(addResponse.data)
            setAdsLoading(false);
          } catch (error) {
            setAdsLoading(false);
          }
        };

    
        fetchAdsData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const industriesResponse = await BillingAndIndustriesApi.GetIndustries({});
            setFetchIndustries(industriesResponse.data);
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const billingTypeResponse = await BillingAndIndustriesApi.getBiillingTypes({});
            setFetchPaymentType(billingTypeResponse.data);
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const countriesResponse = await BillingAndIndustriesApi.getAllCountries({});
            setFetchCountrues(countriesResponse.data);
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const response: any = await BillingAndIndustriesApi.getAllPlaces(selectedCountryById);
            const { status } = response;
            if (status === 200) {
                setFetchPlaces(response.data);
            }
            
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, [selectedCountryById]);

    useEffect(() => {
        setPlaces([]);
    }, [selectedCountryById]);

    const {
        handleSubmit,
        formState,
        control,
        trigger,
        reset,
        setValue } = useForm({
            mode: 'onChange',
            defaultValues: {
                Search: '',
                CountryId: '',
                PlaceId: '',
                WorkingStartDate: '',
                WorkingEndDate: '',
                BillingTypeId: '',
                IndustryIds: [],
                OfferedAccomodation: false,
                OfferedFood: false,
                OfferedTransport: false
            }
        })

        const onSubmit = async (FormData: any) => { 
            const params = new URLSearchParams();
        
            if (FormData.IndustryIds.length > 0) {
                FormData.IndustryIds.forEach((id:string) => {
                    params.append('IndustryIds', id);
                });
            }
        
            if (FormData.Search) {
                params.append('Search', FormData.Search);
            }
            if (FormData.CountryId) {
                params.append('CountryId', FormData.CountryId);
            }
            if (FormData.PlaceId) {
                params.append('PlaceId', FormData.PlaceId);
            }
            if (FormData.WorkingStartDate) {
                params.append('WorkingStartDate', FormData.WorkingStartDate);
            }
            if (FormData.WorkingEndDate) {
                params.append('WorkingEndDate', FormData.WorkingEndDate);
            }
            if (FormData.BillingTypeId) {
                params.append('BillingTypeId', FormData.BillingTypeId);
            }
            if(FormData.OfferedAccomodation){
                params.append('OfferedAccomodation', FormData.OfferedAccomodation.toString());
            }
            if(FormData.OfferedFood){
                params.append('OfferedFood', FormData.OfferedFood.toString());
            }
            if(FormData.OfferedTransport){
                params.append('OfferedTransport', FormData.OfferedTransport.toString());
            }
            try {
                const query = params.toString();
                const response: any = await AddApi.GetFilteredAds(`?${query}`);
                const { data, status } = response;
                setFetchFilteredAds(data); 
                if (status === 200 && data.length > 0) { 
                    setFilterApplied(true)
                    if (ref.current) {
                        ref.current.scrollIntoView({ behavior: 'smooth' });
                    }
                } 
            } catch (error) {
                setSearchErrorModal(true);
            }
        }

        const handleButtonClick = async () => {
            try {
                await trigger();
                if (Object.keys(formState.errors).length === 0) {
                    setLoading(true)
                    await handleSubmit(onSubmit)();
                    setLoading(false)
                }
            }
            catch (err) {
                //
            }
        };

        const handleResetFilters = () => {
            reset();
            setIndustry([]);
            setpaymentType([]);
            setPlaces([]);
            setSelectedCountry([])
            setValue('OfferedAccomodation', false);
            setValue('OfferedFood', false);
            setValue('OfferedTransport', false);
            setFetchFilteredAds([]);
        }

    return (
        <Grid container>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <FunctionalHeader />
                    </Grid>
                </Grid>
                <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                    <div className={formClasses.companyNameHeadline}>
                        <ArrowBackIcon 
                            className={formClasses.navigateBack}
                            onClick={() => handleNavigate(navigate, `/employee-dashboard/`)} /> Pretraži oglase
                    </div>
                </div>
                <form  onSubmit={handleButtonClick}>
                <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className={`${formClasses.inputContainer}`}>
                            <Controller
                                name="Search"
                                control={control}
                                render={({field, fieldState}) => {
                                    return (
                                        <StyledInput
                                            {...field}
                                            disabled={false}
                                            variant="outlined"
                                            required={false}
                                            type="text"
                                            label="Naziv oglasa"
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                            inputRef={field.ref}
                                        />
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className={`${formClasses.inputContainer} ${isMobile ? '' : formClasses.pl12}`}>
                            <Controller
                                name="CountryId"
                                control={control}
                                render={({field, fieldState}) => {
                                    const handleCountryChange = (event: SelectChangeEvent<typeof selectedCountry>) => {
                                        const { target: { value } } = event;
                                        const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                        field.onChange(selectedValues);
                                        setSelectedCountry(selectedValues)

                                        const selectedCountryId: string | undefined = fetchCountries.find(
                                            (place) => place.countryName === value
                                        )?.id

                                        setSelectedCountryById(selectedCountryId as string)
                                        setValue('CountryId', selectedCountryId || '');
                                    }
                                    return (
                                        <div className={formClasses.customFormErrorsContainer}>
                                            {fieldState.error && (
                                                <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                    {fieldState.error.message}
                                                </Typography>
                                            )}
                                            <FormControl className={formClasses.selectContainer}>
                                                <InputLabel 
                                                    id="select-country" 
                                                    className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                        Država
                                                </InputLabel>
                                                <StyledSelect
                                                    {...field}
                                                    required={false}
                                                    labelId="select-country"
                                                    value={selectedCountry}
                                                    onChange={handleCountryChange}
                                                    input={
                                                        <OutlinedInput 
                                                            label="Država" 
                                                            className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                        />
                                                    }
                                                    error={!!fieldState.error}
                                                    inputRef={field.ref}
                                                >
                                                    {fetchCountries.slice().sort((a, b) => a.countryName.localeCompare(b.countryName))
                                                        .map((region, index) => (
                                                            <MenuItem
                                                                className={formClasses.menuItemContainer}
                                                                key={index} 
                                                                value={region.countryName}
                                                            >
                                                                {region.countryName}
                                                            </MenuItem>
                                                    ))}

                                                </StyledSelect>
                                            </FormControl>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className={`${formClasses.inputContainer} ${isMobile ? '' : formClasses.horizontalSpacing}`}>
                            <Controller
                                name="PlaceId"
                                control={control}
                                render={({field, fieldState}) => {
                                    const handleCityChange = (event: SelectChangeEvent<typeof places>) => {
                                        const { target: { value } } = event;
                                        const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                        field.onChange(selectedValues);
                                        setPlaces(selectedValues)

                                        const selectedCityId = fetchPlaces.find(
                                            (place) => place.city === value
                                        )?.id

                                        setValue('PlaceId', selectedCityId || '');
                                    }
                                    return (
                                        <div className={formClasses.customFormErrorsContainer}>
                                            {fieldState.error && (
                                                <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                    {fieldState.error.message}
                                                </Typography>
                                            )}
                                            <FormControl className={formClasses.selectContainer}>
                                                <InputLabel 
                                                    id="select-city" 
                                                    className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                        Grad
                                                </InputLabel>
                                                <StyledSelect
                                                    {...field}
                                                    required={false}
                                                    labelId="select-city"
                                                    value={places}
                                                    onChange={handleCityChange}
                                                    input={
                                                        <OutlinedInput 
                                                            label="Grad" 
                                                            className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                        />
                                                    }
                                                    error={!!fieldState.error}
                                                    inputRef={field.ref}
                                                >
                                                    {fetchPlaces.slice().sort((a, b) => a.city.localeCompare(b.city))
                                                        .map((region, index) => (
                                                            <MenuItem
                                                                className={formClasses.menuItemContainer}
                                                                key={index} 
                                                                value={region.city}
                                                            >
                                                                {region.city}
                                                            </MenuItem>
                                                    ))}

                                                </StyledSelect>
                                            </FormControl>
                                        </div>
                                    )
                                }} 
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className={`${formClasses.inputContainer}`}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} className={isMobile ? `${formClasses.pSides0}` : formClasses.pr6}>
                                    <div className="date-range-container">
                                        <div className="date-range-input-container">
                                            <Controller
                                                name="WorkingStartDate"
                                                control={control}
                                                render={({field, fieldState }) => {
                                                    const handleStartDateChange = (date: Date) => {
                                                        const selectedDate = date ? new Date(date) : null;
                                                        field.onChange(selectedDate)
                                                        setStartDate(selectedDate);
                                                    };
                                                    return (
                                                        <div className={formClasses.customFormErrorsContainer}>
                                                            {fieldState.error && (
                                                                <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                                    {fieldState.error.message}
                                                                </Typography>
                                                            )}
                                                            <DatePicker
                                                                {...field}
                                                                selected={startDate}
                                                                onChange={handleStartDateChange}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                minDate={today}
                                                                placeholderText="MM/DD/YYYY"
                                                                className={fieldState.error ? `${'date-range-inputs-error'} ${'date-range-inputs-error::placeholder'}` : 'date-range-inputs'}
                                                            />
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </div>
                                        <span className="MuiTypography-root MuiTypography-body1 css-14am5op">–</span>
                                        <div className="date-range-input-container">
                                            <Controller
                                                name="WorkingEndDate"
                                                control={control}
                                                render={({field, fieldState}) => {
                                                    const handleEndDateChange = (date: Date) => {
                                                        const selectedDate = date ? new Date(date) : null;
                                                        field.onChange(selectedDate)
                                                        setEndDate(selectedDate);
                                                    };
                                                    return (
                                                        <div className={formClasses.customFormErrorsContainer}>
                                                            {fieldState.error && (
                                                                    <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                                        {fieldState.error.message}
                                                                    </Typography>
                                                            )}
                                                            <DatePicker
                                                                {...field}
                                                                selected={endDate}
                                                                onChange={handleEndDateChange}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                minDate={startDate}
                                                                placeholderText="MM/DD/YYYY"
                                                                className={fieldState.error ? `${'date-range-inputs-error'} ${'date-range-inputs-error::placeholder'}` : 'date-range-inputs'}
                                                            />
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className={`${formClasses.inputContainer}`}>
                            <Controller
                                name="BillingTypeId"
                                control={control}
                                render={({field, fieldState}) => {
                                    const handlePaymentTypeChange = (event: SelectChangeEvent<typeof paymentType>) => {
                                        const { target: { value } } = event;
                                        const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                        field.onChange(value);
                                        setpaymentType(selectedValues);

                                        const selectedBillingTypeId = fetchPaymentType.find(
                                            (type) => type.typeOfBilling === value
                                        )?.id

                                        setValue('BillingTypeId', selectedBillingTypeId || '');
                                    };
                                    return (
                                        <div className={formClasses.customFormErrorsContainer}>
                                            {fieldState.error && (
                                                <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                    {fieldState.error.message}
                                                </Typography>
                                            )}
                                            <FormControl className={formClasses.selectContainer}>
                                                <InputLabel 
                                                    id="payment-type" 
                                                    className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                        Tip naplate
                                                </InputLabel>
                                                <StyledSelect
                                                    {...field}
                                                    required
                                                    labelId="payment-type"
                                                    value={paymentType}
                                                    onChange={handlePaymentTypeChange}
                                                    input={
                                                        <OutlinedInput 
                                                            label="Tip naplate" 
                                                            className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                        />
                                                    }
                                                    error={!!fieldState.error}
                                                >
                                                    {fetchPaymentType.map((type, index) => (
                                                        <MenuItem
                                                            className={formClasses.menuItemContainer}
                                                            key={index}
                                                            value={type.typeOfBilling}
                                                        >
                                                            {type.typeOfBilling}
                                                        </MenuItem>
                                                    ))}
                                                </StyledSelect>
                                            </FormControl>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className={`${formClasses.inputContainer} ${isMobile ? '' : formClasses.horizontalSpacing}`}>
                            <Controller
                                name="IndustryIds"
                                control={control}
                                render={({field, fieldState}) => {
                                    const handleChange = (event: SelectChangeEvent<typeof industry>) => {
                                        const {target: { value } } = event;
                                        const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                        
                                        field.onChange(selectedValues);
                                        setIndustry(selectedValues);

                                        const selectedIndustryIds = fetchIndustries
                                        .filter(industryObj => selectedValues.includes(industryObj.industryName))
                                        .map(industryObj => industryObj.id);

                                        setValue('IndustryIds', selectedIndustryIds as unknown as never[]);
                                    };
                                    return (
                                        <div className={formClasses.customFormErrorsContainer}>
                                            {fieldState.error && (
                                                    <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                        {fieldState.error.message}
                                                    </Typography>
                                            )}
                                            <FormControl className={formClasses.selectContainer}>
                                                <InputLabel 
                                                    id="field-of-industry" 
                                                    className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                        Industrija / e
                                                </InputLabel>
                                                <StyledSelect
                                                    {...field}
                                                    required={false}
                                                    labelId="field-of-industry"
                                                    multiple
                                                    value={industry}
                                                    onChange={handleChange}
                                                    input={
                                                        <OutlinedInput 
                                                            label="Industrija / e" 
                                                            className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                        />
                                                    }
                                                    error={!!fieldState.error}
                                                    inputRef={field.ref}
                                                    renderValue={(selected) => {
                                                        return (
                                                            <Box className={formClasses.chipContainer}>
                                                                {selected.map((value: any) => (
                                                                    <Chip key={value} label={value} />
                                                                ))}
                                                            </Box>
                                                        )  
                                                    }}
                                                >
                                                    {fetchIndustries.map((industryObj) => (
                                                        <MenuItem
                                                            key={industryObj.id}
                                                            value={industryObj.industryName}
                                                        >
                                                            {industryObj.industryName}
                                                            {industry.includes(industryObj.industryName) ? <CheckCircleIcon className={formClasses.checkIcon}/> : undefined}
                                                        </MenuItem>
                                                    ))}
                                                </StyledSelect>
                                            </FormControl>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                        <div className={`${isMobile ? formClasses.pt15 : ''} ${isMobile ? formClasses.inputContainerMobile : ''}`}>
                            <Controller
                                name="OfferedAccomodation"
                                control={control}
                                render={({ field, fieldState }) => {
                                    return (
                                        <div className={formClasses.additionalBenefitsContainer}>
                                            <Checkbox 
                                                {...field}
                                                checked={!!field.value}
                                                className={formClasses.checkAdditionalBenefits}
                                            />
                                            <Typography className={formClasses.benefitsText}>Smeštaj</Typography>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                        <div className={formClasses.inputContainerMobile}>
                            <Controller
                                name="OfferedFood"
                                control={control}
                                render={({ field, fieldState }) => {
                                    return (
                                        <div className={formClasses.additionalBenefitsContainer}>
                                            <Checkbox 
                                                {...field}
                                                checked={!!field.value}
                                                className={formClasses.checkAdditionalBenefits}
                                            />
                                            <Typography className={formClasses.benefitsText}>Hrana</Typography>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                        <div className={formClasses.inputContainerMobile}>
                            <Controller
                                name="OfferedTransport"
                                control={control}
                                render={({ field, fieldState }) => {
                                    return (
                                        <div className={formClasses.additionalBenefitsContainer}>
                                            <Checkbox 
                                                {...field}
                                                checked={!!field.value}
                                                className={formClasses.checkAdditionalBenefits}
                                            />
                                            <Typography className={formClasses.benefitsText}>Prevoz</Typography>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
                </form>
                <div className={formClasses.inputContainerTop}>
                    <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={`${isMobile ? formClasses.inputContainerMobile : ''} `}>
                                <StyledButton
                                    style={{ textTransform: 'none'}}
                                    className={formClasses.w100pi}
                                    variant="contained"
                                    onClick={handleButtonClick}
                                    >
                                        {loading ? <CircularProgress 
                                                    size={24}
                                                    className="circular-progress"
                                                />
                                                : ' Pretraži'}
                                </StyledButton>
                            </div>
                            {searchErrorModal && (
                                <Backdrop className={formClasses.zIndexHighest} open={searchErrorModal}>
                                    <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setSearchErrorModal(false)} severity="error">Došlo je do greške!</Alert>
                                </Backdrop>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={`${isMobile ? ' ' : formClasses.horizontalSpacing}`}>
                                <StyledButton
                                    style={{ textTransform: 'none'}}
                                    className={formClasses.w100pi}
                                    variant="outlined"
                                    onClick={handleResetFilters}
                                    >
                                        Resetuj filtere
                                </StyledButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={formClasses.inputContainerTop}>
                    <Divider />
                </div>
                <div ref={ref} className={formClasses.bodyItemsHolder}>
                    {!adsLoading && (
                        <>
                            {filterApplied && fetchFilteredAds.length === 0 ? (
                                <div className={`${formClasses.pl12} ${formClasses.pr12}`}>
                                    {fetchAds.map((addDetails: any, index: number) => (
                                        <AdsCard key={index} addDetails={addDetails} />
                                    ))}
                                </div>
                            ) : (
                                <div className={`${formClasses.pl12} ${formClasses.pr12}`}>
                                    {filterApplied ? (
                                        fetchFilteredAds.map((addDetails: any, index: number) => (
                                            <AdsCard key={index} addDetails={addDetails} />
                                        ))
                                    ) : (
                                        fetchAds.map((addDetails: any, index: number) => (
                                            <AdsCard key={index} addDetails={addDetails} />
                                        ))
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Grid>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
        </Grid>
    )
}

export default AdsDashboard;