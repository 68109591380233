import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles((theme) => ({
    '& .MuiInputLabel-root': {
        zIndex: '0 !important',
        fontWeight: 'bold !important',
        display: 'block !important',
    }
}));

export default useStyles