import Grid from "@mui/material/Grid/Grid"
import FunctionalHeader from "../../functionalHeader/functionalHeader";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Alert from '@mui/material/Alert';
import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import { useMediaQueries } from "../../../utils/global-utils";
import { useAuthContext } from "../../../context/UserContext";
import { Controller, useForm } from "react-hook-form";
import { StyledInput } from "../../styledComponents/styledInput/styledInput";
import StyledButton from "../../styledComponents/styledButton.module";
import AdminApi from "../../../services/adminApi";


function AdminPanel() {
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const { accessToken } = useAuthContext();
    const [successModal, setSuccessModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        handleSubmit,
        formState,
        control,
        trigger,
        reset } = useForm({
            defaultValues: {
                paymentReference: '',
            }
        });

    const onSubmit = async (formData: any) => {
        try {
            const response: any = await AdminApi.ApproveTheAd(accessToken, formData);
            reset({
                paymentReference: '',  
            })
            const { status } = response;
            if (status === 200) {
                setSuccessModal(true);
            }
        } catch (error) {
            setErrorModal(true)
        }
    }

    const handleButtonClick = async () => {
        try {
            await trigger();
            if (Object.keys(formState.errors).length === 0) {
                setLoading(true);
                await handleSubmit(onSubmit)();
                setLoading(false);
            }
        }
        catch (err) {
            //
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <FunctionalHeader />
                        </Grid>
                    </Grid>
                    <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                        <div className={formClasses.companyNameHeadline}>
                            Admin panel
                        </div>
                    </div>
                    <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className={formClasses.bodyItemsHolder}>
                                <form onSubmit={handleButtonClick} className={isMobile ? formClasses.mb24 : ''}>
                                    <div className={formClasses.inputContainer}>
                                        <Controller
                                            name="paymentReference"
                                            control={control}
                                            rules={{ 
                                                required: 'Polje je obavezno'
                                            }}
                                            render={({ field, fieldState }) => {
                                                return (
                                                    <StyledInput
                                                        variant="outlined"
                                                        required={true}
                                                        type="text"
                                                        label="Unesi poziv na broj"
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                        inputRef={field.ref}
                                                        {...field}
                                                    />
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className={formClasses.inputContainer}>
                                        <StyledButton
                                            style={{ textTransform: 'none' }}
                                            variant="contained"
                                            className={isMobile ? formClasses.w100pi : ' '}
                                            onClick={handleButtonClick}
                                        >
                                            {loading ? <CircularProgress 
                                                size={24}
                                                className="circular-progress"
                                            />
                                            : 'Odobri oglas'}
                                        </StyledButton>
                                    </div>
                                    {successModal && (
                                        <Backdrop className={formClasses.zIndexHighest} open={successModal}>
                                            <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setSuccessModal(false)} severity="success">Uspešno odobravanje oglasa</Alert>
                                        </Backdrop>
                                    )}
                                    {errorModal && (
                                        <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                                            <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModal(false)} severity="error">Nema porudzbine sa ovim pozivom na broj</Alert>
                                        </Backdrop>
                                    )}
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

}

export default AdminPanel