import useStyles from "./spinningIcon.module";
import spinningIcon from '../../../assets/logo_blue.svg'

function StyledSpinningIcon() {

const classes = useStyles();

return (
    <>
        <img className={classes.spinnerIcon} alt="Sezonal" src={`${spinningIcon}`}/>
    </>
        
    )
}  

export default StyledSpinningIcon