
import Grid from "@mui/material/Grid/Grid"
import useFormStyles from "../../styledComponents/globalFormStying.module";
import MobileHeader from "../../mobileHeader/mobileHeader";
import { useMediaQueries } from "../../../utils/global-utils";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import AddApi from "../../../services/addApi";
import { useEffect, useRef, useState } from "react";
import Divider from "@material-ui/core/Divider/Divider";
import { FilteredAdResponse } from "../../../utils/types.d";
import AdsCard from "../adsCard/adsCard";
import BODY_ITEMS from "../../../assets/bodyItems";

function AdsBulletLinks () {
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const [filterApplied, setFilterApplied] = useState(false);
    const [fetchFilteredAds, setFetchFilteredAds] = useState<FilteredAdResponse[]>([]);
    const ref = useRef<HTMLDivElement | null>(null);
    const { token } = useParams();

    useEffect(() => {
        const fetchData = async () => {

          try {
            const response: any = await AddApi.GetFilteredAds(`?${token}`);
            const { data, status } = response;
            setFetchFilteredAds(data);
            if (status === 200) {
                setFilterApplied(true)
            }
            
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, [token]);

    return (
    <Grid container>
        <Grid item xs={0} sm={0} md={2}>
            <div className={formClasses.bannersContainer}></div>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <MobileHeader />
                </Grid>
            </Grid>
            <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                <div className={formClasses.companyNameHeadline}>
                    <ArrowBackIcon 
                        className={formClasses.navigateBack}
                        onClick={() => navigate(-1)} /> Pregledaj oglase
                </div>
            </div>
            <div className={formClasses.inputContainerTop}>
                <Divider />
            </div>
            <div ref={ref} className={formClasses.bodyItemsHolder}>
                {filterApplied && fetchFilteredAds.length === 0 ? (
                    <>
                        <Grid container justifyContent="space-between" alignItems="center" className={formClasses.middleSectionContainer}>
                            <Grid item xs={12} sm={12} md={12} className={formClasses.bodyMegaphoneContainer}>
                                <img className={formClasses.bodyMegaphone} src={`${BODY_ITEMS.NO_RESULTS}`}/>
                            </Grid>
                        
                        </Grid>
                        <Grid container justifyContent="space-between" alignItems="center" className={formClasses.middleSectionContainer}>
                            <Grid item xs={12} sm={12} md={12} className={formClasses.bodyMegaphoneContainer}>
                                <div className={formClasses.actionCardsText}>Nema rezultata u ovoj kategoriji</div>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    fetchFilteredAds.map((addDetails: any, index: number) => (
                        <AdsCard key={index} addDetails={addDetails} />
                    ))
                )}
            </div>
        </Grid>
        <Grid item xs={0} sm={0} md={2}>
            <div className={formClasses.bannersContainer}></div>
        </Grid>
    </Grid>
    )
}

export default AdsBulletLinks