import Grid from "@mui/material/Grid/Grid"
import FunctionalHeader from "../../functionalHeader/functionalHeader"
import useStyles from "./employerDashboard.module"
import useFormStyles from "../../styledComponents/globalFormStying.module";
import { 
    getFullName,
    getGreeting, 
    getUserId, 
    handleNavigate, 
    useMediaQueries 
} from "../../../utils/global-utils";
import Card from "@mui/material/Card/Card";
import CardContent from "@mui/material/CardContent/CardContent";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/UserContext";
import AddApi from "../../../services/addApi";
import { useEffect, useState } from "react";
import { EmployerAddResponse, NumberOfActiveAds } from "../../../utils/types.d";
import logoBlue from '../../../assets/logo_blue.svg'
import Backdrop from "@mui/material/Backdrop/Backdrop";
import StyledSpinningIcon from "../../styledComponents/spinningIcon/spinningIcon";
import SingleAd from "../singleAd/singleAd";
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from "@material-ui/core"
import PricingPackagesDialog from "../pricingPackagesDialog/pricingPackagesDialog";

function EmployerDashboard() {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile } = useMediaQueries();
    const navigate = useNavigate();
    const { control } = useForm();
    
    const [fetchEmployerAdds, setFetchEmployerAdds] = useState<EmployerAddResponse[]>([]);
    const [fetchNumberOfActiveAdds, setFetchNumberOfActiveAdds] = useState<NumberOfActiveAds>();
    const [fetchNumberOfReceivedApplications, setFetchNumberOfReceivedApplications] = useState<number>(0);
    const [loadingAds, setLoadingAds] = useState(true);
    const [, setLoading] = useState(true);

    const { userData, accessToken } = useAuthContext();
    const fullName = getFullName(userData)
    const userId =getUserId(userData);
    const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
    const [displayAdBlockerNotificationModal, setDisplayAdBlockerNotificationModal] = useState(false);
    const [, setSelectedPackageType] = useState<string | undefined>();
    const [errorModal, setErrorModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    useEffect(() => {

        const hasNotificationBeenShown = localStorage.getItem('adBlockerNotificationShown');
        if (accessToken && !hasNotificationBeenShown) {
            
            setDisplayAdBlockerNotificationModal(true);
            localStorage.setItem('adBlockerNotificationShown', 'true');
        }
        
    }, [accessToken]);

    const handleCloseAdBlickerModal = () => {
        setDisplayAdBlockerNotificationModal(false);
        
    }

    useEffect(() => {
        const fetchEmployerAds = async () => {
          try {
            if (accessToken) {
                const response: any = await AddApi.GetAllEmployerAds(accessToken, userId)
                const { data, status } = response;
                if (status ===200) {
                    setFetchEmployerAdds(data);
                }
                setLoadingAds(false);
            }
          } catch (error) {
            setLoadingAds(false);
          }
        };

        if (accessToken) {
            setLoadingAds(true);
            fetchEmployerAds();
        }
    }, [accessToken, userId]);

    useEffect(() => {
        const fetchDetails = async () => {
          try {
            if (accessToken) {
                const response: any = await AddApi.GetNumberOfActiveAds(accessToken);
                const { data, status } = response;
                if(status === 200) {
                    setFetchNumberOfActiveAdds(data)
                }
                setLoading(false);
            }
          } catch (error) {
            setLoading(false);
          }
        };

        if (accessToken) {
            setLoading(true);
            fetchDetails();
        }
    }, [accessToken]);

    useEffect(() => {
        const fetchReceivedApplications = async () => {
            try {
                const numberOfReceivedApplicationsResponse = await AddApi.getNumberOfReceivedApplications(accessToken)
    
                setFetchNumberOfReceivedApplications(numberOfReceivedApplicationsResponse.data.numberOfReceivedApplications)
            } catch (error) {
                //
            }
        }
        
        fetchReceivedApplications();
       
    }, [accessToken]);

    const handleOpenSubscriptionModal = () => {
        setOpenSubscriptionModal(true);
    }
    const handleCloseSubscriptionModal = () => {
        setOpenSubscriptionModal(false);
    }

    const handlePackageTypeSubmit = async (type: string | undefined) => {

        try {
            setSelectedPackageType(type);
            setLoading(true);
            const employerId = userData?.nameid
            const packagePurchaseData = {
                employerId: employerId,
                packageTypeId: type
            }
            const response: any = await AddApi.PackagePurchase(packagePurchaseData, accessToken);
            const { status } = response;

            if (status === 200) {
                setSuccessModal(true);
            }
            setLoading(false);
        } catch (error) {
            setErrorModal(true)
        }

        setOpenSubscriptionModal(false);
    }

    const handlePricingModalConfirm = (type: string | undefined) => {
        setSelectedPackageType(type);
        handlePackageTypeSubmit(type);
    }
    return(
        <>
        <Grid container>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <FunctionalHeader />
                    </Grid>
                </Grid>
                <div className={formClasses.headlineContainer}>
                    <div className={formClasses.companyNameHeadline}>
                        {getGreeting()}{fullName}
                    </div>
                </div>
                <Grid container className={formClasses.layoutContainer}>
                    {displayAdBlockerNotificationModal && (
                        <Dialog
                            maxWidth={'sm'}
                            open={displayAdBlockerNotificationModal}
                            aria-describedby="add-blocker-dialog"
                        >
                            <DialogContent>
                                <DialogContentText id="add-blocker-dialog">
                                    Poštovani, <br/>
                                    <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                        <span>
                                            ukoliko koristite neki od alata za blokiranje sadržaja molimo Vas da ga privremeno isključite
                                            kako biste nesmetano koristili našu aplikaciju. <br />
                                        </span>
                                    </div>
                                    <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                        <span>
                                            Hvala na razumevanju, <br />
                                        </span>
                                    </div>
                                    <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                        <span>
                                            Vaš <img height={12} alt="Sezonal" src={`${logoBlue}`}/>
                                        </span>
                                    </div>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                    onClick={() => handleCloseAdBlickerModal()}  autoFocus>
                                    Razumem
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                    <Grid item xs={6} sm={6} md={3} sx={{ mb: 1}}>
                        <Controller
                            name="addNew"
                            control={control}
                            render={({field}) => {
                                return (
                                <Card 
                                    className={`${classes.actionCardsSelected} ${isMobile ? formClasses.actionCardsMobile : ''}`}
                                    onClick={() => handleNavigate(navigate, '/new-job/')}
                                    >
                                    <CardContent>
                                        <div className={`${classes.actionCardsUpper} ${formClasses.actionCardsTextSelected}`}>
                                            <AddCircleOutlineIcon /> 
                                        </div>
                                        <div className={`${formClasses.actionCardsText} ${formClasses.actionCardsTextSelected}`}>
                                            Postavi oglas
                                        </div>
                                    </CardContent>
                                </Card>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} sx={{ mb: 1}}>
                        <Controller
                            name="buyPackage"
                            control={control}
                            render={({field}) => {
                                return (
                                <Card 
                                    className={`${classes.actionCardsSelected} ${isMobile ? formClasses.actionCardsMobile : ''}`}
                                    onClick={handleOpenSubscriptionModal}
                                    >
                                    <CardContent>
                                        <div className={`${classes.actionCardsUpper} ${formClasses.actionCardsTextSelected}`}>
                                            <AddCircleOutlineIcon /> 
                                        </div>
                                        <div className={`${formClasses.actionCardsText} ${formClasses.actionCardsTextSelected}`}>
                                            Odaberi paket oglasa
                                        </div>
                                    </CardContent>
                                </Card>
                                )
                            }}
                        />
                    </Grid>
                    {openSubscriptionModal && (
                        <Backdrop open={true}>
                            <PricingPackagesDialog 
                                open={openSubscriptionModal} 
                                onClose={handleCloseSubscriptionModal}
                                onConfirm={handlePricingModalConfirm}
                            />
                        </Backdrop>
                    )}
                    {successModal && (
                        <Backdrop className={formClasses.zIndexHighest} open={successModal}>
                            <Dialog
                                maxWidth={'sm'}
                                open={successModal}
                                aria-describedby="successful-payment-dialog"
                            >
                                <DialogContent>
                                    <DialogContentText id="successful-payment-dialog">
                                        <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                            <span>
                                                Uspešna kupovina paketa.
                                            </span>
                                        </div>
                                        <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                            <span>
                                                Sezonal će vam poslati detalje plaćanja na vašu e-mail adresu.
                                            </span>
                                        </div>
                                        <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                            <span>
                                                Vaš, <img height={12} alt="Sezonal" src={`${logoBlue}`} />
                                            </span>
                                        </div>
                                    </DialogContentText>
                                    <DialogActions>
                                        <Button
                                            onClick={() => {
                                                setSuccessModal(false);
                                                handleNavigate(navigate, `/employer-dashboard/`)
                                            }}
                                        >
                                            Razumem
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>
                        </Backdrop>
                    )}
                    {errorModal && (
                        <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                            <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModal(false)} severity="error">Došlo je do greške</Alert>
                        </Backdrop>
                    )}
                    <Grid item xs={6} sm={6} md={3} sx={{ mb: 1}}>
                        <Controller
                            name="activeNumber"
                            control={control}
                            render={({field}) => {
                                return (
                                    <Card 
                                        onClick={() => handleNavigate(navigate, '/my-active-ads')}
                                        className={`${classes.actionCards} ${isMobile ? formClasses.actionCardsMobile : ''}`}
                                        >
                                        <CardContent >
                                            <div className={`${classes.actionCardsUpper}`}>
                                                {fetchNumberOfActiveAdds?.numberOfActiveAds ? fetchNumberOfActiveAdds?.numberOfActiveAds : '0'}
                                            </div>
                                            <div className={`${formClasses.actionCardsText}`}>
                                                Aktivnih oglasa
                                            </div>
                                        </CardContent>
                                    </Card>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} sx={{ mb: 1}}>
                        <Controller
                            name="appliesNumber"
                            control={control}
                            render={({field}) => {
                                return (
                                    <Card 
                                        onClick={() => handleNavigate(navigate, '/my-received-applications')}
                                        className={`${classes.actionCards} ${isMobile ? formClasses.actionCardsMobile : ''}`}
                                        >
                                        <CardContent >
                                            <div className={`${classes.actionCardsUpper}`}>
                                               {fetchNumberOfReceivedApplications}
                                            </div>
                                            <div className={`${formClasses.actionCardsText}`}>
                                                Pristiglih prijava
                                            </div>
                                        </CardContent>
                                    </Card>
                                )
                            }}
                        />
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center" className={formClasses.middleSectionContainer}>
                        <Grid item>
                            <div className={formClasses.actionCardsText}>
                                Poslednji oglasi
                            </div>
                        </Grid>
                    </Grid>
                    <div className={formClasses.bodyItemsHolder}>
                        {loadingAds ? (
                            <Backdrop open={true}>
                                <div className={formClasses.circularProgressContainer}>
                                    <StyledSpinningIcon />
                                </div>
                            </Backdrop>
                        ) : (
                            <>
                                {fetchEmployerAdds?.map((addDetails: any, index: number) => (
                                    <SingleAd key={index} addDetails={addDetails} />
                                ))}
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
        </Grid>
           
        </>
    )
}

export default EmployerDashboard