import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles(() => ({
    uploaderContainer: {
        //display: 'flex'
    },
    uploaderThumbnail: {
        paddingTop: '12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    uploaderPaper: {
        width: '100px',
        height: '100px',
        overflow: 'hidden'
    },
    uploaderImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    }
}));

export default useStyles