import { useEffect, useState } from "react";
import { useAuthContext } from "../../../context/UserContext";
import { ReceivedApplicationsResponse } from "../../../utils/types.d";
import AddApi from "../../../services/addApi";
import DetailedCard from '../../wwwApp/detailedCard/detailedCard'
import Grid from "@mui/material/Grid/Grid"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import useFormStyles from "../../styledComponents/globalFormStying.module";
import { useMediaQueries, handleNavigate } from "../../../utils/global-utils";
import FunctionalHeader from "../../functionalHeader/functionalHeader";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import StyledSpinningIcon from "../../styledComponents/spinningIcon/spinningIcon";
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded';

function ReceivedApplications() {

    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const { accessToken } = useAuthContext();
    const navigate = useNavigate();
    const [fetchReceivedApplications, setFetchReceivedApplications] = useState<ReceivedApplicationsResponse[]>([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const fetchDetails = async () => {
          try {
            if (accessToken) {

                const receivedApplicationsResponse = await AddApi.GetReceivedApplications(accessToken)
                setFetchReceivedApplications(receivedApplicationsResponse.data)
                
                setLoading(false);
            }
          } catch (error) {
            setLoading(false);
          }
        };

        if (accessToken) {
          setLoading(true);
          fetchDetails();
        }
    }, [accessToken]);

    return (
      <>
        <Grid container>
          <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
          </Grid>
          <Grid item xs={12} sm={12} md={8} className={isMobile ? `${formClasses.ml12} ${formClasses.mr12}` : ''}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                  <FunctionalHeader />
              </Grid>
            </Grid>
            <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
              <div className={formClasses.companyNameHeadline}>
                  <ArrowBackIcon 
                      className={formClasses.navigateBack}
                      onClick={() => handleNavigate(navigate, `/employer-dashboard/`)} /> Pristigle prijave
              </div>
            </div>
            <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
              <div className={formClasses.bodyItemsHolder}>
                  {loading ? (
                    <Backdrop open={true}>
                      <div className={formClasses.circularProgressContainer}>
                          <StyledSpinningIcon />
                      </div>
                    </Backdrop>
                  ) : (
                    <>
                    {fetchReceivedApplications ? (
                      <>
                        {fetchReceivedApplications?.map((details: any, index: number) => (
                          <div key={index}>
                            <div className={`${formClasses.pb12} ${formClasses.pt12}`}>
                              <span className={formClasses.canditateName}>
                                {details.adTitle}
                              </span>
                              <span 
                                className={`${details.adStatus === 'Aktivan' ? formClasses.activeChip : formClasses.inactiveChip} ${formClasses.ml12}`}>
                                {details.adStatus}
                              </span>
                            </div>
                            {details.candidates.length === 0 ? (
                              <>
                                <div className={formClasses.inputContainerTop}>
                                  <div className={`${formClasses.canditateName} ${formClasses.ml28} ${formClasses.dflex}`}>
                                    Nema prijava na oglasu
                                    <SentimentDissatisfiedRoundedIcon className={`${formClasses.fs18i} ${formClasses.pl6}`}/>
                                  </div>
                                </div>
                              <Divider />
                              </>
                            ) : (
                              <DetailedCard  addDetails={details} adId={details.adId}/>
                            )}
                          </div>
                        ))}
                      </>
                      ) : (
                        <>
                          <div className={formClasses.inputContainerTop}>
                              <div className={`${formClasses.canditateName} ${formClasses.ml28}`}>
                                  Nemate pristiglih prijava
                              </div>
                          
                          </div>
                          <div className={formClasses.inputContainerTop}>
                              <Divider />
                          </div>
                        </>   
                      )}
                     
                    </>
                  )}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={0} md={2}>
            <div className={formClasses.bannersContainer}></div>
          </Grid>
        </Grid>
      </>
    )
}

export default ReceivedApplications