import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles((theme) => ({
    mobileDialog: {
        bottom: '30%' 
      },
      tabletDialog: {
        bottom: '20%'
      },
      mobileDialogBody: {
        maxHeight: '400px'
      },
}));

export default useStyles