import useFormStyles from "../../styledComponents/globalFormStying.module";
import { Controller, useForm } from "react-hook-form";
import { SelectChangeEvent } from "@mui/material";
import Grid from "@mui/material/Grid/Grid"
import FormControl from '@mui/material/FormControl';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Typography from "@mui/material/Typography/Typography";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import { StyledSelect } from "../../styledComponents/styledSelect/styledSelect";
import OutlinedInput from "@mui/material/OutlinedInput/OutlinedInput";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Alert from '@mui/material/Alert';
import Box from "@material-ui/core/Box/Box";
import Chip from "@material-ui/core/Chip/Chip";
import Divider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress } from "@material-ui/core";
import { handleNavigate, useMediaQueries } from "../../../utils/global-utils";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../context/UserContext";
import { useContext, useEffect, useState, useRef } from "react";
import { StyledInput } from "../../styledComponents/styledInput/styledInput";
import { Country, FilteredEmployerResponse, Industry, Place } from "../../../utils/types.d";
import BillingAndIndustriesApi from "../../../services/billingAndIndustries";
import StyledButton from "../../styledComponents/styledButton.module";
import EmployerApi from "../../../services/employerApi";
import DetailedEmployerCard from "../detailedEmployerCard/detailedEmployerCard";
import MobileHeader from "../../mobileHeader/mobileHeader";


function EmployersHubLanding() {
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const today = new Date();
    const { accessToken } = useContext(UserContext);
    const ref = useRef<HTMLDivElement | null>(null);
    const [industry, setIndustry] = useState<string[]>([]);
    const [fetchIndustries, setFetchIndustries] = useState<Industry[]>([]);
    const [fetchPlaces, setFetchPlaces] = useState<Place[]>([]);
    const [fetchCountries, setFetchCountrues] = useState<Country[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
    const [selectedCountryById, setSelectedCountryById] = useState<string>('');
    const [places, setPlaces] = useState<string[]>([]);
    const [fetchFilteredEmployers, setFetchFilteredEmployers] = useState<FilteredEmployerResponse[]>([]);
    const [fetchEmployers, setFetcEmployers] = useState<FilteredEmployerResponse[]>([]);
    const [filterApplied, setFilterApplied] = useState(false);
    const [employersLoading, setEmployersLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchErrorModal, setSearchErrorModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const employersResponse = await EmployerApi.GetAllEmployers({});
                setFetcEmployers(employersResponse.data);
                setEmployersLoading(false)
            } catch (error) {
                setEmployersLoading(false)
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

            try {
            const industriesResponse = await BillingAndIndustriesApi.GetIndustries({});
            setFetchIndustries(industriesResponse.data);
            } catch (error) {
            //
            }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const countriesResponse = await BillingAndIndustriesApi.getAllCountries({});
            setFetchCountrues(countriesResponse.data);
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const response: any = await BillingAndIndustriesApi.getAllPlaces(selectedCountryById);
            const { status } = response;
            if (status === 200) {
                setFetchPlaces(response.data);
            }
            
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, [selectedCountryById]);

    useEffect(() => {
        setPlaces([]);
    }, [selectedCountryById]);

    const {
        handleSubmit,
        formState,
        control,
        trigger,
        reset,
        setValue } = useForm({
            mode: 'onChange',
            defaultValues: {
                Search: '',
                CountryId: '',
                PlaceId: '',
                IndustryIds: [],
                NoRatings: false,
                NoActiveAds: false,
            }
    })

    const onSubmit = async (FormData: any) => { 
        const params = new URLSearchParams();
    
        if (FormData.IndustryIds.length > 0) {
            FormData.IndustryIds.forEach((id:string) => {
                params.append('IndustryIds', id);
            });
        }
    
        if (FormData.Search) {
            params.append('Search', FormData.Search);
        }
        if (FormData.CountryId) {
            params.append('CountryId', FormData.CountryId);
        }
        if (FormData.PlaceId) {
            params.append('PlaceId', FormData.PlaceId);
        }

        try {
            const query = params.toString();
            const getFilteredEmployersResponse: any = await EmployerApi.GetFilteredEmployers(`?${query}`, accessToken);
            setFetchFilteredEmployers(getFilteredEmployersResponse.data);
            const { status } = getFilteredEmployersResponse;
            if (status === 200 && getFilteredEmployersResponse.data.length > 0) {
                setFilterApplied(true)
                if (ref.current) {
                    ref.current.scrollIntoView({ behavior: 'smooth' });
                }
            }
        } catch (error) {
            setSearchErrorModal(true);
        }
    }

    const handleButtonClick = async () => {
        try {
            await trigger();
            if (Object.keys(formState.errors).length === 0) {
                setLoading(true);
                await handleSubmit(onSubmit)();
                setLoading(false);
            }
        }
        catch (err) {
            //
        }
    };

    const handleResetFilters = () => {
        reset();
        setIndustry([]);
        setSelectedCountry([])
        setPlaces([]);
        setValue('NoRatings', false);
        setValue('NoActiveAds', false);
        setFetchFilteredEmployers([]);
    }

    return (
        <Grid container>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <MobileHeader />
                    </Grid>
                </Grid>
                <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                    <div className={formClasses.companyNameHeadline}>
                        <ArrowBackIcon 
                            className={formClasses.navigateBack}
                            onClick={() => navigate(-1)} /> Pregledaj poslodavce
                    </div>
                    <div className={formClasses.inputContainerTop}>
                        <Divider />
                    </div>
                    <div className={`${formClasses.detailsText} ${formClasses.mt12}`}>
                        <span>Za detaljniji pregled svih poslodavaca, molimo Vas da se registrujete ili prijavite</span>
                    </div>
                </div>
                <form onSubmit={handleButtonClick}>
                    <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={formClasses.inputContainer}>
                                <Controller 
                                    name="Search"
                                    control={control}
                                    render={({field, fieldState}) => {
                                        return (
                                            <StyledInput
                                                {...field}
                                                disabled={false}
                                                variant="outlined"
                                                required={false}
                                                type="text"
                                                label="Naziv poslodavca"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                                inputRef={field.ref}
                                            />
                                        )
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={`${formClasses.inputContainer} ${isMobile ? '' : formClasses.horizontalSpacing}`}>
                                <Controller
                                    name="CountryId"
                                    control={control}
                                    render={({field, fieldState}) => {
                                        const handleCountryChange = (event: SelectChangeEvent<typeof selectedCountry>) => {
                                            const { target: { value } } = event;
                                            const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                            field.onChange(selectedValues);
                                            setSelectedCountry(selectedValues)
    
                                            const selectedCountryId: string | undefined = fetchCountries.find(
                                                (place) => place.countryName === value
                                            )?.id
    
                                            setSelectedCountryById(selectedCountryId as string)
                                            setValue('CountryId', selectedCountryId || '');
                                        }
                                        return (
                                            <div className={formClasses.customFormErrorsContainer}>
                                                {fieldState.error && (
                                                    <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                        {fieldState.error.message}
                                                    </Typography>
                                                )}
                                                <FormControl className={formClasses.selectContainer}>
                                                    <InputLabel 
                                                        id="select-country" 
                                                        className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                            Država
                                                    </InputLabel>
                                                    <StyledSelect
                                                        required={false}
                                                        labelId="select-country"
                                                        value={selectedCountry}
                                                        onChange={handleCountryChange}
                                                        input={
                                                            <OutlinedInput 
                                                                label="Država" 
                                                                className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                            />
                                                        }
                                                        error={!!fieldState.error}
                                                        inputRef={field.ref}
                                                    >
                                                        {fetchCountries.slice().sort((a, b) => a.countryName.localeCompare(b.countryName))
                                                            .map((region, index) => (
                                                                <MenuItem
                                                                    className={formClasses.menuItemContainer}
                                                                    key={index} 
                                                                    value={region.countryName}
                                                                >
                                                                    {region.countryName}
                                                                </MenuItem>
                                                        ))}
                                                    </StyledSelect>
                                                </FormControl>
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={`${formClasses.inputContainer} ${isMobile ? '' : formClasses.horizontalSpacing}`}>
                                <Controller
                                    name="PlaceId"
                                    control={control}
                                    render={({field, fieldState}) => {
                                        const handleCityChange = (event: SelectChangeEvent<typeof places>) => {
                                            const { target: { value } } = event;
                                            const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                            field.onChange(selectedValues);
                                            setPlaces(selectedValues)

                                            const selectedCityId = fetchPlaces.find(
                                                (place) => place.city === value
                                            )?.id

                                            setValue('PlaceId', selectedCityId || '');
                                        }
                                        return (
                                            <div className={formClasses.customFormErrorsContainer}>
                                                {fieldState.error && (
                                                    <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                        {fieldState.error.message}
                                                    </Typography>
                                                )}
                                                <FormControl className={formClasses.selectContainer}>
                                                    <InputLabel 
                                                        id="select-city" 
                                                        className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                            Grad
                                                    </InputLabel>
                                                    <StyledSelect
                                                        {...field}
                                                        required={false}
                                                        labelId="select-city"
                                                        value={places}
                                                        onChange={handleCityChange}
                                                        input={
                                                            <OutlinedInput 
                                                                label="Grad" 
                                                                className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                            />
                                                        }
                                                        error={!!fieldState.error}
                                                        inputRef={field.ref}
                                                    >
                                                        {fetchPlaces.slice().sort((a, b) => a.city.localeCompare(b.city))
                                                            .map((region, index) => (
                                                                <MenuItem
                                                                    key={index} 
                                                                    value={region.city}
                                                                >
                                                                    {region.city}
                                                                </MenuItem>
                                                        ))}

                                                    </StyledSelect>
                                                </FormControl>
                                            </div>
                                        )
                                    }} 
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={`${formClasses.inputContainer}`}>
                                <Controller
                                    name="IndustryIds"
                                    control={control}
                                    render={({field, fieldState}) => {
                                        const handleChange = (event: SelectChangeEvent<typeof industry>) => {
                                            const {target: { value } } = event;
                                            const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                            
                                            field.onChange(selectedValues);
                                            setIndustry(selectedValues);

                                            const selectedIndustryIds = fetchIndustries
                                            .filter(industryObj => selectedValues.includes(industryObj.industryName))
                                            .map(industryObj => industryObj.id);

                                            setValue('IndustryIds', selectedIndustryIds as unknown as never[]);
                                        };
                                        return (
                                            <div className={formClasses.customFormErrorsContainer}>
                                                {fieldState.error && (
                                                        <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                            {fieldState.error.message}
                                                        </Typography>
                                                )}
                                                <FormControl className={formClasses.selectContainer}>
                                                    <InputLabel 
                                                        id="field-of-industry" 
                                                        className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                            Industrija / e
                                                    </InputLabel>
                                                    <StyledSelect
                                                        {...field}
                                                        required={false}
                                                        labelId="field-of-industry"
                                                        multiple
                                                        value={industry}
                                                        onChange={handleChange}
                                                        input={
                                                            <OutlinedInput 
                                                                label="Industrija / e" 
                                                                className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                            />
                                                        }
                                                        error={!!fieldState.error}
                                                        inputRef={field.ref}
                                                        renderValue={(selected) => {
                                                            return (
                                                                <Box className={formClasses.chipContainer}>
                                                                    {selected.map((value: any) => (
                                                                        <Chip key={value} label={value} />
                                                                    ))}
                                                                </Box>
                                                            )  
                                                        }}
                                                    >
                                                        {fetchIndustries.map((industryObj) => (
                                                            <MenuItem
                                                                key={industryObj.id}
                                                                value={industryObj.industryName}
                                                                // className={formClasses.menuItemContainer}
                                                            >
                                                                {industryObj.industryName}
                                                                {industry.includes(industryObj.industryName) ? <CheckCircleIcon className={formClasses.checkIcon}/> : undefined}
                                                            </MenuItem>
                                                        ))}
                                                    </StyledSelect>
                                                </FormControl>
                                            </div>
                                        )
                                    }}
                                /> 
                            </div>
                        </Grid>
                    </Grid>
                </form>
                <div className={formClasses.inputContainerTop}>
                    <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={`${isMobile ? formClasses.inputContainerMobile : ''} `}>
                                <StyledButton
                                    style={{ textTransform: 'none'}}
                                    className={formClasses.w100pi}
                                    variant="contained"
                                    onClick={handleButtonClick}
                                    >
                                        {loading ? <CircularProgress 
                                                    size={24}
                                                    className="circular-progress"
                                                />
                                                : ' Pretraži'}
                                </StyledButton>
                            </div>
                            {searchErrorModal && (
                                <Backdrop className={formClasses.zIndexHighest} open={searchErrorModal}>
                                    <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setSearchErrorModal(false)} severity="error">Unesi vrednosti pretrage</Alert>
                                </Backdrop>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={`${isMobile ? ' ' : formClasses.horizontalSpacing}`}>
                                <StyledButton
                                    style={{ textTransform: 'none'}}
                                    className={formClasses.w100pi}
                                    variant="outlined"
                                    onClick={handleResetFilters}
                                    >
                                        Resetuj filtere
                                </StyledButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={formClasses.inputContainerTop}>
                    <Divider />
                </div>
                <div ref={ref} className={formClasses.bodyItemsHolder}>
                    {!employersLoading && (
                        <>
                            {filterApplied && fetchFilteredEmployers.length === 0 ? (
                                <div className={`${formClasses.pl12} ${formClasses.pr12}`}>
                                    {fetchEmployers.map((employers: any, index: number) => (
                                        <DetailedEmployerCard key={index} employerDetails={[employers]}/>
                                    ))}
                                </div>
                            ) : (
                                <div className={`${formClasses.pl12} ${formClasses.pr12}`}>
                                    {filterApplied ? (
                                        fetchFilteredEmployers.map((employers: any, index: number) => (
                                            <DetailedEmployerCard key={index} employerDetails={[employers]}/>
                                        ))
                                    ) : (
                                        fetchEmployers.map((employers: any, index: number) => (
                                            <DetailedEmployerCard key={index} employerDetails={[employers]}/>
                                        ))
                                    )}
                            </div>
                            )}
                        </>
                    )}
                </div>
            </Grid>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
        </Grid>
    )

}

export default EmployersHubLanding