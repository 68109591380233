import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles((theme) => ({
    actionButtonsContainer: {
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'end'
    },
    actionButtonsContainerMobile: {
        marginTop: '24px'
    },
    actionButtonsHolder: {
        marginBottom: '12px'
    },
    addDescriptionContainer: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    addDescriptionText: {
        fontSize: '14px',
        color: '#636366'
    },
    handleLongTexts: {
        width: '95%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    moreInfoDetails: {
        fontSize: '14px',
        fontWeight: 'bold',
        paddingLeft: '6px',
        paddingRight: '6px'
    },
    table: {
        borderRadius: '18px !important',
    },
    addDescriptionTextPreview: {
        fontSize: '16px',
        color: '#636366'
    },
    tableRow: {
        '&:nth-child(odd)': {
            backgroundColor: '#EBEFFF !important'
        }
    },
    detailsText: {
        color: '#636366 !important',
        fontSize: '14px !important',
        fontWeight: 'bold !important'
    },
    detailsHeadlines: {
        color: '#636366 !important',
        fontSize: '14px !important',
    },
}));

export default useStyles