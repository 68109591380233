import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles((theme) => ({
    ratingsContainer: {
        paddingTop: '24px',
        paddingBottom: '24px'
    },
    ratingDetailsUpper: {
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '6px'
    },
    star: {
        fontSize: '12px !important',
        color: '#FFD740 !important'
    },
    ratingDetails: {
        display: 'flex', 
        alignItems: 'center'
    },
    addedDate: {
        display: 'flex', 
        justifyContent: 'flex-end'
    },
    date: {
        fontSize: '14px',
        color: '#B8B8BD'
    },
    ratingDetailsBottom: {
        display: 'flex', 
        height: '41px',
        width: '100%',
        backgroundColor: '#EBEFFF',
        borderRadius: '12px'
    },
    ratingDetailsText: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '12px',
        justifyContent: 'center',
    },
}));

export default useStyles