import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles((theme) => ({
    addDescriptionTextPreview: {
        fontSize: '16px',
        color: '#636366',
        display: 'inline-block',
        maxWidth: '100%',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word'
    },
    table: {
        borderRadius: '18px !important',
    },
    tableRow: {
        '&:nth-child(odd)': {
            backgroundColor: '#EBEFFF !important'
        }
    },
    detailsText: {
        color: '#636366 !important',
        fontSize: '14px !important',
        fontWeight: 'bold !important'
    },
    detailsHeadlines: {
        color: '#636366 !important',
        fontSize: '14px !important',
    },
    companyNameRatingsContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

export default useStyles