import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid/Grid';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import Avatar from '@mui/material/Avatar';
import logoBlue from '../../assets/logo_blue.svg'
import useStyles from './functionalHeader.module';
import useFormStyles from "../styledComponents/globalFormStying.module";
import {getFullName, getInitials, handleNavigate, useMediaQueries } from '../../utils/global-utils';
import { useAuthContext } from "../../context/UserContext";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import jwt_decode from 'jwt-decode';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import PolicySharpIcon from '@mui/icons-material/PolicySharp';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import EmployeeApi from '../../services/employeeApi';
import StyledProfilePicture from '../styledComponents/profileImage/profileImage';



function FunctionalHeader() {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { userData, logout, accessToken } = useAuthContext();
    const fullName = getFullName(userData);
    const initials = getInitials(userData)
    const [userRole, setUserRole] = useState<string | null>(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [fetchprofilePicture, setFetchProfilePicture] = useState<any>([]);
    
    useEffect(() => {
        if (accessToken) {
          try {
            const decodedAccessToken: any = jwt_decode(accessToken);
            setUserRole(decodedAccessToken.role);
          } catch (error) {
            //
          }
        }
    }, [accessToken]);

    useEffect(() => {
        const fetcProfilePicture = async () => {
          try {
            if (accessToken) {

                const response: any = await EmployeeApi.ApiGetProfilePicture(accessToken);
                const { status, data } = response;
                if (status === 200) { 
                    setFetchProfilePicture(data)
                }
            }
          } catch (error) {
            //
          }
        };

        if (accessToken) {
            fetcProfilePicture();
        }
    }, [accessToken]);
    const handleNavigateToProfile = () => {
        if (userRole === 'employer') {
            handleNavigate(navigate,  `/employer-profile/` )
        } 
        if (userRole === 'employee') {
            handleNavigate(navigate, '/employee-profile/' ) 
        }
    }
    
    const open = Boolean(anchorEl);
    const id = open ? 'notifier-popover' : undefined;

    const handleLogout = () => {
        logout();
    };

    const handleNavigateToDashboard = () => {
        if (userRole === 'employer') {
            handleNavigate(navigate,  `/employer-dashboard/` )
        } 
        if (userRole === 'employee') {
            handleNavigate(navigate, '/employee-dashboard/' )
            
        }
    }

    return (
        <>
       
        <AppBar className={isMobile ? classes.functionalHeaderMobile : classes.functionalHeader}>
            <Toolbar>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <div
                            onClick={handleNavigateToDashboard}
                            className={classes.logoContainer}>
                            <img className={classes.logo} alt="Sezonal" src={`${logoBlue}`}/>
                        </div>
                    </Grid>
                    <Grid item>
                        <div
                            onClick={() => setOpenDrawer(!openDrawer)} 
                            className={classes.notifierContainer}>
                            {fetchprofilePicture?.profilePicture ? (
                                <div className={classes.avatarHolderImage}>
                                    <StyledProfilePicture
                                        style={{ pointerEvents: 'none' }}
                                        width={36}
                                        height={36}
                                        alt={`profile-image`}
                                        imageData={fetchprofilePicture?.profilePicture as string}
                                    />
                                </div>
                            ) : (
                                <Avatar
                                    aria-describedby={id}
                                    aria-haspopup="true"
                                    
                                    className={classes.avatarHolder}
                                >
                                {initials}
                                </Avatar>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        <Drawer 
            anchor="right" 
            open={openDrawer} 
            onClose={() => setOpenDrawer(false)}
            variant="temporary"
            PaperProps={{
                style: {
                  width: isMobile ? '80%' : '20%' ,
                  display: 'flex',
                  flexDirection: 'column',
                },
            }}
        >
            <List>
                <div className={formClasses.p16}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleNavigateToProfile}>
                            <ListItemIcon>
                                <AccountCircleSharpIcon className={classes.drawerIcons} />
                            </ListItemIcon>
                                {fullName ? (
                                    <ListItemText primary={`Moj profil (${fullName})`} />
                                ) : (
                                    <ListItemText primary={`Moj profil`} />
                                )}
                                
                        </ListItemButton>
                    </ListItem>
                </div>
                <div className={formClasses.p16}>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleNavigate(navigate,  `/terms-and-conditions` )}
                        >
                            <ListItemIcon>
                                <DescriptionSharpIcon className={classes.drawerIcons} />
                            </ListItemIcon>
                            <ListItemText primary={'Uslovi korišćenja'} />
                        </ListItemButton>
                    </ListItem>
                </div>
                <div className={formClasses.p16}>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleNavigate(navigate, '/privacy-policy')}
                        >
                            <ListItemIcon>
                                <PolicySharpIcon className={classes.drawerIcons}/>
                            </ListItemIcon>
                            <ListItemText primary={'Politika privatnosti'} />
                        </ListItemButton>
                    </ListItem>
                </div>
                <Divider />
                <div className={`${formClasses.inputContainerTop} ${formClasses.p16}`}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutIcon className={classes.drawerIcons} />
                            </ListItemIcon>
                            <ListItemText primary={'Odjavi se'} />
                        </ListItemButton>
                    </ListItem>
                </div>
            </List>
        </Drawer>
        </>
    )
}

export default FunctionalHeader