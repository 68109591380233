import { useNavigate } from "react-router-dom";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import { 
    formatBillingType,
    formatCity,
    formatCountry,
    formatCurrency,
    formatDate, 
    generateOfferedItemsText, 
    handleNavigate, 
    useMediaQueries 
} from "../../../utils/global-utils";
import Grid from "@mui/material/Grid/Grid"
import Chip from "@mui/material/Chip/Chip";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Divider from "@mui/material/Divider/Divider";
import classNames from 'classnames';
import useStyles from "./adsCardLanding.module";

function AdsCardLanding ({ addDetails }: any) {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();

    const standardAdType =  addDetails.adType === 'Standardni oglas';
    const sponsoredAdType = addDetails.adType === 'Sponzorisani oglas';
    const premiumAdType = addDetails.adType === 'Premium oglas';

    const containerClassName = classNames({
        [classes.detailedCardContainerRegular]: standardAdType,
        [classes.detailedCardContainerSponsored]: sponsoredAdType,
        [classes.detailedCardContainerPremium]: premiumAdType,
    });

    const dividerClassName =  classNames({
        [classes.dividerRegular]: standardAdType,
        [classes.dividerSponsored]: sponsoredAdType,
        [classes.dividerPremium]: premiumAdType
    });

    const handleNavigateToRegister = () => {
        handleNavigate(navigate,  `/ad-profile-preview`,  addDetails.adId)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
    return (
        <>
            <div onClick={handleNavigateToRegister} >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} className={`${containerClassName} ${isMobile ? classes.cardsHeightMobile : ''}`}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className={formClasses.mb6}>
                                    {standardAdType && (
                                        <Chip label="Standardni oglas" className={`${classes.standardChip}`} />
                                    )}
                                    {sponsoredAdType && (
                                        <Chip label="Sponzorisan oglas" className={classes.sponsoredChip} />
                                    )}
                                    {premiumAdType && (
                                        <Chip icon={<StarRoundedIcon className={formClasses.star}/>} label="Premium oglas" className={classes.premiumChip} />
                                    )}
                                </div>
                                <div>
                                    {addDetails.industries.map((industry: any, key: string) => (
                                        <Chip key={`${key}`} label={`${industry}`}  className={classes.fieldGroupChip}/>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className={`${isMobile ? classes.adTitleMobile : classes.adTitle} ${classes.headlineContainer}`}>
                                    {addDetails.adTitle}
                                </div>
                            </Grid>
                        </Grid>
                        <Divider className={dividerClassName}/>
                        <div className={`${classes.myAddDetails} ${classes.myAddContainers}`}>
                            <Grid container>
                                <Grid display={"flex"} item xs={12} sm={12} md={6}>
                                    <div className={classes.detailsTextContainer}>
                                        <PlaceIcon className={classes.explanationIcons}/> 
                                        <span className={classes.detailsText}>{formatCity(addDetails.city)}, {formatCountry(addDetails.country)}</span>
                                    </div>
                                </Grid>
                                <Grid display={"flex"} item xs={12} sm={12} md={6}>
                                    <div className={classes.detailsTextContainer}>
                                        <CalendarMonthIcon className={classes.explanationIcons}/> 
                                        <span className={classes.detailsText}>
                                            {formatDate(addDetails.workingStartDate)} / {addDetails.workingEndDate === null ? "_" : formatDate(addDetails.workingEndDate)}
                                        </span>
                                    </div>
                                </Grid>
                                <Grid display={"flex"} item xs={12} sm={12} md={6}>
                                    {addDetails?.salaryValue && addDetails?.currency && addDetails?.billingType ? (
                                        <>
                                            <div className={classes.detailsTextContainer}>
                                                <MonetizationOnIcon className={classes.explanationIcons}/> 
                                                <span className={classes.detailsText}>
                                                    {addDetails.salaryValue} {formatCurrency(addDetails.currency)}, {formatBillingType(addDetails.billingType)}
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className={classes.detailsTextContainer}>
                                                <MonetizationOnIcon className={classes.explanationIcons}/> 
                                                <span className={classes.detailsText}>Isplata po dogovoru</span>
                                            </div>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container>
                            <Grid display={"flex"} item xs={12} sm={12} md={6}>
                                {addDetails.offeredFood || addDetails.offeredAccomodation || addDetails.offeredTransport ? (
                                    <>
                                        <div className={classes.detailsTextContainer}>
                                            <CheckCircleIcon className={`${classes.checkIcon} ${classes.explanationIcons}`} />
                                            <span className={classes.detailsText}>
                                                {generateOfferedItemsText(addDetails)}
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={classes.detailsTextContainer}>
                                            <RadioButtonUncheckedIcon  className={`${classes.checkIcon} ${classes.explanationIcons}`}/>
                                            <span className={classes.detailsText}>
                                                Bez benefita
                                            </span>
                                        </div>
                                    </>
                                )}
                            </Grid>
                            <Grid display={"flex"} item xs={12} sm={12} md={6}>
                                <div className={isMobile ? classes.companyNameMobile : classes.companyName}>
                                    {addDetails.companyName}
                                    <StarRoundedIcon className={`${classes.star}`}/>
                                    <span className={classes.grade}>{addDetails.averageRating}</span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
           
        </>
    )
}

export default AdsCardLanding