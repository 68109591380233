
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import useFormStyles from '../../styledComponents/globalFormStying.module';
import MobileHeader from "../../mobileHeader/mobileHeader";
import { handleNavigate, useMediaQueries } from "../../../utils/global-utils";
import StyledButton from "../../styledComponents/styledButton.module";
import { APP_AUTH } from '../../../environment';

function EmailVerify() {
  const formClasses = useFormStyles();
  const { token } = useParams();
  const { isMobile, isTablet } = useMediaQueries();
  const navigate = useNavigate();

  useEffect(() => {

    const requestBody = { token: token };

    const urlPost = `${APP_AUTH}/User/verify-email`;
    const verifyEmail = async () => {
      try {

          await axios.post(urlPost, requestBody, {
            headers: {
            'Content-Type': 'application/json'
          }}
        );
      } catch (error) {
        //
      }
    };
  
      verifyEmail();
  }, []);

  return (
    <>
      
      <Grid container justify-content="center" className={formClasses.container}>
        <MobileHeader />
        <Grid item xs={12} sm={12} md={12}>
          <div className={isMobile ? formClasses.formContainerMobile : formClasses.formContainer}>
              <div className={formClasses.inputContainer}>
                <Typography className={formClasses.headline}>
                  Dobrodošao/la na Sezonal!
                </Typography>
                <Typography className={formClasses.headlineSmall}>
                  Tvoja verifikacija je uspešna.
                </Typography>
              </div>
              <div className={formClasses.inputContainerTop}>
                <StyledButton
                  style={{ textTransform: 'none'}}
                  onClick={() => handleNavigate(navigate, `/login`)}
                  variant="contained">
                  Nastavi na prijavu
                </StyledButton>
              </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default EmailVerify