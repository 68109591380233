import TextField, { TextFieldVariants } from '@mui/material/TextField';
import useStyles from './styledInput.module';
import React, { CSSProperties } from 'react';
import { styled } from '@mui/system';

interface StyledInputProps {
    id?: string;
    required: boolean;
    value?: string | number;
    type: string;
    label: string;
    error?: boolean;
    helperText?: string;
    defaultValue?: string;
    variant?: TextFieldVariants | undefined;
    multiline?: boolean;
    rows?: number;
    InputProps?: any;
    inputRef: any;
    style?: CSSProperties | undefined;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: any
    disabled?: boolean;
}


const StyledInputComponent = styled(TextField)(({ variant }) => ({
    width: variant === 'outlined' ? '100%' : '320px',
}));
export const StyledInput = React.forwardRef((
    { 
        id, required, value, type, label, error, defaultValue, variant,
        multiline, rows,helperText, inputRef, style, InputProps, onChange, onBlur, disabled 
    }: StyledInputProps,  
    ref: React.Ref<HTMLInputElement>) => {
    const classes = useStyles();
    return (
       <StyledInputComponent
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            className={classes.styledInput}
            style={style}
            value={value}
            required={required} 
            type={type}
            disabled={disabled}
            InputProps={InputProps}
            label={label}
            error={error}
            defaultValue={defaultValue}
            variant={variant}
            multiline={multiline}
            rows={rows}
            helperText={helperText}
            inputRef={ref}
       />
    )
})