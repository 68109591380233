import axios, { AxiosResponse } from "axios";
import { APP_CORE } from "../environment";

const GET_EMPLOYER_DETAILS = '/Employer/getEmployerDetails'
const EDIT_EMPLOYER_PROFILE = '/Employer/editEmployerProfile'
const GET_FILTERED_EMPLOYERS = '/Employer/getEmployersByFilter'
const GET_ALL_EMPLOYERS = '/Employer/getAllEmployers'

const api = axios.create({
    baseURL: APP_CORE
});

const apiGet = (operation: string, accessToken: string | null, employerId: string | undefined): Promise<AxiosResponse<any>> => {
    return new Promise((resolve, reject) => {
        api.get(operation, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                EmployerId: `${employerId}`
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error)
        })
    })
}

const apiGetFilteredEmployers = (operation: string, queryParams: string, accessToken: string | null) => {
  return new Promise((resolve, reject) => {
    api.get(operation + queryParams, {
      headers: {
          Authorization: `Bearer ${accessToken}`,
      },
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      })
  })
}

const apiGetAllEmployers = (operation: string, data: object): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
      api.get(operation, data).then((response) => {
          resolve(response);
      }).catch((error) => {
          reject(error)
      })
  })
}

const apiPut = (operation: string, data: object, accessToken: string | null) => {
    return new Promise((resolve, reject) => {
        api.put(operation, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data"
          },
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
    });
}

const GetEmployerDetails = async (accessToken: string | null, employerId: string | undefined) => {
    return apiGet(GET_EMPLOYER_DETAILS, accessToken, employerId)
}

const EditEmployerProfile = async (data: object, accessToken: string | null) => {
    return apiPut(EDIT_EMPLOYER_PROFILE, data, accessToken)
}

const GetFilteredEmployers = async (queryParams: string, accessToken: string | null) => {
    return apiGetFilteredEmployers(GET_FILTERED_EMPLOYERS, queryParams, accessToken)
}

const GetAllEmployers = async (data: object) => {
  return apiGetAllEmployers(GET_ALL_EMPLOYERS, data)
}


const EmployerApi = {
    GetEmployerDetails,
    EditEmployerProfile,
    GetFilteredEmployers,
    GetAllEmployers
}
export default EmployerApi