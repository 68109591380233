
import AppBar from '@mui/material/AppBar';
import { Grid, Toolbar } from '@mui/material';
import logo from '../../assets/logo.png'
import useStyles from './mobileHeader.module';
import { handleNavigate } from "../../utils/global-utils";
import { useNavigate } from 'react-router-dom';

function MobileHeader() {
const classes = useStyles();
const navigate = useNavigate();
 return (
    <AppBar className={classes.appBarMobile}>
        <Toolbar variant="dense" className={classes.toolBarMobile}>  
            <Grid container alignItems="center">
                <Grid 
                    item 
                    onClick={() => handleNavigate(navigate, `/`)}
                    className={classes.logoContainer}
                >
                    <img alt="logo-sezonal" src={`${logo}`} className={classes.logoMobile} />
                </Grid>
            </Grid>
        </Toolbar>
    </AppBar>
 )
}

export default MobileHeader