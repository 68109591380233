import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles((theme) => ({
    actionCards: {
        backgroundColor: '#EBEFFF !important',
        borderRadius: '12px !important',
        marginLeft: '12px',
        marginRight: '12px',
        cursor: 'pointer !important'
    },
    actionCardsSelected: {
        backgroundColor: '#0033FF !important',
        borderRadius: '12px !important',
        marginLeft: '12px',
        marginRight: '12px',
        cursor: 'pointer'
    },
    actionCardsUpper: {
        fontWeight: 'bold',
        fontSize: '30px',
        color: '#0033FF'
    },
}));

export default useStyles