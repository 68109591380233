import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles(() => ({
    employerReadOnlyContainer: {
        backgroundColor: '#EBEFFF',
        borderRadius: '12px'
    },
    personalInfoContainer: {
        minHeight: '450px',
        backgroundColor: '#EBEFFF',
        borderRadius: '12px',
        opacity: '1'
    },
    personalInfoContainerMobile: {
        minHeight: '450px',
        backgroundColor: '#EBEFFF',
        borderRadius: '12px',
        opacity: '1'
    },
    companyNameContainer: {
        paddingTop: '12px',
        paddingBottom: '18px',
        display: 'flex',
        justifyContent: 'center'
    },
    companyName: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#1C1C1E'
    }, 
    companyDescription: {
        paddingTop: '12px',
        paddingBottom: '18px',
        paddingLeft: '6px',
        paddingRight: '6px',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#636366'
    },
    handleLongTexts: {
        width: '95%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    ratingsText: {
        color: '#636366',
        fontSize: '14px'
    },
}));

export default useStyles