import { useNavigate } from "react-router-dom";
import { 
    getFullName,
    getGreeting, 
    handleNavigate, 
    useMediaQueries 
} from "../../../utils/global-utils";
import SearchIcon from '@mui/icons-material/Search';
import useFormStyles from "../../styledComponents/globalFormStying.module";
import useStyles from "./employeeDashboard.module";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid/Grid"
import FunctionalHeader from "../../functionalHeader/functionalHeader"
import { useAuthContext } from "../../../context/UserContext";
import Card from "@mui/material/Card/Card";
import Dialog from '@mui/material/Dialog';
import logoBlue from '../../../assets/logo_blue.svg'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from "@material-ui/core"
import CardContent from "@mui/material/CardContent/CardContent";
import { useEffect, useState } from "react";
import AddApi from "../../../services/addApi";
import { AdResponse } from "../../../utils/types.d";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import StyledSpinningIcon from "../../styledComponents/spinningIcon/spinningIcon";
import AdsCard from "../adsCard/adsCard";
import FavoritesApi from "../../../services/favoritesApi";

function EmployeeDashboard() {
    
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const { control } = useForm();
    const { userData, accessToken } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const [fetchAds, setFetchAds] =  useState<AdResponse[]>([])
    const [fetchNumberOfFavoriteAds, setFetchNumberOfFavoriteAds] = useState<number>(0);
    const [fetchNumberOfAppliedAds, setFetchNumberOfAppliedAds] = useState<number>(0);
    const [displayAdBlockerNotificationModal, setDisplayAdBlockerNotificationModal] = useState(false);
    const fullName = getFullName(userData);

    useEffect(() => {

        const hasNotificationBeenShown = localStorage.getItem('adBlockerNotificationShown');
        if (accessToken && !hasNotificationBeenShown) {
            
            setDisplayAdBlockerNotificationModal(true);
            localStorage.setItem('adBlockerNotificationShown', 'true');
        }
        
    }, [accessToken]);

    const handleCloseAdBlickerModal = () => {
        setDisplayAdBlockerNotificationModal(false);
        
    }

    useEffect(() => {
        const fetchData = async () => {

          try {
            const addResponse = await AddApi.GetAllAds({});
            setFetchAds(addResponse.data)
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetcFavorites = async () => {
          try {
            if (accessToken) {

                const favoriteAdsResponse = await FavoritesApi.getNumberOfFavoriteAds(accessToken);
                setFetchNumberOfFavoriteAds(favoriteAdsResponse.data)
                
                setLoading(false);
            }
          } catch (error) {
            setLoading(false);
          }
        };

        if (accessToken) {
            setLoading(true);
            fetcFavorites();
        }
    }, [accessToken]);

    useEffect(() => {
        const fetcAppliedAds = async () => {
          try {
            if (accessToken) {

                const appliedAdsResponse = await AddApi.getNumberOfAppliedAds(accessToken);
                setFetchNumberOfAppliedAds(appliedAdsResponse.data)
                
                setLoading(false);
            }
          } catch (error) {
            setLoading(false);
          }
        };

        if (accessToken) {
            setLoading(true);
            fetcAppliedAds();
        }
    }, [accessToken]);

    
    return (
        <Grid container>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <FunctionalHeader />
                    </Grid>
                </Grid>
                <div className={formClasses.headlineContainer}>
                    <div className={formClasses.companyNameHeadline}>
                        {getGreeting()}{fullName}
                    </div>
                </div>
                <Grid container className={formClasses.layoutContainer}>
                    {displayAdBlockerNotificationModal && (
                        <Dialog
                        maxWidth={'sm'}
                        open={displayAdBlockerNotificationModal}
                        aria-describedby="add-blocker-dialog"
                        >
                            <DialogContent>
                                <DialogContentText id="add-blocker-dialog">
                                    Dobri/a naš/a, <br/>
                                    <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                        <span>
                                            ukoliko koristiš neki od alata za blokiranje sadržaja molimo te da ga privremeno isključiš
                                            kako bi nesmetano koristio/la našu aplikaciju. <br />
                                        </span>
                                    </div>
                                    <div className={`${formClasses.mt12} ${formClasses.mb12}`}>
                                        <span>
                                            Iskreno tvoj, <img height={12} alt="Sezonal" src={`${logoBlue}`}/>
                                        </span>
                                    </div>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                    onClick={() => handleCloseAdBlickerModal()}  autoFocus>
                                    Razumem
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                    <Grid item xs={6} sm={6} md={3} sx={{ mb: 1}}>
                        <Controller
                            name="addSearch"
                            control={control}
                            render={({field}) => {
                                return (
                                <Card 
                                    className={`${classes.actionCardsSelected} ${isMobile ? formClasses.actionCardsMobile : ''}`}
                                    onClick={() => handleNavigate(navigate, '/ads-dashboard')}
                                    >
                                    <CardContent>
                                        <div className={`${classes.actionCardsUpper} ${formClasses.actionCardsTextSelected}`}>
                                            <SearchIcon /> 
                                        </div>
                                        <div className={`${formClasses.actionCardsText} ${formClasses.actionCardsTextSelected}`}>
                                            Pretraži oglase
                                        </div>
                                    </CardContent>
                                </Card>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} sx={{ mb: 1}}>
                        <Controller
                            name="employersSearch"
                            control={control}
                            render={({field}) => {
                                return (
                                <Card 
                                    className={`${classes.actionCardsSelected} ${isMobile ? formClasses.actionCardsMobile : ''}`}
                                    onClick={() => handleNavigate(navigate, '/employers-hub')}
                                    >
                                    <CardContent>
                                        <div className={`${classes.actionCardsUpper} ${formClasses.actionCardsTextSelected}`}>
                                            <SearchIcon /> 
                                        </div>
                                        <div className={`${formClasses.actionCardsText} ${formClasses.actionCardsTextSelected}`}>
                                            Pretraži poslodavce
                                        </div>
                                    </CardContent>
                                </Card>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} sx={{ mb: 1}}>
                        <Controller
                            name="favoriteAdds"
                            control={control}
                            render={({field}) => {
                                return (
                                    <Card 
                                        onClick={() => handleNavigate(navigate, '/my-favorite-ads')}
                                        className={`${classes.actionCards} ${isMobile ? formClasses.actionCardsMobile : ''}`}
                                        >
                                        <CardContent >
                                            <div className={`${classes.actionCardsUpper}`}>
                                                {fetchNumberOfFavoriteAds}
                                            </div>
                                            <div className={`${formClasses.actionCardsText}`}>
                                                Omiljenih oglasa
                                            </div>
                                        </CardContent>
                                    </Card>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} sx={{ mb: 1}}>
                        <Controller
                            name="appliedAdds"
                            control={control}
                            render={({field}) => {
                                return (
                                    <Card 
                                        onClick={() => handleNavigate(navigate, '/my-applied-ads')}
                                        className={`${classes.actionCards} ${isMobile ? formClasses.actionCardsMobile : ''}`}
                                        >
                                        <CardContent >
                                            <div className={`${classes.actionCardsUpper}`}>
                                                {fetchNumberOfAppliedAds}
                                            </div>
                                            <div className={`${formClasses.actionCardsText}`}>
                                                Konkurisanih oglasa
                                            </div>
                                        </CardContent>
                                    </Card>
                                )
                            }}
                        />
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center" className={formClasses.middleSectionContainer}>
                        <Grid item>
                            <div className={formClasses.actionCardsText}>
                                Najnoviji oglasi
                            </div>
                        </Grid>
                    </Grid>
                    <div className={formClasses.bodyItemsHolder}>
                        {loading ? (
                            <Backdrop open={true}>
                                <div className={formClasses.circularProgressContainer}>
                                    <StyledSpinningIcon />
                                </div>
                            </Backdrop>
                        ) : (
                            <>
                                {fetchAds && fetchAds?.map((addDetails: any, index: number) => (
                                    <AdsCard key={index} addDetails={addDetails} />
                                ))}
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
        </Grid>
    )
}

export default EmployeeDashboard;