import { useMediaQueries } from "../../../utils/global-utils";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@mui/material/Grid/Grid"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import privacyPolicyText from "../../../utils/privacyPolicy";
import MobileHeader from "../../mobileHeader/mobileHeader";

function PrivacyPolicyGeneral () {
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();

    return (
        <>
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <MobileHeader />
                    </Grid>
                </Grid>
                <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                    <div className={formClasses.companyNameHeadline}>
                        <ArrowBackIcon 
                            className={formClasses.navigateBack}
                            onClick={() => navigate(-1)} /> Politika privatnosti
                    </div>
                </div>
                
            </Grid>
        </Grid>
        <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
            <Grid item xs={12} sm={12} md={12}>
               <div className={formClasses.inputContainer}>
                    {privacyPolicyText.map((details, index) => (
                        <div key={index} className={formClasses.inputContainer}>
                            <Typography>
                                {details?.content}
                            </Typography>
                        </div>
                    ))}
               </div>
            </Grid>
        </Grid>
        </>
    )
}

export default PrivacyPolicyGeneral