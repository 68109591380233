import {  Outlet, Navigate } from "react-router-dom";
import { useAuthContext } from "../src/context/UserContext";


const PrivateRoutes = () => {
    const { isLoggedIn } = useAuthContext();
  
    return isLoggedIn ? <Outlet /> : <Navigate to="/login"/>
  };
  
export default PrivateRoutes;