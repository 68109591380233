import { useContext, useState } from "react";
import Grid from "@mui/material/Grid"
import useFormStyles from "../../styledComponents/globalFormStying.module";
import { handleNavigate, useMediaQueries } from "../../../utils/global-utils";
import MobileHeader from '../../mobileHeader/mobileHeader';
import StyledLayoutItem from "../../layoutItem/layoutItem";
import BODY_ITEMS from '../../../assets/bodyItems/index'
import Typography from "@material-ui/core/Typography";
import { StyledInput } from "../../styledComponents/styledInput/styledInput";
import { useNavigate } from 'react-router-dom';
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Alert from '@mui/material/Alert';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CircularProgress, Link } from "@material-ui/core";
import StyledButton from "../../styledComponents/styledButton.module";
import LoginUserApi from "../../../services/loginApi";
import { Controller, useForm } from "react-hook-form";
import UserContext from "../../../context/UserContext";
import jwt_decode from 'jwt-decode';



function Login(){
    const formClasses = useFormStyles();
    const [loading, setLoading] = useState(false);
    const { isMobile, isTablet } = useMediaQueries();
    const [errorModal, setErrorModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { setTokens } = useContext(UserContext);
 
    const navigate = useNavigate();

    const { 
        handleSubmit,
        formState,
        control,
        trigger, 
        reset } = useForm({
            mode: 'onChange',
            defaultValues: {
                emailAddress: '',
                password: '',
            }
    });

    const onSubmit = async (formData: any) => {
        try {  
            const response: any = await LoginUserApi.LoginUser(formData);
            const { idToken, accessToken } = response;
            setTokens(idToken, accessToken)
            reset({
                emailAddress: '',
                password: '',
            })

            const decodedAccessToken: any = jwt_decode(response.accessToken);
            if (decodedAccessToken.role === 'employer') {
                navigate(`/employer-dashboard/`, { replace: true })
            } 
            if (decodedAccessToken.role === 'employee') {
                navigate('/employee-dashboard/', { replace: true })
            }
            if (decodedAccessToken.role === 'admin') {
                navigate('/admin-panel/', { replace: true })
            }
        } catch (error) {
            setErrorModal(true)
        }
    };

    const handleButtonClick = async () => {
        try {
            await trigger();
            if (Object.keys(formState.errors).length === 0) {
                setLoading(true);
                await handleSubmit(onSubmit)();
                setLoading(false);
            }
        }
        catch (err) {
            //
        }
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <section>
                <Grid container className={formClasses.container}>
                    {(isMobile || isTablet) ? (
                        <MobileHeader />
                    ) : (
                        <StyledLayoutItem 
                            alt="construct"
                            imageSrc={`${BODY_ITEMS.CONSTRUCT}`}
                            children
                        />
                    )}
                    <Grid item xs={12} sm={12} md={6}>
                        <div className={isMobile ? formClasses.formContainerMobile : formClasses.formContainer}>
                            <div className={formClasses.formText} >
                                <Typography className={formClasses.headline}>
                                    Dobrodošli nazad na Sezonal!
                                </Typography>
                            </div>
                            <form onSubmit={handleButtonClick}>
                                <div className={formClasses.inputContainer}>
                                    <Controller 
                                        name="emailAddress"
                                        control={control}
                                        rules={{ 
                                            required: 'Polje obavezno',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Unesite validnu e-mail adresu',
                                            }, 
                                        }}
                                        render={({field, fieldState}) => {
                                            return (
                                                <StyledInput
                                                    required
                                                    type="text"
                                                    label="E-mail adresa"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                    inputRef={field.ref}
                                                    {...field}
                                                />
                                            )
                                            
                                        }}
                                    />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{ 
                                            required: 'Polje je obavezno',
                                        }}
                                        render={({ field, fieldState }) => {
                                            return (
                                                <>
                                                    <StyledInput
                                                        {...field}
                                                        required
                                                        value={field.value}
                                                        type={showPassword ? 'text' : 'password'}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <IconButton 
                                                                    onMouseDown={handleTogglePassword}
                                                                    onMouseUp={handleTogglePassword}
                                                                    onMouseLeave={() => setShowPassword(false)}
                                                                    onTouchStart={handleTogglePassword}
                                                                    onTouchEnd={handleTogglePassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        label="Lozinka"
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                        inputRef={field.ref}
                                                    />
                                                    
                                                    <div className={formClasses.inputContainerSmall}>
                                                        <Typography className={formClasses.manageConsent}>
                                                            <Link onClick={() => handleNavigate(navigate, `/forgot-password-email`)} className={formClasses.manageConsentLink}>Zaboravio/la si lozinku?</Link>
                                                        </Typography>
                                                    </div>
                                                   
                                                </>
                                            )
                                        }}
                                    />
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <StyledButton
                                        style={{ textTransform: 'none'}}
                                        onClick={handleButtonClick}
                                        variant="contained"
                                    >
                                        {loading ? <CircularProgress 
                                                size={24}
                                                className="circular-progress"
                                            />
                                            : 'Prijavi se'}
                                    </StyledButton>
                                </div>
                                <div className={formClasses.inputContainer}>
                                    <StyledButton 
                                        onClick={() => handleNavigate(navigate,  `/` )}
                                        style={{ textTransform: 'none'}}
                                        variant="text" 
                                    >
                                        Vrati se nazad
                                    </StyledButton>
                                </div>
                                {errorModal && (
                                    <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                                        <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModal(false)} severity="error">Pogrešan mejl ili lozinka, molimo pokušajte ponovo.</Alert>
                                    </Backdrop>
                                )}
                                <div className={formClasses.manageConsentContainerCenter}>
                                    <Typography className={formClasses.manageConsent}>
                                        Nemaš nalog? <Link onClick={() => handleNavigate(navigate, `/role-picker/`)} className={formClasses.manageConsentLink}>Registruj se</Link>
                                    </Typography>
                                </div>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </section>
        </>
    )
}

export default Login