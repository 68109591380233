import { Button, Typography } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useStyles from './termsAndConditions.module';
import { useMediaQueries } from '../../utils/global-utils';
import termsAndConditionsText from "../../utils/termsAndConditions";
import useFormStyles from '../styledComponents/globalFormStying.module';

interface TermsAndConditionsProps {
    open: boolean;
    onClose: (event: React.MouseEvent) => void;
    children: React.ReactNode;
}

function TermsAndConditionsDialog({ open, onClose }: TermsAndConditionsProps) {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={`${isMobile ? classes.mobileDialog : ' '} ${isTablet ? classes.tabletDialog : ' '}`}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Saglasnost o uslovima korišćenja platforme"}
                </DialogTitle>
                <DialogContent id="alert-dialog-description" className={isMobile ? classes.mobileDialogBody : ' '}>
                    <div className={formClasses.inputContainer}>
                        {termsAndConditionsText.map((details, index) => (
                            <div key={index} className={formClasses.inputContainer}>
                                <Typography>
                                    {details?.content}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} autoFocus>Razumem</Button>
                </DialogActions>
            </Dialog>
        </>
        
    )
}

export default TermsAndConditionsDialog