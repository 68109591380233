import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles(() => ({
    spinnerIcon: {
        animation: '$pulse 2s infinite',
      },
      '@keyframes pulse': {
        '0%, 100%': { transform: 'scale(1.6)' },
        '50%': { transform: 'scale(1.8)' },
      },
}));

export default useStyles