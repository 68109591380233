import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles((theme) => ({
    styledCardBody: {
        borderRadius: '6px',
        width: '320px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        border: '1px solid #B8B8BD',
        cursor: 'pointer',
    },
    styledCardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    avatarContainer: {
        marginTop: '20px',
        marginBottom: '12px'
    },
    image: {
        width: '36px',
        height: '36px'
    },
    avatar: {
        margin: '0 auto',
        backgroundColor: '#0033FF !important',
        width: '36px',
        height: '36px'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    cardHeadline: {
        color: '#1C1C1E !important',
        fontSize: '14px !important',
        lineHeight: '16px !important',
        fontWeight: 'bold !important',
        paddingBottom: '12px !important'
    },
    cardDescription: {
        color: '#636366 !important',
        fontSize: '12px !important',
        lineHeight: '16px !important',
        fontWeight: 'normal !important'
    }
}));

export default useStyles