import { useNavigate } from "react-router-dom";
import { useMediaQueries, handleNavigate } from "../../utils/global-utils";
import useFormStyles from "../styledComponents/globalFormStying.module";
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid/Grid';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import logo from '../../assets/logo.png'
import useStyles from "./landingHeader.module";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';



function LandingHeader() {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile } = useMediaQueries();
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleNavigateToBrowseAds = () => {
        handleNavigate(navigate, `/browse-ads`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    const handleNavigateToBrowseEmployers = () => {
        handleNavigate(navigate, `/browse-employers`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleNavigateToLogin = () => {
        handleNavigate(navigate, `/login`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleNavigateToRegister = () => {
        handleNavigate(navigate, `/role-picker/`);
    }

    return (
        <>
            <AppBar>
                <Toolbar>
                    <Grid container justifyContent="flex-start" alignItems="center">
                        <Grid item>
                            <img alt="logo-sezonal" src={`${logo}`} className={classes.logoLanding}/>
                        </Grid>
                        
                        <Grid item>
                            <div
                                onClick={() => handleNavigateToBrowseAds()} 
                                className={isMobile ? formClasses.dne :  `${formClasses.headerMenuItems} ${formClasses.ml18}`}>
                                Oglasi
                            </div>
                        </Grid>
                        <Grid item>
                            <div
                                onClick={() => handleNavigateToBrowseEmployers()}
                                className={isMobile ? formClasses.dne :  formClasses.headerMenuItems}>
                                Poslodavci
                            </div>
                        </Grid>
                        
                    </Grid>
                    <Grid container justifyContent="flex-end" alignItems="center">
                        <Grid item>
                            <div
                                onClick={() => handleNavigateToLogin()} 
                                className={isMobile ? formClasses.dne :  formClasses.headerMenuItems}>
                                Prijavi se
                            </div>
                        </Grid>
                        <Grid item>
                            <div
                                onClick={() => handleNavigateToRegister()}  
                                className={isMobile ? formClasses.dne :  formClasses.headerMenuItems}>
                                Registruj se
                            </div>
                        </Grid>
                        <Grid item>
                            <div
                                onClick={() => setOpenDrawer(!openDrawer)}  
                                className={isMobile ? formClasses.headerMenuItems :  formClasses.dne}>
                                <MenuIcon />
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                open={openDrawer}
                anchor="right"
                onClose={() => setOpenDrawer(false)}
                variant="temporary" 
                PaperProps={{
                    style: {
                      width: '80%',
                      display: 'flex',
                      flexDirection: 'column',
                    },
                }}
            >
                <List>
                    <div className={classes.drawerItemsContainer}>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleNavigateToBrowseAds()} >
                                <ListItemText
                                    className={classes.drawerNavigations}
                                    primary={'Oglasi'} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText
                                    onClick={() => handleNavigateToBrowseEmployers()}
                                    className={classes.drawerNavigations} 
                                    primary={'Poslodavci'} />
                            </ListItemButton>
                        </ListItem>
                        <div className={formClasses.inputContainerTop}>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handleNavigateToLogin()}>
                                    <ListItemText 
                                        className={classes.drawerNavigations}
                                        primary={'Prijavi se'} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handleNavigateToRegister()}>
                                    <ListItemText
                                        className={classes.drawerNavigations} 
                                        primary={'Registruj se'} />
                                </ListItemButton>
                            </ListItem>
                        </div>
                    </div>
                </List>
                
            </Drawer>
        </>
    )

}

export default LandingHeader