import { useNavigate, useParams } from "react-router-dom";
import { 
    formatBillingType,
    formatCity,
    formatCountry,
    formatCurrency,
    formatDate, 
    generateOfferedItemsText, 
    handleNavigate, 
    useMediaQueries 
} from "../../../utils/global-utils";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import StyledSpinningIcon from "../../styledComponents/spinningIcon/spinningIcon";
import Grid from "@mui/material/Grid/Grid"
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import Divider from '@mui/material/Divider';
import StyledButton from "../../styledComponents/styledButton.module";
import { AddDetails, SelectedAddResponse } from "../../../utils/types.d";
import AddApi from "../../../services/addApi";
import MobileHeader from "../../mobileHeader/mobileHeader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useStyles from "./adProfilePreview.module";
import { Typography } from "@material-ui/core";

function AdProfilePreview() {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const { addId } = useParams();
    const [fetchSelectedAdd, setfFetchSelectedAdd] = useState<SelectedAddResponse[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchDetails = async () => {
          try {

                const selectedAddResponse: any = await AddApi.GetSelectedAdNoAuth(addId);
                setfFetchSelectedAdd([selectedAddResponse.data]);
                setLoading(false);
          } catch (error) {
            setfFetchSelectedAdd([]);
            setLoading(false);
          }
        };

        fetchDetails();
    }, [])

    return (
        <Grid container>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} className={isMobile ? `${formClasses.ml12} ${formClasses.mr12}` : ''}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <MobileHeader />
                    </Grid>
                </Grid>
                <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                    <div className={formClasses.companyNameHeadline}>
                        Pregledaj oglas
                    </div>
                </div>
                <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                    <div className={`${formClasses.bodyItemsHolder}`}>
                        {loading ? (
                            <Backdrop open={true}>
                                <div className={formClasses.circularProgressContainer}>
                                    <StyledSpinningIcon />
                                </div>
                            </Backdrop>
                            ) : (
                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    {fetchSelectedAdd.map((selectedAdDetails, index) => (
                                        <div key={index}>
                                            <div className={`${formClasses.actionCardsText}`}>
                                                <span>
                                                    {selectedAdDetails?.adTitle}
                                                </span>
                                                
                                            </div>
                                            <div className={formClasses.inputContainerTop}>
                                                <span className={classes.addDescriptionTextPreview}>
                                                    {selectedAdDetails?.adDescription}
                                                </span>
                                            </div>
                                            <div className={formClasses.inputContainerTop}>
                                                <Divider />
                                            </div>
                                            <TableContainer component={Paper} className={classes.table}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow className={classes.tableRow}>
                                                            <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                                Poslodavac
                                                            </TableCell>
                                                            <TableCell  align="right" onClick={() => handleNavigate(navigate, `/role-picker`)}>
                                                                <span className={`${formClasses.fcolorLInki}`}>{selectedAdDetails?.companyName}</span>
                                                                <StarRoundedIcon className={`${formClasses.star} ${formClasses.pl6} ${formClasses.pr6} ${formClasses.pt3}`}/>
                                                                <span className={`${formClasses.grade} ${formClasses.p0i}`}>{selectedAdDetails?.averageRating}</span>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className={classes.tableRow}>
                                                            <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                                Industrija/e
                                                            </TableCell>
                                                            <TableCell align="right" className={`${classes.detailsText}`}>
                                                                {selectedAdDetails?.industries.map((industry) => industry?.industryName).join(', ')}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className={classes.tableRow}>
                                                            <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                                Država
                                                            </TableCell>
                                                            <TableCell align="right" className={`${classes.detailsText}`}>
                                                                {formatCountry(selectedAdDetails?.country?.countryName as string)}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className={classes.tableRow}>
                                                            <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                                Grad
                                                            </TableCell>
                                                            <TableCell align="right" className={`${classes.detailsText}`}>
                                                                {formatCity(selectedAdDetails?.city?.city as string)}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className={classes.tableRow}>
                                                            <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                                {selectedAdDetails?.workingEndDate === null ? 'Početak rada' : 'Trajanje'}
                                                            </TableCell>
                                                            <TableCell align="right" className={`${classes.detailsText}`}>
                                                                {formatDate(selectedAdDetails?.workingStartDate as string)} / {selectedAdDetails?.workingEndDate === null ? '_' : formatDate(selectedAdDetails?.workingEndDate as string)}
                                                            </TableCell>
                                                        </TableRow>
                                                        {selectedAdDetails?.billingType?.typeOfBilling  && (
                                                            <TableRow className={classes.tableRow}>
                                                                <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Tip naplate</TableCell>
                                                                <TableCell align="right" className={`${classes.detailsText}`}>
                                                                    {formatBillingType(selectedAdDetails?.billingType?.typeOfBilling as string)}
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                        <TableRow className={classes.tableRow}>
                                                            {selectedAdDetails?.salaryValue && selectedAdDetails?.currency?.currencyName ? (
                                                                <>
                                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Iznos</TableCell>
                                                                    <TableCell align="right" className={`${classes.detailsText}`}>
                                                                        {selectedAdDetails?.salaryValue} {formatCurrency(selectedAdDetails?.currency?.currencyName as string)}
                                                                    </TableCell>
                                                                </>
                                                        
                                                            ) : (
                                                                <>
                                                                    <TableCell variant="head" className={`${classes.detailsHeadlines}`}>Isplata</TableCell>
                                                                    <TableCell align="right" className={`${classes.detailsText}`}>Po dogovoru</TableCell>
                                                                </>
                                                        
                                                            )}
                                                        </TableRow>
                                                        <TableRow className={classes.tableRow}>
                                                            <TableCell variant="head" className={`${classes.detailsHeadlines}`}>
                                                                Benefiti
                                                            </TableCell>
                                                            <TableCell align="right" className={`${classes.detailsText}`}>
                                                                {generateOfferedItemsText(selectedAdDetails as AddDetails) || "Bez benefita"}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    ))}
                                </Grid>
                                <div className={formClasses.inputContainerTop}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <StyledButton
                                                style={{ textTransform: 'none'}}
                                                className={isMobile ? formClasses.w100pi : formClasses.w90pi}
                                                variant="contained"
                                                onClick={() => handleNavigate(navigate, `/role-picker`)}
                                            >
                                                Prijavi se
                                            </StyledButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
        </Grid>
    )
}

export default AdProfilePreview;