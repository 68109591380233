import axios from "axios";
import { APP_AUTH } from "../environment";

const LOGIN_USER = '/User/Login'

const api = axios.create({
    baseURL: APP_AUTH
})

const apiPost = (operation: string, data: object) => {

    return new Promise((resolve, reject) => {
        api.post(operation, data).then(res => {
            if ( res.status === 200) {
                const { idToken, accessToken } = res.data;
                resolve({ idToken, accessToken });
            } else {
              reject(new Error('Failed to retrieve tokens.'));
            }
        })
        .catch(error => {
            reject(error)
        });
    })
}

const LoginUser = async (data: object) => {
    return apiPost(LOGIN_USER, data)
}

const LoginUserApi = {
    LoginUser
}

export default LoginUserApi