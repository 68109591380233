import Grid from "@material-ui/core/Grid/Grid";
import useFormStyles from '../../styledComponents/globalFormStying.module';
import { handleNavigate, useMediaQueries } from "../../../utils/global-utils";
import MobileHeader from "../../mobileHeader/mobileHeader";
import StyledLayoutItem from "../../layoutItem/layoutItem";
import BODY_ITEMS from "../../../assets/bodyItems";
import Typography from "@material-ui/core/Typography/Typography";
import { StyledCard } from "../../styledComponents/styledCard/styledCard";
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Link from "@mui/material/Link";
import RegisterUserApi from "../../../services/registerApi";


function VerificationScreen() {
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();

    const navigate = useNavigate();
    const { userId } = useParams();

    const resendVerificationLink = async () => {

        try {
            await RegisterUserApi.ResendVerificationLink({userId})
        } catch (error) {
            //
        }
    }

    return (
        <>
            <Grid container className={formClasses.container}>
                {(isMobile || isTablet) ? (
                    <MobileHeader />
                ) : (  
                    <StyledLayoutItem 
                        alt="lifeguard" 
                        imageSrc={`${BODY_ITEMS.FARM}`} 
                        children
                    />
                )}
                <Grid item xs={12} sm={12} md={6}>
                    <div className={formClasses.rolePickerContainer}>
                        <div className={`${formClasses.dflexI} ${formClasses.mb20}`}>
                            <ArrowBackIcon 
                                className={formClasses.navigateBack}
                                onClick={() => navigate(-1)} />
                            <Typography className={formClasses.headline}>
                                Uspešno ste kreirali nalog!
                            </Typography>
                        </div>
                        <div className={formClasses.inputContainer}>   
                            <StyledCard
                                className={formClasses.styledCardBody}
                                cardTextHeadline="Verifikacija u toku"
                                cardTextDescription={`Sezonal Vam je poslao verifikacioni mejl, molimo Vas da proverite mejl i verifikujete nalog pomoću linka u poruci. 
                                    Ukoliko Vam nije stigla poruka molimo Vas proverite folder "nepoželjno". 
                                    Ukoliko poruke i dalje nema poslaćemo Vam novu poruku klikom na`}
                            ><Link onClick={resendVerificationLink} className={formClasses.manageConsentLink}>Link</Link></StyledCard>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default VerificationScreen;