import { useState } from "react";
import useFormStyles from "../globalFormStying.module";
import { Button } from "@material-ui/core";
import useStyles from "./imagePreviewThumbnail.module";
import Paper from '@mui/material/Paper';
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Alert from '@mui/material/Alert';
import { useMediaQueries } from "../../../utils/global-utils";

function ImageUploaderThumbnail({ onChange }: any) {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [errorModal, setErrorModal] = useState(false);

    const isImageValid = (file: File): boolean => {
        return file.type === "image/jpeg" ||  file.type === "image/png";
    };
  
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0] || null;
  
        if (file && isImageValid(file)) {
            setSelectedFile(file);
    
            const reader = new FileReader();
    
            reader.onload = (event) => {
            setImagePreview(event?.target?.result as string);
            onChange(file); 
            };
    
            reader.readAsDataURL(file);
        } else {
            setErrorModal(true)
        }
    };
  
    return (
        <>
            <div className={classes.uploaderContainer}>
                <input
                    accept=".png, .jpg, .jpeg"
                    id="file-input"
                    type="file"
                    hidden
                    onChange={handleFileChange}
                />
                <div className={classes.uploaderLabel}>
                    <label htmlFor="file-input">
                        <Button 
                            style={{ textTransform: 'none'}}
                            variant="text" 
                            component="span" 
                            className={formClasses.uploaderComponentButton}
                            >
                            Promeni profilnu sliku
                        </Button>
                    </label>
                </div>
                {errorModal && (
                    <Backdrop className={formClasses.zIndexHighest} open={errorModal}>
                        <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setErrorModal(false)} severity="error">Pogrešan format. Izaberite .jpg ili .png sliku.</Alert>
                    </Backdrop>
                )}
            </div>
            {imagePreview && (
                <div className={classes.uploaderThumbnail}>
                    <div className={formClasses.headlineSmall}>Izabrana slika:</div>
                    <Paper className={classes.uploaderPaper}>
                        <img className={classes.uploaderImage} src={imagePreview} alt="selected-image"/>
                    </Paper>
                </div>
            )}
        </>
    );
  }
  
  export default ImageUploaderThumbnail;