import { makeStyles } from  '@mui/styles';

const useStyles: any = makeStyles(() => ({

    personalInfoContainer: {
        minHeight: '450px',
        width: '50%',
        backgroundColor: '#EBEFFF',
        borderRadius: '12px',
        opacity: '1'
    },
    personalInfoContainerMobile: {
        minHeight: '450px',
        width: '100%',
        backgroundColor: '#EBEFFF',
        borderRadius: '12px',
        opacity: '1'
    },
    nameInfoContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '12px',
        marginBottom: '12px',
        marginLeft: '6px',
        marginRight: '6px'
    },
    aboutMeContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '12px',
        marginRight: '12px',
        color: '#636366',
    },
    dialogActionsContainer: {
        padding: '16px'
    },
    dialogActionsContainerMobile: {
        padding: '6px'
    },
    emailAnchor: {
        textDecoration: 'none',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#1C1C1E'
    }

}));

export default useStyles