import axios, { AxiosResponse } from "axios";
import { APP_CORE } from "../environment";

const ADD_NEW_POST = '/Ad/postAd';
const POST_AD_SECOND_STEP = '/Ad/postAdSecondStep';
const PACKAGE_PURCHASE = '/Ad/packagePurchase';
const GET_ALL_EMPLOYER_ADDS = '/Ad/GetAllEmployerAds';
const GET_ALL_EMPLOYEE_APPLIED_ADS = '/Ad/getMyAppliedAds';
const GET_ALL_ACTIVE_ADDS = '/Ad/getActiveAds';
const GET_ALL_ADDS = '/Ad/getAllAds';
const GET_SELECTED_ADD = '/Ad/getAd';
const GET_SELECTED_ADD_NO_AUTH = '/Ad/getAdNoAuth';
const DELETE_ADD = '/Ad/deleteAd';
const EDIT_ADD = '/Ad/editAd';
const CLOSE_APPLICATIONS = '/Ad/closeAdApplications';
const GET_ADD_TYPES = '/Ad/getAdTypes';
const GET_ADD_PACKAGE_TYPES = '/Ad/getAdPackageTypes';
const GET_NUMBER_OF_ACTIVE_ADDS = '/Ad/getNumberOfActiveAds';
const GET_NUMBER_OF_RECEIVED_APLICATIONS = '/Ad/getNumberOfReceivedApplications';
const GET_RECEIVED_APPLICATIONS = '/Ad/getReceivedApplications';
const GET_RECEIVED_APPLICATIONS_BY_ADID = '/Ad/getReceivedApplicationsByAd';
const GET_FILTERED_ADS = '/Ad/getFilteredAds';
const GET_NUMBER_OF_APPLIED_ADS = '/Ad/getNumberOfMyAppliedAds'
const AD_APPLICATION = '/Ad/adApplication';
const WITHDRAW_APPLICATION = '/Ad/withdrawAdApplication';

const api = axios.create({
    baseURL: APP_CORE
});


const apiGet = (
  operation: string, 
  accessToken: string | null, 
  employerId?: string | undefined,
  adId?: string | undefined
): Promise<AxiosResponse<any>> => {
return new Promise((resolve, reject) => {
  const params: Record<string, string> = {};

    if (employerId) {
      params.EmployerId = `${employerId}`;
    }

    if (adId) {
      params.AdId = `${adId}`;
    }
    
    api.get(operation, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        params
    }).then((response) => {
        resolve(response);
    }).catch((error) => {
        reject(error)
    })
  })
}

const apiGetAllAds = (operation: string, data: object, accessToken?: string | null): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
      api.get(operation, data).then((response) => {
          resolve(response);
      }).catch((error) => {
          reject(error)
      })
  })
}

const apiGetFilteredAds = (operation: string, queryParams: string) => {
  return new Promise((resolve, reject) => {
    api.get(operation+queryParams, {
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      })
  })
}

const apiPost = (operation: string, data: object, accessToken: string | null) => {
    return new Promise((resolve, reject) => {
      api.post(operation, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          data
        }
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
};


const apiDelete = (
  operation: string, 
  accessToken: string | null,
  adId: string | undefined,
  roleId: string | undefined,
  roleType: 'employee' | 'employer') => {
  return new Promise((resolve, reject) => {
    const dataObject: { [key: string]: string | undefined } = {
      employeeId: roleType === 'employee' ? roleId : undefined,
      employerId: roleType === 'employer' ? roleId : undefined,
      adId: adId
    };
    api.delete(operation, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: dataObject
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const apiPut = (
    operation: string, 
    data: object, 
    accessToken: string | null,
    ) => {
    return new Promise((resolve, reject) => {
        api.put(operation, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            data
          
          }
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
    });
}

const AddNewPost = async (data: object, accessToken: string | null) => {

    return apiPost(ADD_NEW_POST, data, accessToken);
};

const PostAdSecondStep = async (data: object, accessToken: string | null) => {
  
  return apiPost(POST_AD_SECOND_STEP, data, accessToken)
}

const PackagePurchase = async (data: object, accessToken: string | null) => {
  return apiPost(PACKAGE_PURCHASE, data, accessToken)
}

const AddAplication = async (data: object, accessToken: string | null) => {

  return apiPost(AD_APPLICATION, data, accessToken)
}

const GetAllEmployerAds = async (accessToken: string | null, employerId: string | undefined) => {
  
  return apiGet(GET_ALL_EMPLOYER_ADDS, accessToken, employerId)
}

const GetActiveEmpleyerAdds = async (accessToken: string | null) => {
  
  return apiGet(GET_ALL_ACTIVE_ADDS, accessToken)
}

const GetFilteredAds = async (queryParams: string) => {
  
  return apiGetFilteredAds(GET_FILTERED_ADS, queryParams)
}

const GetAllAds = async (data: object) => {
  
  return apiGetAllAds(GET_ALL_ADDS, data)
}

const getMyAppliedAds = async (accessToken: string | null) => {
  
  return apiGet(GET_ALL_EMPLOYEE_APPLIED_ADS, accessToken)
}

const getNumberOfAppliedAds = async  (accessToken: string | null) => {

  return apiGet(GET_NUMBER_OF_APPLIED_ADS, accessToken)
}

const GetSelectedAd = async (accessToken: string | null, adId: string | undefined) => {
  
  return apiGet(GET_SELECTED_ADD, accessToken, undefined, adId)
}

const GetSelectedAdNoAuth = async (adId: string | undefined) => {
  
  return apiGet(GET_SELECTED_ADD_NO_AUTH, null, undefined, adId)
}

const GetAdTypes = async (accessToken: string | null) => {

  return apiGet(GET_ADD_TYPES, accessToken)
}

const GetAdPackageTypes = async (accessToken: string | null) => {

  return apiGet(GET_ADD_PACKAGE_TYPES, accessToken)
}

const GetNumberOfActiveAds = async (accessToken: string | null) => {

  return apiGet(GET_NUMBER_OF_ACTIVE_ADDS, accessToken)
}

const GetReceivedApplications = async (accessToken: string | null) => {

  return apiGet(GET_RECEIVED_APPLICATIONS, accessToken)
}

const GetReceivedApplicationsByAdId = async (accessToken: string | null, adId: string | undefined) => {

  return apiGet(GET_RECEIVED_APPLICATIONS_BY_ADID, accessToken, undefined, adId)
}

const getNumberOfReceivedApplications = async (accessToken: string | null) => {

  return apiGet(GET_NUMBER_OF_RECEIVED_APLICATIONS, accessToken)
}

const DeleteAdd = (accessToken: string | null, adId: string | undefined, employerId: string | undefined) => {

  return apiDelete(DELETE_ADD, accessToken, adId, employerId, 'employer')
}

const WithdrawApplication = (accessToken: string | null, adId: string | undefined, employeeId: string | undefined) => {

  return apiDelete(WITHDRAW_APPLICATION, accessToken, adId, employeeId, 'employee')
}

const EditAdd = (accessToken: string | null, data: object) => {

  return apiPut(EDIT_ADD, data, accessToken)
}

const CloseApplications = (accessToken: string | null, data: object) => {

  return apiPut(CLOSE_APPLICATIONS, data, accessToken)

}

const AddApi = {
    AddNewPost,
    PostAdSecondStep,
    PackagePurchase,
    AddAplication,
    GetAllEmployerAds,
    GetActiveEmpleyerAdds,
    getMyAppliedAds,
    getNumberOfAppliedAds,
    GetAdTypes,
    GetAdPackageTypes,
    GetAllAds,
    GetFilteredAds,
    GetNumberOfActiveAds,
    GetReceivedApplications,
    GetReceivedApplicationsByAdId,
    getNumberOfReceivedApplications,
    GetSelectedAd,
    GetSelectedAdNoAuth,
    EditAdd,
    CloseApplications,
    DeleteAdd,
    WithdrawApplication
}

export default AddApi