import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import useStyles from './styledSelect.module';


interface StyledSelectProps {
    id?: string;
    required: boolean;
    value?: string[];
    labelId?: string;
    label?: string;
    labelText?: string;
    multiple?: boolean;
    InputProps?: any;
    inputRef?: any;
    error?: boolean;
    input?: ReactElement<any, any>;
    style?: CSSProperties | undefined;
    disabled?: boolean;
    renderValue?: (value: string[]) => any;
    children?: React.ReactNode;
    onChange?: (event: SelectChangeEvent<string[]>, child: ReactNode) => void;
}

export const StyledSelect = React.forwardRef(( 
    { 
        id, required, value, labelId, label,  multiple, InputProps, inputRef, error, input, style, disabled, renderValue, children, onChange 
    }: StyledSelectProps, 
    ref: React.Ref<HTMLSelectElement>) => {
        const classes = useStyles();
        return (
            <Select
                className={`${classes.styledSelect}`}
                onChange={onChange}
                id={id}
                required={required}
                value={value}
                labelId={labelId}
                label={label}
                multiple={multiple}
                inputProps={InputProps}
                inputRef={ref}
                error={error}
                input={input}
                style={style}
                disabled={disabled}
                renderValue={renderValue}
                MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: '200px',
                      },
                    },
                }}
            >
                {children}
            </Select>
        )

})
