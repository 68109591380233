import Grid from "@mui/material/Grid/Grid"
import useFormStyles from "../../styledComponents/globalFormStying.module";
import FunctionalHeader from "../../functionalHeader/functionalHeader";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { handleNavigate, useMediaQueries } from "../../../utils/global-utils";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import StyledSpinningIcon from "../../styledComponents/spinningIcon/spinningIcon";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { EmployerAddResponse } from "../../../utils/types.d";
import { useAuthContext } from "../../../context/UserContext";
import AddApi from "../../../services/addApi";
import SingleAd from "../singleAd/singleAd";
import Divider from "@material-ui/core/Divider/Divider";



function ActiveAds() {
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [fetchEmployerActiveAdds, setFetchEmployerActiveAdds] = useState<EmployerAddResponse[]>([]);

    const { accessToken } = useAuthContext();

    useEffect(() => {
        const fetchDetails = async () => {
          try {
            if (accessToken) {

                const activeAddsResponse = await AddApi.GetActiveEmpleyerAdds(accessToken)
                setFetchEmployerActiveAdds(activeAddsResponse.data)
                
                setLoading(false);
            }
          } catch (error) {
            setLoading(false);
          }
        };

        if (accessToken) {
            setLoading(true);
            fetchDetails();
        }
    }, [accessToken]);
    
    return (
        <>
            <Grid container>
                <Grid item xs={0} sm={0} md={2}>
                    <div className={formClasses.bannersContainer}></div>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <FunctionalHeader />
                        </Grid>
                    </Grid>
                    <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                        <div className={formClasses.companyNameHeadline}>
                            <ArrowBackIcon 
                                className={formClasses.navigateBack}
                                onClick={() => handleNavigate(navigate, `/employer-dashboard/`)} /> Aktivni oglasi
                        </div>
                    </div>
                    <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                        <div className={formClasses.bodyItemsHolder}>
                            {loading ? (
                                <Backdrop open={true}>
                                    <div className={formClasses.circularProgressContainer}>
                                        <StyledSpinningIcon />
                                    </div>
                                </Backdrop>
                            ) : (
                               <>
                                    {fetchEmployerActiveAdds.length ? (
                                        <>
                                            {fetchEmployerActiveAdds?.map((addDetails: any, index: number) => (
                                                <SingleAd key={index} addDetails={addDetails} />
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <div className={formClasses.inputContainerTop}>
                                                <div className={`${formClasses.canditateName} ${formClasses.ml28}`}>
                                                    Nemate aktivnih oglasa
                                                </div>
                                            
                                            </div>
                                            <div className={formClasses.inputContainerTop}>
                                                <Divider />
                                            </div>
                                        </>   
                                    )}
                                    
                               </>
                            )}
                       </div>
                    </Grid>
                </Grid>
                <Grid item xs={0} sm={0} md={2}>
                    <div className={formClasses.bannersContainer}></div>
                </Grid>
            </Grid>
        </>
    )
}

export default ActiveAds