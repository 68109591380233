import { useNavigate, useParams } from "react-router-dom";
import validateValue, { 
    useMediaQueries, 
    countryId 
} from "../../../utils/global-utils";
import FunctionalHeader from "../../functionalHeader/functionalHeader";
import useFormStyles from "../../styledComponents/globalFormStying.module";
import useStyles from "./editAdd.module";
import Grid from "@mui/material/Grid/Grid"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Controller, useForm } from "react-hook-form";
import FormControl from '@mui/material/FormControl';
import { StyledInput } from "../../styledComponents/styledInput/styledInput";
import { useEffect, useState } from "react";
import {    Currency, 
            Industry, 
            BillingType, 
            Place, 
            SelectedAddResponse, 
            Country} 
from "../../../utils/types.d";
import '../../styledComponents/styledDateRangePicker.css'
import DatePicker from "react-datepicker";
import Typography from "@mui/material/Typography/Typography";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import { StyledSelect } from "../../styledComponents/styledSelect/styledSelect";
import OutlinedInput from "@mui/material/OutlinedInput/OutlinedInput";
import BillingAndIndustriesApi from "../../../services/billingAndIndustries";
import { SelectChangeEvent } from "@mui/material";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Alert from '@mui/material/Alert';
import Box from "@material-ui/core/Box/Box";
import Chip from "@material-ui/core/Chip/Chip";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from "@material-ui/core/Divider/Divider";
import SOCIAL_MEDIA_ICONS from "../../../assets/media";
import Checkbox from '@mui/material/Checkbox';
import StyledButton from "../../styledComponents/styledButton.module";
import BODY_ITEMS from "../../../assets/bodyItems";
import { useAuthContext } from "../../../context/UserContext";
import AddApi from "../../../services/addApi";


function EditAdd() {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { isMobile, isTablet } = useMediaQueries();
    const navigate = useNavigate();
    const { addId } = useParams();
    const today = new Date();
    const [industry, setIndustry] = useState<string[]>([]);
    const [fetchIndustries, setFetchIndustries] = useState<Industry[]>([]);
    const [fetchPaymentType, setFetchPaymentType] = useState<BillingType[]>([])
    const [paymentType, setpaymentType] = useState<string[]>([]);
    const [fetchCurrencies, setFetchCurrencies] = useState<Currency[]>([]);
    const [coinType, setCoinType] = useState<string[]>([]);
    const [fetchPlaces, setFetchPlaces] = useState<Place[]>([]);
    const [places, setPlaces] = useState<string[]>([]);
    const [fetchCountries, setFetchCountrues] = useState<Country[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<string[]>([])
    const [selectedCountryById, setSelectedCountryById] = useState<string>('')
    const [startDate, setStartDate] = useState<Date | null>();
    const [endDate, setEndDate] = useState<Date | null>();
    const [fetchSelectedAdd, setfFetchSelectedAdd] = useState<SelectedAddResponse | null>(null);
    const [loading, setLoading] = useState(true);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [editErrorModal, setEditErrorModal] = useState(false);
    const [anyPaymentFieldFilled, setAnyPaymentFieldFilled] = useState(false);
    const { accessToken } = useAuthContext();

    useEffect(() => {

        const fetchAddDetails = async () => {
          try {
            if (accessToken) {
                const selectedAddResponse = await AddApi.GetSelectedAd(accessToken, addId);
                setfFetchSelectedAdd(selectedAddResponse.data);
            }
          } catch (error) {
            //
          }
        };

        if (accessToken) {
            fetchAddDetails();
        }
    }, [accessToken]);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const industriesResponse = await BillingAndIndustriesApi.GetIndustries({});
            setFetchIndustries(industriesResponse.data);
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const billingTypeResponse = await BillingAndIndustriesApi.getBiillingTypes({});
            setFetchPaymentType(billingTypeResponse.data);
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

            try {
            const currenciesResponse = await BillingAndIndustriesApi.GetCurrencies({});
            setFetchCurrencies(currenciesResponse.data);
            } catch (error) {
            //
            }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const countriesResponse = await BillingAndIndustriesApi.getAllCountries({});
            setFetchCountrues(countriesResponse.data);
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

          try {
            const response: any = await BillingAndIndustriesApi.getAllPlaces(selectedCountryById);
            const { status } = response;
            if (status === 200) {
                setFetchPlaces(response.data);
            }
            
          } catch (error) {
            //
          }
        };

    
        fetchData();
    }, [selectedCountryById]);

    useEffect(() => {
        setPlaces([]);
    }, [selectedCountryById]);

    const { 
        handleSubmit,
        formState,
        control,
        trigger,
        setValue,
        watch,
        getValues } = useForm({
            mode: 'onChange',
            defaultValues: {
                adId: addId,
                industryIds: [],
                adTitle: '',
                adDescription: '',
                countryId: '',
                cityId: '',
                workingStartDate: '',
                workingEndDate: '',
                workingHours: 0,
                billingTypeId: '',
                salaryValue: '',
                currencyId: '',
                offeredAccomodation: false,
                offeredFood: false,
                offeredTransport: false
            }
    });

    useEffect(() => {
        if (fetchSelectedAdd) {
            const { 
                adTitle, 
                adDescription, 
                industries, 
                country,
                city, 
                workingStartDate, 
                workingEndDate, 
                workingHours,
                billingType,
                salaryValue,
                currency,
                offeredAccomodation,
                offeredFood,
                offeredTransport

            } = fetchSelectedAdd;

            const selectedIndustryNames = industries.map((industry) => industry.industryName)
            const selectedIndustryIds = industries.map((industry) => industry.id)
            
            setIndustry(selectedIndustryNames)
            setPlaces([city.city])
            setCoinType([currency?.currencyName])
            setpaymentType([billingType?.typeOfBilling])

            setValue('adTitle', adTitle);
            setValue('adDescription', adDescription);
            setValue('industryIds', selectedIndustryIds as never[])
            setValue('countryId', country.id);
            setValue('cityId', city.id);
            setValue('workingStartDate', workingStartDate);
            setValue('workingEndDate', workingEndDate);
            setValue('salaryValue', salaryValue);
            setValue('workingHours', workingHours);
            setValue('billingTypeId', billingType?.id);
            setValue('currencyId', currency?.id);
            setValue('offeredAccomodation', offeredAccomodation);
            setValue('offeredFood', offeredFood);
            setValue('offeredTransport', offeredTransport);
        }

    }, [fetchSelectedAdd, setValue]);

    useEffect(() => {
        setIsFormDirty(Object.keys(formState.dirtyFields).length > 0);
    }, [formState])

    const onSubmit = async (formData: any) => {

        const formDataToSend = {
            ...formData,
            adId: addId,
        };

        try {
            const response: any = await AddApi.EditAdd(accessToken, formDataToSend);
            const { status } = response;
            if (status === 200) {
                navigate(-1);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        } catch (error) {
            setEditErrorModal(true);
        }
    }

    const handleButtonClick = async () => {
        try {
            await trigger();
            if (Object.keys(formState.errors).length === 0) {
                setLoading(true);
                await handleSubmit(onSubmit)();
                setLoading(false);
            }
        }
        catch (err) {
            //
        }
    };

    return (
       <>
        <Grid container>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <FunctionalHeader />
                    </Grid>
                </Grid>
                <div className={isMobile ? formClasses.navigationContainerMobile : formClasses.navigationContainer}>
                    <div className={formClasses.companyNameHeadline}>
                        <ArrowBackIcon 
                            className={formClasses.navigateBack}
                            onClick={() => navigate(-1)} /> Izmeni oglas
                    </div>
                </div>
                <Grid container className={isMobile? formClasses.bodyContainerMobile : formClasses.bodyContainer}>
                    <Grid item xs={12} sm={12} md={6}>
                        <form onSubmit={handleButtonClick} className={classes.formContainer}>
                            <div className={formClasses.inputContainer}>
                                <Controller
                                    name="adTitle"
                                    control={control}
                                    render={({field, fieldState}) => {
                                        return (
                                            <StyledInput
                                                variant="outlined"
                                                required={false}
                                                type="text"
                                                label="Naziv oglasa"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                                inputRef={field.ref}
                                                {...field}
                                            />
                                        )
                                    }}  
                                />
                            </div>
                            <div className={formClasses.inputContainer}>
                                <Controller
                                    name="adDescription"
                                    control={control}
                                    rules={{
                                        maxLength: 200
                                    }}
                                    render={({field, fieldState}) => {
                                        const CHARACTER_LIMIT = 200
                                        return(
                                            <StyledInput
                                                variant="outlined"
                                                multiline={true}
                                                rows={4}
                                                required={false}
                                                type="text"
                                                label="Opis oglasa" 
                                                error={!!fieldState.error}
                                                InputProps={{
                                                    maxLength: CHARACTER_LIMIT
                                                }}
                                                helperText={`${field.value.length} / ${CHARACTER_LIMIT}`}
                                                inputRef={field.ref}
                                                {...field}
                                            />
                                        )
                                    }} 
                                />
                            </div>
                            <div className={formClasses.inputContainer}>
                                <Controller
                                    name="industryIds"
                                    control={control}
                                    render={({field, fieldState}) => {
                                        const handleChange = (event: SelectChangeEvent<typeof industry>) => {
                                            const {target: { value } } = event;
                                            const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                            
                                            field.onChange(selectedValues);
                                            setIndustry(selectedValues);

                                            const selectedIndustryIds = fetchIndustries
                                            .filter(industryObj => selectedValues.includes(industryObj.industryName))
                                            .map(industryObj => industryObj.id);

                                            setValue('industryIds', selectedIndustryIds as unknown as never[]);
                                        };

                                        return (
                                            <div className={formClasses.customFormErrorsContainer}>
                                                {fieldState.error && (
                                                        <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                            {fieldState.error.message}
                                                        </Typography>
                                                )}
                                                <FormControl className={formClasses.selectContainer}>
                                                    <InputLabel 
                                                        id="field-of-industry" 
                                                        className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                            Industrija
                                                    </InputLabel>
                                                    <StyledSelect
                                                        {...field}
                                                        required={false}
                                                        labelId="field-of-industry"
                                                        multiple
                                                        value={industry}
                                                        onChange={handleChange}
                                                        input={
                                                            <OutlinedInput 
                                                                label="Industrija" 
                                                                className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                            />
                                                        }
                                                        error={!!fieldState.error}
                                                        inputRef={field.ref}
                                                        renderValue={(selected) => {
                                                            return (
                                                                <Box className={formClasses.chipContainer}>
                                                                    {selected.map((value: any) => (
                                                                        <Chip key={value} label={value} />
                                                                    ))}
                                                                </Box>
                                                            )  
                                                        }}
                                                    >
                                                        {fetchIndustries.map((industryObj) => (
                                                            <MenuItem
                                                                key={industryObj.id}
                                                                value={industryObj.industryName}
                                                                // className={formClasses.menuItemContainer}
                                                            >
                                                                {industryObj.industryName}
                                                                {industry.includes(industryObj.industryName) ? <CheckCircleIcon className={formClasses.checkIcon}/> : undefined}
                                                            </MenuItem>
                                                        ))}
                                                    </StyledSelect>
                                                </FormControl>
                                            </div>
                                        )
                                    }} 
                                />
                            </div>
                            <div className={formClasses.inputContainer}>
                                <Divider />
                            </div>
                            <div className={formClasses.inputContainer}>
                                <Controller
                                    name="countryId"
                                    control={control}
                                    render={({field, fieldState}) => {
                                        const handleCountryChange = (event: SelectChangeEvent<typeof selectedCountry>) => {
                                            const { target: { value } } = event;
                                            const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                            field.onChange(selectedValues);
                                            setSelectedCountry(selectedValues)
    
                                            const selectedCountryId: string | undefined = fetchCountries.find(
                                                (place) => place.countryName === value
                                            )?.id
    
                                            setSelectedCountryById(selectedCountryId as string)
                                            setValue('countryId', selectedCountryId || '');
                                        }
                                        return (
                                            <div className={formClasses.customFormErrorsContainer}>
                                                {fieldState.error && (
                                                    <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                        {fieldState.error.message}
                                                    </Typography>
                                                )}
                                                <FormControl className={formClasses.selectContainer}>
                                                    <InputLabel 
                                                        id="select-country" 
                                                        className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                            Država
                                                    </InputLabel>
                                                    <StyledSelect
                                                        {...field}
                                                        required={false}
                                                        labelId="select-country"
                                                        value={selectedCountry}
                                                        onChange={handleCountryChange}
                                                        input={
                                                            <OutlinedInput 
                                                                label="Država" 
                                                                className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                            />
                                                        }
                                                        error={!!fieldState.error}
                                                        inputRef={field.ref}
                                                    >
                                                        {fetchCountries.slice().sort((a, b) => a.countryName.localeCompare(b.countryName))
                                                            .map((region, index) => (
                                                                <MenuItem
                                                                    className={formClasses.menuItemContainer}
                                                                    key={index} 
                                                                    value={region.countryName}
                                                                >
                                                                    {region.countryName}
                                                                </MenuItem>
                                                            ))}
                                                    </StyledSelect>
                                                </FormControl>
                                            </div>
                                        )
                                    }} 
                                />
                            </div>
                            <div className={formClasses.inputContainer}>
                                <Controller
                                    name="cityId"
                                    control={control}
                                    render={({field, fieldState}) => {
                                        const handleCityChange = (event: SelectChangeEvent<typeof places>) => {
                                            const { target: { value } } = event;
                                            const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                            field.onChange(selectedValues);
                                            setPlaces(selectedValues)

                                            const selectedCityId = fetchPlaces.find(
                                                (place) => place.city === value
                                            )?.id

                                            setValue('cityId', selectedCityId || '');
                                        }
                                        return (
                                            <div className={formClasses.customFormErrorsContainer}>
                                                {fieldState.error && (
                                                    <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                        {fieldState.error.message}
                                                    </Typography>
                                                )}
                                                <FormControl className={formClasses.selectContainer}>
                                                    <InputLabel 
                                                        id="select-city" 
                                                        className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                            Grad
                                                    </InputLabel>
                                                    <StyledSelect
                                                        {...field}
                                                        required={false}
                                                        labelId="select-city"
                                                        value={places}
                                                        onChange={handleCityChange}
                                                        input={
                                                            <OutlinedInput 
                                                                label="Grad" 
                                                                className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                            />
                                                        }
                                                        error={!!fieldState.error}
                                                        inputRef={field.ref}
                                                    >
                                                        {fetchPlaces.slice().sort((a, b) => a.city.localeCompare(b.city))
                                                            .map((region, index) => (
                                                                <MenuItem
                                                                    className={formClasses.menuItemContainer}
                                                                    key={index} 
                                                                    value={region.city}
                                                                >
                                                                    {region.city}
                                                                </MenuItem>
                                                        ))}
                                                    </StyledSelect>
                                                </FormControl>
                                            </div>
                                        )
                                    }} 
                                />
                            </div>
                            <div className={formClasses.inputContainer}>
                                <Divider />
                            </div>
                            <div className={formClasses.inputContainer}>
                                <Grid container className={classes.datePickerContainer}>
                                        <Grid item xs={12} sm={12} md={6} className={isMobile ? `${formClasses.pSides0} ${formClasses.pb28}` : formClasses.pr6}>
                                            <div className="date-range-container">
                                                <div className="date-range-input-container">
                                                    <Controller
                                                        name="workingStartDate"
                                                        control={control}
                                                        render={({field, fieldState }) => {
                                                            const handleStartDateChange = (date: Date) => {
                                                                const selectedDate = date ? new Date(date) : null;
                                                                field.onChange(selectedDate)
                                                                setStartDate(selectedDate);
                                                            };
                                                            return (
                                                                <div className={formClasses.customFormErrorsContainer}>
                                                                    {fieldState.error && (
                                                                        <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                                            {fieldState.error.message}
                                                                        </Typography>
                                                                    )}
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={startDate}
                                                                        onChange={handleStartDateChange}
                                                                        startDate={startDate}
                                                                        endDate={endDate}
                                                                        minDate={today}
                                                                        placeholderText="MM/DD/YYYY"
                                                                        className={fieldState.error ? `${'date-range-inputs-error'} ${'date-range-inputs-error::placeholder'}` : 'date-range-inputs'}
                                                                    />
                                                                </div>
                                                            )
                                                        }} 
                                                    />
                                                
                                                </div>
                                                <span className="MuiTypography-root MuiTypography-body1 css-14am5op">–</span>
                                                <div className="date-range-input-container">
                                                    <Controller
                                                        control={control}
                                                        name="workingEndDate"
                                                        render={({field, fieldState}) => {
                                                            const handleEndDateChange = (date: Date) => {
                                                                const selectedDate = date ? new Date(date) : null;
                                                                field.onChange(selectedDate)
                                                                setEndDate(selectedDate);
                                                            };
                                                            return (
                                                                <div className={formClasses.customFormErrorsContainer}>
                                                                    {fieldState.error && (
                                                                        <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                                            {fieldState.error.message}
                                                                        </Typography>
                                                                    )}
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={endDate}
                                                                        onChange={handleEndDateChange}
                                                                        startDate={startDate}
                                                                        endDate={endDate}
                                                                        minDate={startDate}
                                                                        placeholderText="MM/DD/YYYY"
                                                                        className={fieldState.error ? `${'date-range-inputs-error'} ${'date-range-inputs-error::placeholder'}` : 'date-range-inputs'}
                                                                    />
                                                               </div>
                                                            )
                                                        }} 
                                                    />
                                                    
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} className={isMobile? formClasses.pSides0 : formClasses.pl6}>
                                            <Controller
                                                name="workingHours"
                                                control={control}
                                                rules={{ 
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message: 'Unesite broj',
                                                    },
                                                    validate: validateValue
                                                }}
                                                render={({field, fieldState}) => {
                                                    return (
                                                        <StyledInput
                                                        {...field}
                                                            variant="outlined"
                                                            required={false}
                                                            type="text"
                                                            label="Radno vreme (sati)" 
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            inputRef={field.ref}
                                                        />
                                                    )
                                                }}
                                            />
                                        </Grid>
                                </Grid>
                            </div>
                            <div className={formClasses.inputContainer}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={4} className={isMobile ? `${formClasses.pSides0} ${formClasses.pb28}` : formClasses.pr6}>
                                        <Controller
                                            name="salaryValue"
                                            control={control}
                                            rules={{ 
                                                required: anyPaymentFieldFilled,
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Unesite broj',
                                                },
                                            }}
                                            render={({field, fieldState}) => {
                                                const handleSalaryChange = (e: { target: { value: any; }; }) => {
                                                    const value = e.target.value;
                                                    field.onChange(value);
                                                    setAnyPaymentFieldFilled(!!value || !!watch("billingTypeId") || !!watch("currencyId"));
                                                };
                                                return (
                                                    <StyledInput
                                                        {...field}
                                                        variant="outlined"
                                                        required={false}
                                                        type="text"
                                                        label="Iznos" 
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                        inputRef={field.ref}
                                                        onChange={handleSalaryChange}
                                                    />
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} className={isMobile ? `${formClasses.pSides0} ${formClasses.pb28}` : ''}>
                                        <Controller 
                                            name="billingTypeId"
                                            control={control}
                                            rules={{ 
                                                required: anyPaymentFieldFilled,
                                            }}
                                            render={({field, fieldState}) => {
                                                const handlePaymentTypeChange = (event: SelectChangeEvent<typeof paymentType>) => {
                                                    const { target: { value } } = event;
                                                    const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                                    field.onChange(value);
                                                    setpaymentType(selectedValues);

                                                    const selectedBillingTypeId = fetchPaymentType.find(
                                                        (type) => type.typeOfBilling === value
                                                    )?.id

                                                    setValue('billingTypeId', selectedBillingTypeId || '');

                                                    setAnyPaymentFieldFilled(!!value);
                                                };
                                                return (
                                                    <div className={formClasses.customFormErrorsContainer}>
                                                        {fieldState.error && (
                                                            <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                                {fieldState.error.message}
                                                            </Typography>
                                                        )}
                                                        <FormControl className={formClasses.selectContainer}>
                                                            <InputLabel 
                                                                id="payment-type" 
                                                                className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                                    Tip naplate
                                                            </InputLabel>
                                                            <StyledSelect
                                                                {...field}
                                                                required
                                                                labelId="payment-type"
                                                                value={paymentType}
                                                                onChange={handlePaymentTypeChange}
                                                                input={
                                                                    <OutlinedInput 
                                                                        label="Tip naplate" 
                                                                        className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                                    />
                                                                }
                                                                error={!!fieldState.error}
                                                            >
                                                                {fetchPaymentType.map((type, index) => (
                                                                    <MenuItem
                                                                        key={index}
                                                                        value={type.typeOfBilling}
                                                                    >
                                                                        {type.typeOfBilling}
                                                                    </MenuItem>
                                                                ))}
                                                            </StyledSelect>
                                                        </FormControl>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} className={isMobile ? `${formClasses.pSides0} ${formClasses.pb28}` : formClasses.pl6}>
                                        <Controller
                                            name="currencyId"
                                            control={control}
                                            rules={{ 
                                                required: anyPaymentFieldFilled,
                                            }}
                                            render={({field, fieldState}) => {
                                                const handleCurrencyChange = (event: SelectChangeEvent<typeof coinType>) => {
                                                    const { target: { value } } = event;
                                                    const selectedValues = Array.isArray(value) ? value : [value].filter(Boolean);
                                                    field.onChange(selectedValues);
                                                    setCoinType(selectedValues);

                                                    const selectedCurrencyId = fetchCurrencies.find(
                                                        (currency) => currency.currencyName === value
                                                    )?.id;
    
                                                    setValue('currencyId', selectedCurrencyId || '');

                                                    setAnyPaymentFieldFilled(!!value);
                                                }
                                                return (
                                                    <div className={formClasses.customFormErrorsContainer}>
                                                        {fieldState.error && (
                                                            <Typography className={formClasses.customValidationErrors} color="error" variant="caption">
                                                                {fieldState.error.message}
                                                            </Typography>
                                                        )}
                                                        <FormControl className={formClasses.selectContainer}>
                                                            <InputLabel 
                                                                id="currency" 
                                                                className={fieldState.error ? formClasses.customFormErrors : ' '}>
                                                                    Valuta
                                                            </InputLabel>
                                                            <StyledSelect
                                                                {...field}
                                                                required
                                                                labelId="currency"
                                                                value={coinType}
                                                                onChange={handleCurrencyChange}
                                                                input={
                                                                    <OutlinedInput 
                                                                        label="Valuta" 
                                                                        className={fieldState.error ? formClasses.customFormErrors : ' '}
                                                                    />
                                                                }
                                                                error={!!fieldState.error}
                                                                inputRef={field.ref}
                                                            >
                                                                {fetchCurrencies.map((currency) => (
                                                                    <MenuItem
                                                                        key={currency.id}
                                                                        value={currency.currencyName}
                                                                    >
                                                                        {currency.currencySymbol}
                                                                    </MenuItem>
                                                                ))}
                                                            </StyledSelect>
                                                        </FormControl>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={formClasses.inputContainer}>
                                <Grid container className={isMobile ? classes.checkboxesMobileContainer : ''}>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <Controller
                                            name="offeredAccomodation"
                                            control={control}
                                            render={({ field, fieldState }) => {
                                                return (
                                                    <div className={formClasses.additionalBenefitsContainer}>
                                                        <Checkbox 
                                                            {...field}
                                                            className={formClasses.checkAdditionalBenefits}
                                                            checked={field.value}
                                                        />
                                                        <Typography className={formClasses.benefitsText}>Smestaj</Typography>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <Controller
                                            name="offeredFood"
                                            control={control}
                                            render={({ field, fieldState }) => {
                                                return (
                                                    <div className={formClasses.additionalBenefitsContainer}>
                                                        <Checkbox 
                                                            {...field}
                                                            className={formClasses.checkAdditionalBenefits}
                                                            checked={field.value}
                                                        />
                                                        <Typography className={formClasses.benefitsText}>Hrana</Typography>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <Controller
                                            name="offeredTransport"
                                            control={control}
                                            render={({ field, fieldState }) => {
                                                return (
                                                    <div className={formClasses.additionalBenefitsContainer}>
                                                        <Checkbox 
                                                            {...field}
                                                            className={formClasses.checkAdditionalBenefits}
                                                            checked={field.value}
                                                        />
                                                        <Typography className={formClasses.benefitsText}>Prevoz</Typography>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={isMobile ? formClasses.inputContainerMobile : formClasses.inputContainer}>
                                <StyledButton
                                    disabled={!isFormDirty}
                                    style={{ textTransform: 'none'}}
                                    className={`${formClasses.w100pi} ${!isFormDirty ? formClasses.buttonDisabled : ''}`}
                                    variant="contained"
                                    onClick={handleButtonClick}
                                >{!isFormDirty ? 'Napravi izmene na oglasu' : 'Izmeni oglas'}
                                </StyledButton>
                                {editErrorModal && (
                                    <Backdrop className={formClasses.zIndexHighest} open={editErrorModal}>
                                        <Alert className={isMobile ? `${formClasses.ml18} ${formClasses.mr18}` : ''} onClose={() => setEditErrorModal(false)} severity="error">Došlo je do greške!</Alert>
                                    </Backdrop>
                                )}
                            </div>
                            <div className={formClasses.inputContainer}>
                                <StyledButton
                                    style={{ textTransform: 'none'}}
                                    className={formClasses.w100pi}
                                    variant="outlined"
                                    onClick={() => navigate(-1)}
                                    >
                                    Odustani
                                </StyledButton>
                            </div>
                        </form>
                    </Grid>
                    <Grid className={isMobile ? formClasses.dne : formClasses.bodyMegaphoneContainer} item md={6}>
                        <img className={formClasses.bodyMegaphone} src={`${BODY_ITEMS.MEGAPHONE}`}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={0} sm={0} md={2}>
                <div className={formClasses.bannersContainer}></div>
            </Grid>
        </Grid>
       </>
    )
}

export default EditAdd