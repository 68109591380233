import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import StyledButton from '../styledButton.module';
import DialogTitle from '@mui/material/DialogTitle';
import React from "react";
import useStyles from './styledApplyingModal.module';

interface ApplyModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    titleText?: string;
    bodyText?: string;
    inputText?: string;
    confirmButtonText?: string;
    children: React.ReactNode;
}

function StyledApplyingModal({  open, onClose, onConfirm, titleText, bodyText, inputText, confirmButtonText, children }: ApplyModalProps) {
    const classes = useStyles();
  
    return (
        <Dialog
            maxWidth={'md'}
            open={open}
            keepMounted
            onClose={onClose}
            aria-describedby="apply-modal"
            
            >
            <DialogContent>
                <DialogTitle>{titleText}</DialogTitle>
                <DialogContentText id="apply-modal">
                    <div  className={classes.bodyText}>
                        <div>
                            {bodyText}
                        </div>
                        <div className={classes.bodyTextSmall}>
                            {inputText}
                        </div>
                       {children}
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.actionButtonsContainer}>
                <StyledButton
                        style={{ textTransform: 'none'}}
                        onClick={onClose}
                        variant="outlined"
                    >
                        Zatvori
                </StyledButton>
                <StyledButton
                    style={{ textTransform: 'none'}}
                    onClick={onConfirm}
                    variant="contained"
                >
                    {confirmButtonText}
                </StyledButton>
            </DialogActions>
        </Dialog>
    )
}  

export default StyledApplyingModal